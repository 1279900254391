import { useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Catalogue/CatalogueModal';
import { languageData } from '../../../i18n';
import HeaderTypeMachine from './HeaderTypeMachine';
import TypeMachineICard from './TypeMachineICard';
import { serviceCampanyMachineTypeModels } from '../../../models/services/module.CampanyMachineType';
import { useDispatch } from 'react-redux';
import {
  addCompanyMachineTypeRequest,
  fetchCompanyMachineTypesRequest,
  updateCompanyMachineTypeRequest,
} from '../../../store/actions/actions';
import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { NotificationSuccessProvider } from '../../../utils/Notificationprovider';
export default function TypeMachineContainer() {
  const dispatch = useDispatch();
  const [mode, setmode] = useState('add');
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [machineborne, setmachineborne] = useState<
    serviceCampanyMachineTypeModels.MachineType[]
  >([]);
  const [machineCaisse, setmachineCaisse] = useState<
    serviceCampanyMachineTypeModels.MachineType[]
  >([]);
  const [machineType, setmachineType] = useState<
    Partial<serviceCampanyMachineTypeModels.AddCompanyMachineTypeRequestPayload>
  >({});
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const [visible, setvisible] = useState(false);
  const handleOpenModal = (type: string) => {
    handleChangeTypeMachine('typeOfMachineTypeId', type);
  };
  const closeAllModal = () => {
    setvisible(false);
  };
  const handleChangeTypeMachine = (
    key: keyof serviceCampanyMachineTypeModels.AddCompanyMachineTypeRequestPayload,
    value: string | undefined,
  ) => {
    const updatedTypemachine: Partial<serviceCampanyMachineTypeModels.AddCompanyMachineTypeRequestPayload> =
      { ...machineType, companyId: parseInt(activeTabContent.companyId ?? '') };
    (updatedTypemachine as any)[key] = value;
    setmachineType(
      updatedTypemachine as serviceCampanyMachineTypeModels.AddCompanyMachineTypeRequestPayload,
    );
  };
  const onClickAddBorne = () => {
    setvisible(true);
    handleOpenModal('2');
  };
  const onClickAddCaisse = () => {
    setvisible(true);

    handleOpenModal('1');
  };
  const handleSave = () => {
    dispatch(
      addCompanyMachineTypeRequest(
        {
          ...machineType,
        } as serviceCampanyMachineTypeModels.AddCompanyMachineTypeRequestPayload,
        () => {
          NotificationSuccessProvider('add', 'Type de machine');
          getListMachineBorne();
          getListMachineCaisee();
          setvisible(false);
        },
      ),
    );
  };
  const getListMachineBorne = () => {
    dispatch(
      fetchCompanyMachineTypesRequest(
        {
          companyId: parseInt(activeTabContent.companyId ?? ''),
          typeOfMachineTypeId: '2',
        },
        (list) => {
          setmachineborne(list);
        },
      ),
    );
  };
  const getListMachineCaisee = () => {
    dispatch(
      fetchCompanyMachineTypesRequest(
        {
          companyId: parseInt(activeTabContent.companyId ?? ''),
          typeOfMachineTypeId: '1',
        },
        (list) => {
          setmachineCaisse(list);
        },
      ),
    );
  };
  useEffect(() => {
    getListMachineBorne();
    getListMachineCaisee();
  }, []);
  const onCLickEdit = (m: serviceCampanyMachineTypeModels.MachineType) => {
    setmode('edit');
    setmachineType(m);
    setvisible(true);
  };
  const onCLickDelete = (m: serviceCampanyMachineTypeModels.MachineType) => {
    setshowDeleteModal(true);
    setmachineType(m);
  };
  const handleDelete = () => {
    dispatch(
      updateCompanyMachineTypeRequest(
        machineType.id as number,
        machineType.designation as string,

        () => {
          setvisible(false);
          NotificationSuccessProvider('delete', 'Type de machine');
          getListMachineBorne();
          getListMachineCaisee();
          setmachineType({});
          setmode('add');
        },
        true,
      ),
    );
  };

  const handleCancelDelete = () => {
    setshowDeleteModal(false);
  };
  const onUpdateMachineType = () => {
    dispatch(
      updateCompanyMachineTypeRequest(
        machineType.id as number,
        machineType.designation as string,
        () => {
          setvisible(false);
          NotificationSuccessProvider('edit', 'Type de machine');
          getListMachineBorne();
          getListMachineCaisee();
          setmachineType({});
          setmode('add');
        },
      ),
    );
  };

  return (
    <div className="TypeMachineContainer">
      <div className="type-wrapper fl_row gp10">
        <div className="Borne-section fl_col gp20 fl-1 pd28-t-b pd25-r-l">
          <HeaderTypeMachine
            title={'Borne'}
            slot={machineborne.length}
            onClickAdd={onClickAddBorne}
          />
          <div className="listing-cards fl_col gp20 ">
            {machineborne?.map((m) => (
              <TypeMachineICard
                affected={m.isAffected}
                label={m.designation}
                onClickEdit={() => onCLickEdit(m)}
                onClickDelete={() => onCLickDelete(m)}
              />
            ))}
          </div>
        </div>
        <div className="Caisse-section fl_col gp20 fl-1 pd28-t-b pd25-r-l">
          <HeaderTypeMachine
            title={'Caisse'}
            slot={machineCaisse.length}
            onClickAdd={onClickAddCaisse}
          />
          {machineCaisse?.map((m) => (
            <TypeMachineICard
              affected={m.isAffected}
              label={m.designation}
              onClickEdit={() => onCLickEdit(m)}
              onClickDelete={() => onCLickDelete(m)}
            />
          ))}
        </div>
      </div>
      <Modal
        handeldelete={() => console.log('x')}
        handleSave={mode == 'edit' ? onUpdateMachineType : handleSave}
        onChange={(e) => handleChangeTypeMachine('designation', e.target.value)}
        closeModal={closeAllModal}
        familytype={'add'}
        target="machine"
        version="Item"
        type="edit"
        title={
          mode == 'add'
            ? languageData.fr.modalMachine.AjouterMACHINE
            : languageData.fr.modalMachine.EditMACHINE
        }
        close={true}
        saveTxt={languageData.fr.Modal.validate}
        save={true}
        cancel={true}
        cancelTxt={languageData.fr.Modal.cancel}
        visibility={visible}
        itemName={machineType.designation}
        itemNameLabel={languageData.fr.modalMachine.DesignationmACHINE}
        itemTagLabel={languageData.fr.modalMachine.AjouterMACHINE}
      ></Modal>
      <Modal
        target="badge"
        version={'Item'}
        type={'delete'}
        title={languageData.fr.modalsCatalogues.confirmDelete}
        visibility={showDeleteModal}
        close={true}
        cancel={true}
        cancelTxt={languageData.fr.Modal.cancel}
        deleteitem={true}
        deleteitemTxt={languageData.fr.Modal.delete}
        handeldelete={handleDelete}
        closeModal={handleCancelDelete}
        description={[languageData.fr.Modal.confirmDelete]}
      />
    </div>
  );
}
