import React from 'react';
import ArticleIcon from '../../assets/icons/ArticlesIcon';
import EtapesIcon from '../../assets/icons/EtapesIcon';
import MenusIcon from '../../assets/icons/MenusIcon';
import CategoriesIcon from '../../assets/icons/CategorieIcon';
import FamillesIcon from '../../assets/icons/FamilyIcon';
import AllergeneIcon from '../../assets/icons/AllergeneIcon';
import TagIcon from '../../assets/icons/TagIcon';
import EcranIcon from '../../assets/icons/EcranIcon';
import TvaIcon from '../../assets/icons/TvaIcon';
import BadgeIcon from '../../assets/icons/BadgeIcon';
import Carte from '../../pages/catalogue/pages/Carte/Carte';
import AddCart from '../../container/Carts/AddCart';
import Spinner from '../../components/skeleton/spinner';
import AddBornCaisse from '../../container/Catalogue/TypeMachine/TypeMachineContainer';
const AddEtapePage = React.lazy(
  () => import('../../pages/catalogue/pages/Etapes/AddEtapePage'),
);
const EditEtapePage = React.lazy(
  () => import('../../pages/catalogue/pages/Etapes/EditEtapePage'),
);
const AddEcranPage = React.lazy(
  () => import('../../pages/catalogue/pages/Ecrans/AddEcran'),
);
const EditEcranPage = React.lazy(
  () => import('../../pages/catalogue/pages/Ecrans/EditEcran'),
);
const Articles = React.lazy(
  () => import('../../pages/catalogue/pages/Articles/Articles'),
);
const AddArticlesPage = React.lazy(
  () => import('../../pages/catalogue/pages/Articles/Add'),
);
const EditArticlesPage = React.lazy(
  () => import('../../pages/catalogue/pages/Articles/EditPage'),
);
const Etapes = React.lazy(
  () => import('../../pages/catalogue/pages/Etapes/Etapes'),
);
const Menus = React.lazy(
  () => import('../../pages/catalogue/pages/Menus/Menus'),
);
const Categories = React.lazy(
  () => import('../../pages/catalogue/pages/Categories/Categories'),
);
const AddCategories = React.lazy(
  () => import('../../pages/catalogue/pages/Categories/AddCategory'),
);
const EditCategories = React.lazy(
  () => import('../../pages/catalogue/pages/Categories/EditCategoryPage'),
);
const Ecrans = React.lazy(
  () => import('../../pages/catalogue/pages/Ecrans/Ecrans'),
);
const Badges = React.lazy(
  () => import('../../pages/catalogue/pages/Badges/Badges'),
);
const Tva = React.lazy(() => import('../../pages/catalogue/pages/Tva/Tva'));
const Family = React.lazy(
  () => import('../../pages/catalogue/pages/Family/Family'),
);
const Tags = React.lazy(() => import('../../pages/catalogue/pages/Tags/Tags'));
const Allergenes = React.lazy(
  () => import('../../pages/catalogue/pages/Allergenes/Allergenes'),
);
const ComingSoon = React.lazy(
  () => import('../../pages/catalogue/pages/ComingSoon/ComingSoon'),
);
const AddMenu = React.lazy(
  () => import('../../pages/catalogue/pages/Menus/AddMenuPage'),
);
const EditMenu = React.lazy(
  () => import('../../pages/catalogue/pages/Menus/EditMenuPage'),
);
export const articlePath = '/articles';
export const routes: { [key: string]: React.ComponentType<any> } = {
  '/': Spinner,
  '/articles': Articles,
  '/etapes': Etapes,
  '/menus': Menus,
  '/categories': Categories,
  '/ecrans': Ecrans,
  '/badges': Badges,
  '/tva': Tva,
  '/family': Family,
  '/tags': Tags,
  '/allergenes': Allergenes,
  '/articles/ajouter': AddArticlesPage,
  '/ecrans/ajouter': AddEcranPage,
  '/categories/ajouter': AddCategories,
  '/categories/edit/:id': EditCategories,
  '/ecrans/edit/:id': EditEcranPage,
  '/articles/edit/:id': EditArticlesPage,
  '/etapes/edit/:id': EditEtapePage,
  '/menus/ajouter': AddMenu,
  '/menus/edit/:id': EditMenu,
  '/etapes/ajouter': AddEtapePage,
  '/coming-soon': ComingSoon,
  '/type-de-machine': AddBornCaisse,
  '/carte': Carte,
  '/carte/add': AddCart,
};

export const routeLabels = [
  'ARTICLES',
  'ETAPES',
  'MENUS',
  'CATEGORIES',
  'FAMILLES',
  'ALLERGENES',
  'TAGS',
  'ECRANS',
  'TYPE DE MACHINE',
  'TVA',
  'BADGES',
  'AJOUTER',
  'ID',
];
export const routesTable = {
  '/articles': 'ARTICLES',
  '/ecrans': 'ECRANS',
  '/categories': 'CATEGORIES',
  '/menus': 'MENUS',
  '/etapes': 'ETAPES',
  '/badges': 'BADGES',
  '/tva': 'TVA',
  '/family': 'FAMILLES',
  '/tags': 'TAGS',
  '/allergenes': 'ALLERGENES',
  '/articles/ajouter': 'AJOUTER',
  '/articles/edit/:id': 'EDITER',
  '/screen/ajouter': 'ECRAN-AJOUTER',
  '/screen/edit/:id': 'EDITER',
  '/etapes/ajouter': 'ETAPES-AJOUTER',
  '/etapes/edit/:id': 'ETAPES-EDIT',
  '/coming-soon': 'Coming Soon',
  '/menus/ajouter': 'MENU-AJOUTER',
  '/menus/edit': 'MENU-EDIT',
  '/categories/ajouter': 'CATEGORIES',
  '/categories/edit/:id': 'CATEGORIES',
  '/carte': 'CARTE',
  '/type-de-machine': 'TYPE DE MACHINE',
  '/carte/add': 'AJOUTER CARTE',
};
export const iconMap: { [key: string]: React.FC } = {
  ARTICLES: ArticleIcon,
  ETAPES: EtapesIcon,
  MENUS: MenusIcon,
  CATEGORIES: CategoriesIcon,
  FAMILLES: FamillesIcon,
  ALLERGENES: AllergeneIcon,
  TAGS: TagIcon,
  ECRANS: EcranIcon,
  TVA: TvaIcon,
  BADGES: BadgeIcon,
  'TYPE DE MACHINE': BadgeIcon,
};
