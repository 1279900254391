import { ChangeEvent } from 'react';
import InfoIconGrey from '../../assets/icons/InfoIconGrey.svg';
/**
 * SwitcherCheckbox component description.
 *
 * @SwitcherCheckbox
 *
 */

interface SwitcherCheckboxProps {
  className?: string;
  label?: string;
  ischecked?: boolean;
  name?: string;
  id?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  showinfo?: boolean;
  infoTxt?: string;
  green?: boolean;
  disabled?: boolean;
}
const SwitcherCheckbox: React.FC<SwitcherCheckboxProps> = ({
  label,
  ischecked,
  name,
  id,
  className,
  onChange,
  infoTxt,
  showinfo,
  green,
  disabled,
}) => {
  return (
    <div
      title={label}
      className={`SwitcherCheckbox  fl_row gp8 ai_c w-fit  ${green ? 'green' : ''} ${className ? className : ''} ${disabled ? 'disabled' : ''}`}
    >
      <div className="field fl_row ai_c gp8">
        <div className="inp-checkbox">
          <input
            disabled={disabled}
            autoComplete="off"
            defaultChecked={ischecked}
            type="checkbox"
            id={id}
            name={name}
            checked={ischecked}
            onChange={onChange}
          />

          <span></span>
        </div>
        <label className="txt-nowrap p12 OxfordBlue02-txt w-500" htmlFor={id}>
          {label}
        </label>
      </div>
      {showinfo && (
        <div className="info-field ai_c">
          <img title={infoTxt} src={InfoIconGrey} alt={label} />
          <div className="info-box">
            <p title={infoTxt} className="p10">
              {infoTxt}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SwitcherCheckbox;
