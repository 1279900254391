import React from 'react';
import SwitcherCheckbox from '../Catalogue/SwitcherCheckbox';
import NumberField from '../Catalogue/NumberField';
import {
  ArticleChanges,
  Composition,
  purshaseMethod,
} from '../../models/services/module.catalogue';
import { languageData } from '../../i18n';
import CardFileds from '../../container/CardFileds';
import PaymentMode from '../Catalogue/Etapes/PaymentMode';

/**
 * ModalEditArticle component description.
 *
 * @ModalEditArticle
 *
 */

interface ModalEditArticleProps {
  composition?: Composition;
  onChangeFieldComposition?: <K extends keyof Composition>(
    field: K,
    value: Composition[K],
  ) => void;
  articleChanges?: ArticleChanges;
  purshaseMethods?: purshaseMethod[];
  onClickPurshaseMethod?: (p: purshaseMethod) => void;
}

const ModalEditArticle: React.FC<ModalEditArticleProps> = ({
  composition,
  onChangeFieldComposition,
  purshaseMethods,
  articleChanges,
  onClickPurshaseMethod,
}) => {
  return (
    <div className="ModalEditArticle fl_col fl-1 w-full">
      <div className="field-wrapper fl_col gp20">
        {purshaseMethods && (
          <CardFileds haslabel label="Modes de vente" className="w-full">
            <PaymentMode
              hasError
              activeMethods={articleChanges?.purshaseMethod}
              purshaseMethods={purshaseMethods}
              //   surplace={articleChanges?articleChanges?.purshaseMethod?.findIndex((_) => _.id)>-1:false}

              onClick={onClickPurshaseMethod}
            />
          </CardFileds>
        )}

        {!purshaseMethods && (
          <div className="fl_row gp40">
            <SwitcherCheckbox
              onChange={(e) =>
                onChangeFieldComposition &&
                onChangeFieldComposition('isActif', e.target.checked)
              }
              ischecked={composition?.isActif}
              label="Actif"
            />
            <SwitcherCheckbox
              onChange={(e) => {
                onChangeFieldComposition &&
                  onChangeFieldComposition('inclus', e.target.checked);
                if (!e.target.checked) {
                  onChangeFieldComposition &&
                    onChangeFieldComposition('maxNbrSelection', '1');
                }
              }}
              ischecked={composition?.inclus}
              label={languageData.fr.sidebarIngredient.inclus}
            />
          </div>
        )}
        {!composition?.inclus && (
          <div className="fl_row jc_st gp20">
            <NumberField
           step="3"
            min={1}
              hideControl
              defaultValue={parseInt(composition?.maxNbrSelection ?? '')}
              label={languageData.fr.sidebarIngredient.NbrMxSect}
              onChange={(e) =>
                onChangeFieldComposition &&
                onChangeFieldComposition('maxNbrSelection', e.target.value)
              }
            />
            <NumberField
              step="2"
              defaultValue={parseFloat(composition?.unite ?? '')}
              hideControl
              label={languageData.fr.sidebarIngredient.unite}
              onChange={(e) =>
                onChangeFieldComposition &&
                onChangeFieldComposition('unite', e.target.value)
              }
            />
          </div>
        )}
        {!composition?.inclus && (
          <div className="fl_row gp20">
            <NumberField
              step="2"
              hideControl
              defaultValue={parseFloat(composition?.priceOnSite ?? '')}
              label={languageData.fr.addProduct.pricePlace}
              onChange={(e) =>
                onChangeFieldComposition &&
                onChangeFieldComposition('priceOnSite', e.target.value)
              }
            />
            <NumberField
              step="2"
              hideControl
              defaultValue={parseFloat(composition?.priceEmporter ?? '')}
              label={languageData.fr.addProduct.priceEmporter}
              onChange={(e) =>
                onChangeFieldComposition &&
                onChangeFieldComposition('priceEmporter', e.target.value)
              }
            />
            <NumberField
              step="2"
              hideControl
              defaultValue={parseFloat(composition?.priceDelivery ?? '')}
              label={languageData.fr.addProduct.priceDelivery}
              onChange={(e) =>
                onChangeFieldComposition &&
                onChangeFieldComposition('priceDelivery', e.target.value)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalEditArticle;
