import React from 'react';
import MarketsIconGrey from '../../assets/icons/MarketsIconGrey.svg';

/**
 * CarteMenuStep component description.
 *
 * @CarteMenuStep
 *
 */

interface CarteMenuStepProps {
  title?: string;
  order: number;
  totalSlot: number;
  checkedSlot?: number;
  free?: boolean;
  payed?: boolean;
  active?: boolean;
  onClick?: () => void;
  OnClickMarkets?: () => void;
  hideAllOption?: boolean;
}

const CarteMenuStep: React.FC<CarteMenuStepProps> = ({
  title,
  order,
  totalSlot,
  checkedSlot,
  free,
  payed,
  active,
  onClick,
  OnClickMarkets,
  hideAllOption,
}) => {
  return (
    <div className="CarteMenuStep rd12" onClick={onClick}>
      <div
        className={`step-wrapper OxfordBlue02-txt fl_row jc_s ai_c rd12 pd8-r-l ${active ? 'active-step' : ''}`}
      >
        <div className="left-step">
          <p className="p12 fl_row ai_c">
            {' '}
            <span className="order-number fl_row">{order}</span>{' '}
            <span className="item-name">{title}</span>{' '}
          </p>
        </div>
        {free && <p className="badge p12">Gratuit</p>}
        {payed && <p className="badge p12">Payant</p>}
        <div className="fl_row gp5">
          <div className="right-step fl_row ai_c gp10">
            <p className="p12">
              {' '}
              <span>{checkedSlot}</span>_<span>{totalSlot}</span>{' '}
            </p>
          </div>
          {!hideAllOption && (
            <img
              onClick={OnClickMarkets}
              className="marketIcon"
              src={MarketsIconGrey}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarteMenuStep;
