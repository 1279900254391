import React, { Fragment, useEffect } from 'react';
import { ToastProps } from '../../../../../components/Toast/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTaxesModels } from '../../../../../models/services/module.taxesInfos';
import { languageData } from '../../../../../i18n';
import { unsavedChangesRegister } from '../../../../../store/actions/actions';
import StepWizzard from '../StepWizzard';
import FormCard from '../FormCard';
import InputField from '../../../../../components/Input/Input';
import SelectInput from '../../../../../components/Input/SelectInput';
import ButtonElement from '../../../../../components/button/button';
import ToastHandler from '../../../Toast/ToastHandler';
import { serviceEtablissementModels } from '../../../../../models/services/module.etablissement';
import tabs_list from '../../../../../data/tabsList';
import { useFiscalFormStateEtablissement } from '../../useFiscalFormStateEtablissement';
import { useFetchCodesNAF } from '../../../FormWizzard/useFetchCodesNAF';
import { useUnsavedChanges } from '../../useUnsavedChanges';

interface EtablissementState {
  etablissementState: boolean;
}

export default function Step0() {
  const dispatch = useDispatch();
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);
  const { codesNAF, codesNafOptions, codesNafReferences, codeNafDefaultValue } =
    useFetchCodesNAF();
  const { newFormData } = useUnsavedChanges();
  console.log('newFormData ++ etab + step 0 ', newFormData)
  const {
    siretError,
    siretErrorMsg,
    tvaError,
    tvaErrorMsg,
    nafError,
    nafErrorMsg,
    handleChange,
    verifyData,
  } = useFiscalFormStateEtablissement();

  const verifResponse = useSelector(
    (state: { taxesInfos: serviceTaxesModels.TaxesInfosState }) =>
      state.taxesInfos.taxesInfosResponseEtablissement,
  );
  const etablissementState = useSelector(
    (state: { etablissementStateChangedReducer: EtablissementState }) =>
      state.etablissementStateChangedReducer.etablissementState,
  );
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const onSelectNaf = (e: string) => {
    const selectedItem = codesNAF?.find(
      (item: serviceTaxesModels.CodesNAF) => item.designation === e,
    );

    if (selectedItem) {
      const array = [
        {
          key: tabs_list[1].name,
          value: {
            type: 'action',
            payload: {
              payload: {
                ...newFormData.payload,
                fiscalInformation: {
                  ...newFormData?.payload?.fiscalInformation,
                  naF_code_id: selectedItem.id,
                },
              },
            },
          },
        },
      ];
      dispatch(unsavedChangesRegister(array));
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (etablissementState) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [etablissementState]);

  return (
    <div className="fl_col gp10">
      <Fragment>
        <StepWizzard
          className="FormWizzardContentCard fl_col gp20 ai_c"
          stepNumber={1}
          isActive={addEtablissementStep === 1}
        >
          <FormCard
            labelCard={languageData.global.fiscal_info}
            grid={3}
            gap="gp24"
          >
            <InputField
              name="sireT_code"
              isRequired={true}
              value={newFormData?.payload?.fiscalInformation?.sireT_code}
              type="number"
              label={languageData.global.siret}
              maxLength={14}
              onChange={handleChange}
              style="form"
              isDisabled={false}
              haserror={siretError ?? false}
              errorMsg={siretErrorMsg ?? ''}
            />
            <InputField
              isRequired={true}
              label={languageData.global.vat_intra}
              name="vaT_Intracommunity"
              value={
                newFormData?.payload?.fiscalInformation?.vaT_Intracommunity
              }
              type="text"
              style="form"
              onChange={handleChange}
              isDisabled={false}
              haserror={tvaError ?? false}
              errorMsg={tvaErrorMsg}
            />
            <SelectInput
              defaultValue={codeNafDefaultValue() ?? ''}
              isRequired={true}
              label={languageData.global.naf_number}
              name="naF_code_id"
              onSelect={onSelectNaf}
              target="reference"
              icons={[]}
              reference={codesNafReferences}
              options={codesNafOptions}
              onChange={handleChange}
              haserror={nafError}
              errorMsg={nafErrorMsg}
            ></SelectInput>
          </FormCard>
          {verifResponse.siretValid === true &&
          verifResponse.siretExist === false &&
          verifResponse.tvaExist === false ? (
            <></>
          ) : (
            <ButtonElement
              type="TextButton"
              text={languageData.global.Form.verify}
              buttonSize="medium"
              buttonStyle="btn-primary"
              onClick={verifyData}
            />
          )}
        </StepWizzard>
      </Fragment>
      <ToastHandler ref={toastRef} />
    </div>
  );
}
