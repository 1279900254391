export const convertPathToId = (path: string) => {
  const pattern = /\/(\d+)$/;
  return path.replace(pattern, '/:id');
};
export const isUrlInFormat = (url: string) => {
  //const pattern = /^\/\w+\/edit\/\d+$/;
  return url.includes('edit');
};
export const showSearchBar = (currentPathState: string) => {
  return (
    !currentPathState.includes('articles') &&
    !currentPathState.includes('ecrans/ajouter') &&
    !currentPathState.includes('ecrans/edit') &&
    !currentPathState.includes('etapes/ajouter') &&
    !currentPathState.includes('etapes/edit') &&
    !currentPathState.includes('menus/ajouter') &&
    !currentPathState.includes('menus/edit') &&
    !currentPathState.includes('categories/ajouter') &&
    !currentPathState.includes('categories/edit') &&
    !currentPathState.includes('type-de-machine')
  );
};
