import React, { ChangeEvent, useState } from 'react';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
import ErrorIconRed from '../../assets/icons/ErrorIconRed.svg';
import ClearInputGrey from '../../assets/icons/ClearInputGrey.svg';
import SubtractInputPrimary from '../../assets/icons/SubtractInputPrimary.svg';

/**
 * FieldName component description.
 *
 * @FieldName
 *
 */

interface FieldNameProps {
  label?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  onClickEdit?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  defaultValue?: string;
  max?: number;
  errorMsg?: string;
}
const FieldName: React.FC<FieldNameProps> = ({
  label,
  type,
  placeholder,
  name,
  id,
  onClickEdit,
  onChange,
  onClick,
  defaultValue,
  max,
  errorMsg,
}) => {
  const [showEditMode, setShowEditMode] = useState(false);
  const HandelEditMode = () => {
    setShowEditMode((prevState) => !prevState);
  };
  const ClearInput = () => {
    alert('Clear inp');
  };
  const ValidateInp = () => {
    alert('Validate inp');
  };

  return (
    <div
      className={`FieldName w-full fl_col gp5 relative ${showEditMode ? 'EditMode' : ''}`}
    >
      {label && (
        <label className="DarkBlueGray-txt p12 w-500 pd16-r-l" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="fl_row">
        {showEditMode ? (
          <>
            <input
              autoComplete="off"
              defaultValue={defaultValue}
              className="EerieBlack p16 w-300 w-full pd16-r-l"
              maxLength={max ?? 25}
              type="text"
              placeholder={placeholder}
              name="name"
              id="id"
              onChange={onChange}
              onClick={onClick}
            />
            <div className="tools fl_row gp10">
              <img
                className="c-p"
                src={ClearInputGrey}
                alt=""
                width={20}
                onClick={ClearInput}
              />
              <img
                className="c-p"
                src={SubtractInputPrimary}
                alt=""
                width={20}
                onClick={HandelEditMode}
              />
            </div>
          </>
        ) : (
          <div className="field-wrapper fl_row ai_c jc_s">
            <label className="black-txt-txt p16 w-500 pd16-r-l" htmlFor={id}>
              {defaultValue}
            </label>
            <div className="tools">
              <img
                className="c-p fl_row"
                src={EditGreyIcon}
                alt=""
                width={20}
                onClick={HandelEditMode}
              />
            </div>
          </div>
        )}
      </div>
      {errorMsg && (
        <div className="fl_row gp5 ai_c errorMsg">
          <img
            src={ErrorIconRed}
            alt=""
            style={{
              width: 15,
              height: 15,
            }}
          />
          <p className=" p12 VenetianRed-text">{errorMsg}</p>
        </div>
      )}
    </div>
  );
};

export default FieldName;
