import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddProductIconBlue from '../../assets/icons/AddProductIconBlue.svg';
import AddProductIcon from '../../assets/icons/AddProductIconLightGrey.svg';
import ChecklistIcon from '../../assets/icons/ChecklistIcon.svg';
import MenuIcon from '../../assets/icons/MenuIcon.svg';
import SearchIconGreyInp from '../../assets/icons/SearchIconGreyInp.svg';
import AddProduct from '../../components/Catalogue/Articles/AddProduct';
import InputField from '../../components/Input/InputCatalogue';
import { languageData } from '../../i18n';
import { Menu } from '../../models/services/module.catalogue';
import {
  changeSidebarMenuMode,
  dragSubMenu,
  startGetMenu,
  updateDeletedSubMenus,
  updateSubMenus,
} from '../../store/actions/catalogues/actions';
import IngredientNavbarContainer from '../Articles/IngredientNavbar';
export default function NavCategory(props: any) {
  const [activeTab, setActiveTab] = useState('article');
  const [menusResult, setmenusResult] = useState<Menu[]>();
  const [menus, setmenus] = useState<Menu[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const deletedSubMenu = useSelector(
    (state: { subMenuStates: { deletedsubMenu: Menu[] } }) =>
      state.subMenuStates.deletedsubMenu,
  );
  const dispatch = useDispatch();
  const subMenuDragged = useSelector(
    (state: { subMenuStates: { subMenus: Menu[] } }) =>
      state.subMenuStates.subMenus,
  );
  const fetchMenus = () => {
    dispatch(
      startGetMenu((_) => {
        setmenus(withDisabledSubMenuDragged(_ as Menu[], subMenuDragged));
      }),
    );
  };
  useEffect(() => {
    if (activeTab == 'menus') {
      fetchMenus();
    }
  }, [activeTab]);
  useEffect(() => {
    dispatch(changeSidebarMenuMode('article'));
  }, []);
  useEffect(() => {
    if (subMenuDragged) {
      setmenus(withDisabledSubMenuDragged(menus, subMenuDragged as Menu[]));
    }
  }, [subMenuDragged]);
  const withDisabledSubMenuDragged = (
    menus: Menu[],
    menuDragged: Menu[],
  ): Menu[] =>
    menus?.map((l) => {
      const disabledSet = new Set(menuDragged?.map((l) => l.idMenu ?? l.id));
      return {
        ...l,
        selected: false,
        disabled: disabledSet.has(l.idMenu ?? l.id),
      } as Menu;
    });
  const onDragSubMenu = (menu: Menu) => {
    let selectedMenu =
      deletedSubMenu.findIndex(
        (c) => (c.idMenu ?? c.id) == (menu.idMenu ?? menu.id),
      ) > -1
        ? deletedSubMenu[
            deletedSubMenu.findIndex(
              (c) => (menu.idMenu ?? menu.id) == (c.idMenu ?? c.id),
            )
          ]
        : menu;
    if (
      deletedSubMenu.findIndex(
        (c) => (c.idMenu ?? c.id) == (menu.idMenu ?? menu.id),
      ) > -1
    ) {
      dispatch(
        updateDeletedSubMenus(
          deletedSubMenu.filter(
            (i) => (i.idMenu ?? i.id) != (menu.idMenu ?? menu.id),
          ),
        ),
      );
    }
    dispatch(dragSubMenu(selectedMenu as Menu));
  };
  const onSelectMenu = (menu: Menu, checked: boolean, all?: boolean) => {
    if (menus)
      setmenus(
        all
          ? menus.map((c) => ({ ...c, selected: checked }))
          : menus.map((c) =>
              c.id === menu.id ? { ...c, selected: checked } : c,
            ),
      );
  };

  const onSelectAllMenus = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectMenu({} as Menu, e.target.checked, true);
  };
  const onDragAllMenus = () => {
    onDragSelectedMenus(menus.filter((_) => _.selected));
  };
  const handleChangeNavMode = (mode: string) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setActiveTab(mode);
    dispatch(changeSidebarMenuMode(mode));
  };
  const onDragSelectedMenus = (menus: Menu[]) => {
    // dispatch(updateDeletedIngredients(updatedDeletedIngredients));
    dispatch(updateSubMenus([...subMenuDragged, ...menus]));
  };
  const iconDraggable = () => {
    return !menus.find((_) => _.selected) ? AddProductIcon : AddProductIconBlue;
  };
  const isActiveButton = () => {
    return !menus.find((_) => _.selected);
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let __ = e.target.value.toLowerCase();
    setmenusResult(
      withDisabledSubMenuDragged(
        __ == ''
          ? menus
          : menus.filter((_) => _.designation.toLowerCase().includes(__)),
        subMenuDragged,
      ),
    );
  };
  return (
    <div className="NavMenu h-full">
      <div className="nav-wrapper pd12">
        <div className="wrapper-header fl_row">
          <div
            className={`article menu-item c-p pd10 fl_row ai_c gp10 fl-1 ${activeTab === 'article' ? 'active-menu' : ''}`}
            onClick={() => handleChangeNavMode('article')}
          >
            <img src={ChecklistIcon} alt="" />
            <p className="p14">{languageData.fr.navMenu.article}</p>
          </div>
          <div
            className={`menus menu-item c-p pd10 fl_row ai_c gp10 fl-1 ${activeTab === 'menus' ? 'active-menu' : ''}`}
            onClick={() => handleChangeNavMode('menus')}
          >
            <img src={MenuIcon} alt="" />
            <p className="p14">{languageData.fr.navMenu.menus}</p>
          </div>
        </div>
        <div className="nav-wrapper">
          {!(activeTab == 'article') && (
            <div className="search-inp">
              <img className="icon-search" src={SearchIconGreyInp} alt="" />
              <InputField
                reff={inputRef}
                onChange={onSearch}
                type="text"
                style="search"
                placeholder={languageData.fr.navMenu.recherche}
              />
            </div>
          )}
          <div
            className={`products-list ${activeTab == 'article' ? '' : 'pd20-t-b pd8-r-l'} `}
          >
            <div className="products-wrapper">
              {!(activeTab == 'article') && (
                <div className="header-products fl_row jc_s pd10-t-b">
                  <div className="fl_row ai_c gp8 ">
                    <input
                      autoComplete="off"
                      type="checkbox"
                      name=""
                      id=""
                      onChange={onSelectAllMenus}
                    />
                    <p className="p12 OxfordBlue02-txt">
                      {languageData.fr.navMenu.selectionnerTous}
                    </p>
                  </div>
                  <div
                    className="Select-all fl_row gp8 c-p"
                    onClick={onDragAllMenus}
                  >
                    <p
                      className={`p12  ${isActiveButton() ? 'LightSilver-txt' : ''}`}
                    >
                      {languageData.fr.navMenu.ajouter}
                    </p>{' '}
                    <img src={iconDraggable()} alt="" />
                  </div>
                </div>
              )}
              {activeTab === 'article' && (
                <IngredientNavbarContainer></IngredientNavbarContainer>
              )}
              {activeTab === 'menus' && (
                <div className="menus-tab">
                  <div className="listing-products fl_col gp12">
                    {(menusResult ? menusResult : menus)?.map(
                      (_, __) =>
                        !_.disabled && (
                          <AddProduct
                            selected={_.selected}
                            onDrag={() => onDragSubMenu(_)}
                            name={_.designation}
                            img={_.url}
                            onChange={(e) => onSelectMenu(_, e.target.checked)}
                          />
                        ),
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
