import React from 'react';

interface Props {
  strokeColor?: string;
}

const EtapesIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 5.9987L5 5.9987" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M13 1.9987L5 1.9987" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M13 9.9987L5 9.9987" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M2.33333 5.9987C2.33333 6.36689 2.03486 6.66536 1.66667 6.66536C1.29848 6.66536 1 6.36689 1 5.9987C1 5.63051 1.29848 5.33203 1.66667 5.33203C2.03486 5.33203 2.33333 5.63051 2.33333 5.9987ZM2.33333 1.9987C2.33333 2.36689 2.03486 2.66536 1.66667 2.66536C1.29848 2.66536 1 2.36689 1 1.9987C1 1.63051 1.29848 1.33203 1.66667 1.33203C2.03486 1.33203 2.33333 1.63051 2.33333 1.9987ZM2.33333 9.9987C2.33333 10.3669 2.03486 10.6654 1.66667 10.6654C1.29848 10.6654 1 10.3669 1 9.9987C1 9.63051 1.29848 9.33203 1.66667 9.33203C2.03486 9.33203 2.33333 9.63051 2.33333 9.9987Z" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default EtapesIcon;
