import React from 'react';
import ArrowRightPrimary from '../../assets/icons/ArrowRightPrimary.svg';
import DoneIconGreen from '../../assets/icons/DoneIconGreen.svg';
/**
 * FormWizzardHeaderSingleStep component description.
 *
 * @FormWizzardHeaderSingleStep
 *
 */

interface FormWizzardHeaderSingleStepProps {
  Icon: React.ReactNode;
  Name: string;
  Description?: string;
  Tag?: string;
  isActive?: boolean;
  activePartOne?: boolean;
  donePartOne?: boolean;
  activePartTwo?: boolean;
  donePartTwo?: boolean;
  isDone?: boolean;
}

const FormWizzardHeaderSingleStep: React.FC<
  FormWizzardHeaderSingleStepProps
> = ({
  Icon,
  Name,
  Description,
  Tag,
  isActive,
  isDone,
  activePartOne,
  donePartOne,
  activePartTwo,
  donePartTwo,
}) => {
  const renderIcon = () => {
    if (Icon) {
      return React.cloneElement(Icon as React.ReactElement, {
        strokeColor: isActive ? '#2A66C6' : '#475467',
      });
    }
  };
  return (
    <div
      className={`FormWizzardHeaderSingleStep ${isActive ? '' : 'InactiveStep'}`}
    >
      <div
        className={`step-wrapper ${isActive ? 'ActiveStep' : ''} ${isDone ? 'isDone' : ''}`}
      >
        <div className="StepContent fl_col gp4">
          <div className="header-step-label fl_col gp4">
            <div className="IconStep">
              {isDone ? (
                <img src={DoneIconGreen} alt="DoneIconGreen" />
              ) : (
                renderIcon()
              )}
            </div>
            <p
              className={`p16  Independence-txt ${isActive ? 'w-700' : 'w-500'}`}
            >
              {Name}
            </p>
          </div>

          <div className="steps">
            <div className={`StepDescription fl_row ai_c gp6 jc_c`}>
              {activePartOne && (
                <img src={ArrowRightPrimary} alt="ArrowRightPrimary" />
              )}
              <p
                className={`p16 txt-nowrap w-500 ${activePartOne ? 'BrilliantAzure-txt' : 'AuroMetalSaurus-txt'} ${donePartOne ? 'SpanishGreen-txt' : ''}`}
              >
                {Description}
              </p>
            </div>

            <div className={`StepDescription fl_row ai_c gp6 jc_c`}>
              {activePartTwo && (
                <img src={ArrowRightPrimary} alt="ArrowRightPrimary" />
              )}
              <p
                className={`p16 txt-nowrap w-500 ${activePartTwo ? 'BrilliantAzure-txt' : 'AuroMetalSaurus-txt'} ${donePartTwo ? 'SpanishGreen-txt' : ''}`}
              >
                {Tag}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormWizzardHeaderSingleStep;
