import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import {
  getCompanyByIdFinancialInfos,
  getCompanyByIdInfos,
  getCompanyByIdSheet,
} from '../../../store/actions/actions';
import EntrepriseProfileInfoContainer from '../../../container/Etablissement/TabsCompany/EntrepriseProfileInfoContainer';
import { serviceTabsModels } from '../../../models/services/module.tabs';

export default function EntrepriseProfileInfo() {
  const [activeMiniTab, setActiveMiniTab] = useState<string>('1');
  const dispatch = useDispatch();
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  useEffect(() => {
    if (activeTabContent.miniTab === 'catalogue') {
      setActiveMiniTab('7');
    }
  }, [activeTabContent]);
  useEffect(() => {
    if (activeTabContent.id) {
      dispatch(getCompanyByIdSheet({ id: activeTabContent.id }));
      dispatch(getCompanyByIdInfos({ id: activeTabContent.id }));
      dispatch(getCompanyByIdFinancialInfos({ id: activeTabContent.id }));
    }
  }, [activeTabContent.id]);

  return (
    <div className="EntrepriseProfileInfo">
      <EntrepriseProfileInfoContainer
        id={activeTabContent.id ?? 0}
        activeMiniTab={activeMiniTab}
        setActiveMiniTab={setActiveMiniTab}
      />
    </div>
  );
}
