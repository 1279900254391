import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput2 from '../../components/Input/CountrySelect/SelectInput2';
import InputField from '../../components/Input/Input';
import { languageData } from '../../i18n';
import { setUserFormDataDetails } from '../../store/actions/actions';
import FormCard from '../Etablissement/FormWizzard/FormCard';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import flagsAndNamesSeparator from '../../utils/FlagsAndNamesSeparator';
import validateEmail from '../../utils/EmailValidation';
import useUserFormValidation, {
  missingRequiredField,
} from './useUserFormValidation';
import compareObjects from '../../utils/CompareObjects';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { transformUserDetailsToPermissions } from '../../utils/transformUserDetailsToPermissions';

export default function EditUserProfileCard() {
  const dispatch = useDispatch();
  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormDataDetails,
  );
  const userDetails = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userDetails,
  );
  const userFormValidationRequested = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormValidationRequested,
  );
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const { countriesCodes, countriePhoneCodes, flags } =
    flagsAndNamesSeparator();

  useEffect(() => {
    const newPermissions = transformUserDetailsToPermissions(userDetails);
    const newUserDetails = {
      user: {
        id: activeTabContent.id,
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber,
        isActive: userDetails.isActive,
      },
      permissions: newPermissions,
    };
    const same = compareObjects(userFormData, newUserDetails);
    if (!same) {
      dispatch(setUserFormDataDetails(newUserDetails));
    }
  }, [dispatch, userDetails]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      setUserFormDataDetails({
        ...userFormData,
        user: {
          ...userFormData.user,
          id: activeTabContent.id,
          [name]: value,
        },
      }),
    );
  };

  const onSelect = (selectedValue: string) => {
    dispatch(
      setUserFormDataDetails({
        ...userFormData,
        user: {
          ...userFormData.user,
          phoneNumber: selectedValue,
        },
      }),
    );
  };

  return (
    <div className="profile-infos fl_col gp20">
      <div className="header-block">
        <p className="p16 w-600 Nevada-txt pd22-t-b">
          {languageData.global.user.profile}
        </p>
      </div>
      <FormCard grid={2} gap="gp20">
        <InputField
          value={userFormData?.user?.lastName || ''}
          name="lastName"
          isRequired={true}
          style="form"
          label={languageData.global.user.lastname}
          onChange={handleChange}
          haserror={
            userFormValidationRequested &&
            missingRequiredField(userFormData?.user?.lastName)
          }
          errorMsg={languageData.global.Form.errorMsg.requiredFieldMissing}
          showSkeleton = {false}
        />
        <InputField
          value={userFormData?.user?.firstName || ''}
          name="firstName"
          isRequired={true}
          style="form"
          label={languageData.global.user.firstname}
          onChange={handleChange}
          haserror={
            userFormValidationRequested &&
            missingRequiredField(userFormData.user.firstName)
          }
          errorMsg={languageData.global.Form.errorMsg.requiredFieldMissing}
          showSkeleton = {false}
        />
        <InputField
          value={userFormData?.user?.email || ''}
          name="email"
          isRequired={true}
          style="form"
          label={languageData.global.Form.email}
          onChange={handleChange}
          haserror={
            (userFormValidationRequested &&
              !validateEmail(userFormData?.user?.email)) ||
            (userFormValidationRequested &&
              missingRequiredField(userFormData?.user?.email))
          }
          errorMsg={
            userFormData?.user?.email?.length !== 0
              ? languageData.global.Form.errorMsg.emailNotValid
              : languageData.global.Form.errorMsg.requiredFieldMissing
          }
          showSkeleton = {false}
        />
        <SelectInput2
          defaultValue={userFormData?.user?.phoneNumber}
          placeholder={userFormData?.user?.phoneNumber}
          isRequired={false}
          label={languageData.global.Form.mobileLabel}
          name="phoneNumber"
          onSelect={(selectedValue: string) => onSelect(selectedValue)}
          target="reflags"
          icons={flags}
          reference={countriePhoneCodes}
          options={countriesCodes}
          onChange={handleChange}
          isDisabled={false}
          haserror={false}
          showSkeleton = {false}
        />
      </FormCard>
    </div>
  );
}
