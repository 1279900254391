import React from 'react';

/**
 * button component description.
 *
 * @button
 *
 */

interface buttonElementProps {
  type: 'TextButton' | 'IconButton';
  text: string;
  textSize?: string;
  textColor?: string;
  icon?: string;
  className?: string;
  buttonStyle?: string;
  reverse?: boolean;
  buttonSize:
    | 'small'
    | 'medium'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'full'
    | 'none';
  radius?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  primaryLoader?: boolean;
}

const ButtonElement: React.FC<buttonElementProps> = ({
  type,
  text,
  textColor,
  textSize,
  icon,
  className,
  buttonSize,
  buttonStyle,
  radius,
  onClick,
  isDisabled,
  reverse,
  isLoading,
  primaryLoader,
}) => {
  const buttonClass = className
    ? `btn ButtonElement ${isDisabled ? 'isDisabled' : ''} ${type} ${className}  fl_row ai_c jc_c`
    : `btn ButtonElement ${isDisabled ? 'isDisabled' : ''} ${type} fl_row ai_c jc_c`;

  const buttonTextClass = textColor
    ? `${textSize} ${textColor}`
    : `${textSize}`;

  return (
    <div
      // style={
      //   background
      //     ? { backgroundColor: background }
      //     : { backgroundColor: "#2A66C6" }
      // }
      className={buttonClass}
    >
      {type === 'TextButton' && (
        <button
          className={`button-wrapper ${isLoading ? 'isLoading' : ''} ${primaryLoader ? 'loader-color-primary' : 'loader-color-white'} ${radius ? radius : ''} ${buttonStyle ? buttonStyle : ''} ${buttonSize ? buttonSize : ''}`}
          onClick={onClick}
          disabled={isDisabled ? true : false}
        >
          <p className={buttonTextClass ? buttonTextClass : ''}>{text}</p>
        </button>
      )}
      {type === 'IconButton' && (
        <button
          className={`button-wrapper ${isLoading ? 'isLoading' : ''} ${primaryLoader ? 'loader-color-primary' : 'loader-color-white'} relative ${radius ? radius : ''} ${buttonStyle} ${buttonSize}`}
          onClick={onClick}
          disabled={isDisabled ? true : false}
        >
          {isLoading && (
            <div className="btn-loader">
              <div className="btn-loader-display"></div>
            </div>
          )}
          {reverse ? (
            <div className="btn-elements fl_row ai_c gp8 jc_c">
              <img src={icon} alt={icon} />
              <p className={buttonTextClass ? buttonTextClass : ''}>
                {text ? text : ''}
              </p>
            </div>
          ) : (
            <div className="btn-elements fl_row ai_c gp8 jc_c">
              <p className={buttonTextClass ? buttonTextClass : ''}>
                {text ? text : ''}
              </p>
              <img src={icon} alt={icon} />
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default ButtonElement;
