import React, { ChangeEvent } from 'react';

/**
 * CheckBox component description.
 *
 * @CheckBox
 *
 */

interface CheckBoxProps {
  label: string;
  isChecked?: boolean;
  name?: string;
  className?: string;
  id?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  boxStyle?: boolean;
  circle?: boolean;
  errorMsg?: string;
  defaultValue?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  isChecked,
  className,
  onChange,
  name,
  boxStyle,
  circle,
  id,
  defaultValue
}) => {
  return (
    <div
      className={`CheckBox relative fl_row ${isChecked ? 'checked' : ''} ${circle ? 'circle' : ''} ${boxStyle ? 'boxStyle' : ''}`}
    >
      <div className="checkbox-wrapper fl_row ai_c gp8">
        <input
          defaultChecked={defaultValue}
          autoComplete="off"
          type="checkbox"
          name={name}
          id={id}
          checked={isChecked}
          onChange={onChange}
        />
        <label
          className={`txt-nowrap p12 OxfordBlue02-txt w-500 ${className}`}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
      {/* {errorMsg && (
        <div className="fl_row gp5 ai_c errorMsgCheckbox">
          <img src={ErrorIconRed} alt="" />
          <p className=" p12 VenetianRed-text">
            {'Field error msg is wrong !'}
          </p>
        </div>
      )} */}
    </div>
  );
};

export default CheckBox;
