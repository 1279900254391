import React from 'react';
import ExistCompanyIcon from '../../../assets/icons/ExistCompanyIcon.svg';
import AddCompanyIcon from '../../../assets/icons/AddCompanyIcon.svg';
import RightArrowLarge from '../../../assets/icons/RightArrowLargeiconGrey.svg';
import SearchIconGreyInp from '../../../assets/icons/SearchIconGreyInp.svg';
import FilterDropdownIcon from '../../../assets/icons/FilterDropdownIcon.svg';
import SelectInput from '../../../components/Input/SelectInput';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import {
  getAllEntreprise,
  selectExistingEntrepriseCreateEtablissment,
  combineEtablissementAction,
} from '../../../store/actions/actions';

import SelectedCompanyCreateEtablissement from './SelectedCompanyCreateEtablissement';
import { languageData } from '../../../i18n';
import NewCompanyCreateEtablissement from '../CombinedProcess/NewCompanyCreateEtablissement';
interface existingCompanyEtablissmentProps {
  id?: number;
  campanyId?: string;
}
export default function AddCreateCompany(
  props: existingCompanyEtablissmentProps,
) {
  const [isFormVisible, setIsFormVisible] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const [paginationModel] =
    React.useState<servicePaginationModels.PaginationModelType>({
      paginationModel: {
        page: 1,
        pageSize: 500,
        nbPage: 0,
      },
    });
  const fetchCompaniesList = React.useCallback(
    (paginationModel: servicePaginationModels.PaginationModelType) => {
      dispatch(getAllEntreprise(paginationModel));
    },
    [],
  );

  const claimsEntreprise = useSelector(
    (state: any) => state.auth.claimsEntreprise,
  );

  const checkClaims = () => {
    if (!claimsEntreprise?.includes('Géneral.Affichage')) {
      return false;
    }
    if (!claimsEntreprise?.includes('Géneral.Ajout')) {
      return false;
    }
    return true;
  };

  const isClaimsValid = checkClaims();

  React.useEffect(() => {
    fetchCompaniesList(paginationModel);
  }, [fetchCompaniesList, paginationModel]);
  const companies_list = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.entreprises,
  );

  const combineEtablissement = useSelector(
    (state: {
      combineEtablissementReducer: { combineEtablissement: boolean };
    }) => state.combineEtablissementReducer.combineEtablissement,
  );
  console.log("prooooops",props)

  const handleSelectCombineEtablissement = () => {
    dispatch(combineEtablissementAction(!combineEtablissement));
  };
  React.useEffect(() => {
    if (props.campanyId!=undefined) {
      setIsFormVisible(true);
    }
  }, [props.campanyId]);
  const companies_options = React.useMemo(() => {
    return companies_list
      ? companies_list.map((item: serviceEntrepriseModels.companyListItem) => {
          return item.commercial_Name || '';
        })
      : [];
  }, [companies_list]);
  const handleSelectCompany = (value: string) => {
    if (!companies_list) {
      return;
    }
    const selectedCompany = companies_list.find(
      (item: serviceEntrepriseModels.companyListItem) =>
        item.commercial_Name === value,
    );
    {
      selectedCompany
        ? (dispatch(
            selectExistingEntrepriseCreateEtablissment(
              selectedCompany.id ? selectedCompany.id : props.id ? props.id : 0,
            ),
          ),
          setIsFormVisible(true))
        : null;
    }
  };
  return (
    <div className="AddCreateCompany h-full">
      {!isFormVisible && !combineEtablissement ? (
        <div className="cards-wrapper fl_col gp32">
          <div className="SelectCompany CardContainerCompany">
            <div className="card-wrapper fl_col gp10 ai_c pd20-t-b">
              <img loading="lazy" src={ExistCompanyIcon} alt="" width={90} />
              <div className="fl_col boxParent gp20">
                <div className="fl_col gp4 box-data">
                  <p className="p32">{languageData.global.ExistingCompany}</p>
                  <p className="p16 w-300">
                    {languageData.global.associateEtablissementExisting}
                  </p>
                </div>
                <div className="select">
                  <img
                    loading="lazy"
                    className="search-icon"
                    src={SearchIconGreyInp}
                    alt=""
                  />
                  <SelectInput
                    target="search"
                    options={companies_options}
                    isRequired
                    onSelect={(value: string) => handleSelectCompany(value)}
                  />
                  <img
                    loading="lazy"
                    className="dropdown-icon"
                    src={FilterDropdownIcon}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          {isClaimsValid && (
            <div
              className="AddCompany CardContainerCompany"
              onClick={handleSelectCombineEtablissement}
            >
              <Link to="/"></Link>
              <div className="card-wrapper fl_col gp10 ai_c pd20-t-b boxParent">
                <img loading="lazy" src={AddCompanyIcon} alt="" width={90} />
                <div className="fl_col gp4 box-data">
                  <p className="p24">{languageData.global.NewCompany}</p>
                  <p className="p16 w-300">
                    {languageData.global.associateEtablissementNew}
                  </p>
                </div>
              </div>
              <img
                loading="lazy"
                className="arrow-right"
                src={RightArrowLarge}
                alt=""
              />
            </div>
          )}
        </div>
      ) : null}
      {isFormVisible ? <SelectedCompanyCreateEtablissement /> : null}
      {combineEtablissement ? <NewCompanyCreateEtablissement /> : null}
    </div>
  );
}
