import React, { useEffect, useState } from 'react';
import TableHeader from '../../components/Table/TableHeader';
import { languageData } from '../../i18n';
import CardContainer from '../CardContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  editUser,
  getUserByIdAction,
  resetEditUserSuccess,
  resetUserFormDataDetails,
  setUserFormDataDetails,
  setUserFormValidationRequested,
} from '../../store/actions/actions';
import { ToastProps } from '../../components/Toast/Toast';
import ToastHandler from '../Etablissement/Toast/ToastHandler';
import useUserFormValidation from './useUserFormValidation';
import { CloseTab } from '../../services/tabs/CloseTab';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import EditUserProfileCard from './EditUserProfileCard';
import Modal from '../../components/Modal/Modal';
import EditUserPermissionsCard from './EditUserPermissionsCard';
import { transformUserDetailsToPermissions } from '../../utils/transformUserDetailsToPermissions';
import tabs_list from '../../data/tabsList';
import EditUserProfilePassword from './EditUserProfilePassword';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
export default function EditUser() {
  const [modalDelete, SetmodalDelete] = React.useState<boolean>(false);
  const [modalShowEditProfile, SetmodalShowEditProfile] = useState(false);
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );

  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormDataDetails,
  );
  const initialUserFormData =
    React.useRef<serviceAppUserModels.AppUserPayload>(userFormData);
  const deleteUserSuccess = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.deleteUserSuccess,
  );
  const dispatch = useDispatch();
  const formHasErrors = useUserFormValidation(userFormData);

  const userDetails = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userDetails,
  );

  useEffect(() => {
    dispatch(resetEditUserSuccess());
    if (activeTabContent.id) {
      const newPermissions = transformUserDetailsToPermissions(userDetails);
      dispatch(getUserByIdAction(activeTabContent.id));
      const userDetailsContent = {
        ...userFormData,
        user: {
          ...userFormData.user,
          id: activeTabContent.id,
          isActive: userDetails.isActive,
        },
        permissions: newPermissions,
      };
      dispatch(
        setUserFormDataDetails(
          userDetailsContent as serviceAppUserModels.AppUserPayload,
        ),
      );
    }
  }, [activeTabContent.id]);

  const onClickSave = () => {
    dispatch(setUserFormValidationRequested(true));

    if (!formHasErrors) {
      dispatch(editUser(userFormData));
      closeTab();
    } else {
      console.log('onClickSave edit !formHasErrors', formHasErrors);
    }
  };

  useEffect(() => {
    if (deleteUserSuccess === true) {
      dispatch(resetUserFormDataDetails()), closeTab();
    }
  }, [deleteUserSuccess]);
  const closeTab = () => {
    CloseTab({
      key: tabs_list[11].tabContent.key,
      open_tabs,
      name: userDetails.firstName + ' ' + userDetails.lastName,
      dispatch,
      id: userDetails.id,
    });
  };
  const handleChange = () => {
    const updatedData = {
      ...userFormData,
      user: {
        ...userFormData.user,
        id: activeTabContent.id,
        isActive: !userFormData?.user?.isActive,
      },
    };
    dispatch(
      setUserFormDataDetails(
        updatedData as serviceAppUserModels.AppUserPayload,
      ),
    );
  };
  const showmodalDelete = () => {
    SetmodalDelete((prev) => !prev);
  };
  const cancelDeleteUser = () => {
    SetmodalDelete((prev) => !prev);
  };
  const confirmDeleteUser = () => {
    SetmodalDelete((prev) => !prev);
    dispatch(deleteUser(activeTabContent.id));
  };
  // Cancel changes and reset to initial state under dev
  const cancelChanges = () => {
    if (initialUserFormData.current) {
      dispatch(setUserFormDataDetails(initialUserFormData.current));
    }
  };
 

  return (
    <div className="EditUser">
      <div className="user-wrapper fl_col gp10">
        <div className="header-Content">
          <TableHeader
            title={languageData.global.user.titleAddUser}
            type="edituser"
            textAdd={languageData.global.Modal.save}
            onChange={handleChange}
            labelSwitcher={languageData.global.status.actif}
            onClickDelete={showmodalDelete}
            onClickSave={onClickSave}
            ischecked={userFormData?.user?.isActive ?? false}
            hasPassword={!!userDetails.passwordHash}
          />
        </div>

        <div className="body-Content">
          <div className="fl_col gp12">
            <CardContainer type={'form'}>
              <EditUserProfileCard />
            </CardContainer>
            <CardContainer ClassName="change-user-pw hide" type={'form'}>
              <div className="header-block">
                <p className="p16  w-600 Nevada-txt pd22-t-b">
                  {languageData.global.user.Motdepasse}
                </p>
              </div>
              <div
                className="btn-pw fl_row ai_c gp10"
                onClick={() => SetmodalShowEditProfile(true)}
              >
                <p className="p15 w-600">Modifier mon mot de passe</p>
                <img src={EditGreyIcon} alt="" />
              </div>
              <Modal
                className="AddSubscriptionsModal"
                version="custom"
                title={'Modifier mot de passe'}
                visibility={modalShowEditProfile}
                close
                maxWidth={640}
                content={
                  <EditUserProfilePassword
                    oldPassword={'MAP?EKHEJEtj'}
                    newPassword={'MAP?EKHEJEtj*'}
                    rewritePassword={'MAP?EKHEJEtj'}
                    onClickCancel={() => SetmodalShowEditProfile(false)}
                    onClickSave={() => alert('')}
                  />
                }
                onClickClose={() => SetmodalShowEditProfile(false)}
              />
            </CardContainer>
            <CardContainer type={'form'}>
              <EditUserPermissionsCard cancelChanges={cancelChanges} />
            </CardContainer>
          </div>
        </div>
      </div>
      <Modal
        description={[languageData.global.user.deleteDescription]}
        title={languageData.global.user.deleteConfirm}
        visibility={modalDelete}
        maxWidth={544}
        height={181}
        version={'Alert'}
        onClickConfirm={confirmDeleteUser}
        onClickClose={cancelDeleteUser}
        confirm
        cancel
      />
      <ToastHandler ref={toastRef} />
    </div>
  );
}
