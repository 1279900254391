import React, { useEffect, useRef, useState } from 'react';
import InputField from './InputCatalogue';
import OptionItem from './OptionItemCatalogue';
import SearchIconGreyInp from '../../assets/icons/SearchIconGreyInp.svg';
import BottomArrowGreyIcon from '../../assets/icons/BottomArrowGreyIcon.svg';
import { Option } from '../../utils/catalogueHelper/FamilyHelper';
import { languageData } from '../../i18n';
import SmallPlaceholderImage from '../../assets/icons/smalImageAvatar.svg';
import CloseBtnIconGrey from '../../assets/icons/CloseIconGrey.svg';

import { isValidURL } from '../../utils/catalogueHelper/EmptyHelper';
interface SelectInputProps {
  label?: string;
  name?: string;
  type?: string;
  onChange?: (id: number) => void;
  style?: string;
  isDisabled?: boolean;
  haserror?: boolean;
  errorMsg?: string;
  placeholder?: string;
  options: Option[];
  icons?: string[];
  target: 'country' | 'dropdown' | 'search' | 'color';
  onKeyUp?: () => void;
  defaultValue?: string;
  autoSelectFirst?: boolean;
  withIcon?: boolean;
  Codecolor?: string;
  hideOptionItem?: boolean;
}
const SelectInputCatalogue: React.FC<SelectInputProps> = ({
  label,
  name,
  isDisabled,
  placeholder,
  icons,
  options,
  onChange,
  target,
  defaultValue,
  autoSelectFirst,
  withIcon,
  Codecolor,
  hideOptionItem,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultValue ?? '',
  );
  const [inputIcon, setInputIcon] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const [filteredOptions, setfilteredOptions] = useState<Option[]>();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value == '' ||
      options.findIndex((o) => o.label == e.target.value) == -1
    ) {
      console.log('-1 select');
      if (onChange) onChange(-1);
    }
    onSearch(e.target.value);

    setInputValue(e.target.value);
    setShowDropdown(true);
  };

  const handleOptionClick = (
    optionValue: string,
    id: number,
    optionIcon?: any,
  ) => {
    //  onSearch(optionValue);
    setInputValue(optionValue);
    setInputIcon(optionIcon);
    setShowDropdown(false);
    setfilteredOptions(options);

    // console.log('Option selected:', optionValue, 'Icon:', optionIcon);
    onChange && onChange(id);
  };
  const onSearch = (inputValue: string) => {
    const filteredOptionss = options.filter((option) =>
      inputValue
        ? option?.label.toLowerCase().includes(inputValue.toLowerCase())
        : [],
    );
    setfilteredOptions(filteredOptionss);
  };

  //ADDED BY ZEROBUG =>> THIS FUNCTION IT'S A LISTENER THAT CAN HIDE THE DROPDOWN WHEN USER CLIKC ON THE BACKDROP !!!
  //BEGIN FUNCTION
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
        //    setfilteredOptions(options);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (options.length == 0) setInputValue(defaultValue ?? '');
    if (options.length == 1 && autoSelectFirst)
      setInputValue('' + options[0].label);
  }, [options]);

  //END FUNCTION
  return (
    <div ref={inputRef} className={`InputFieldDropDown ${target}_field`}>
      <div className="inp-select-field">
        {withIcon && inputIcon && (
          <img className="icon icon-flag" src={inputIcon} alt="" />
        )}
        {target == 'dropdown' && !isDisabled && (
          <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />
        )}

        {target == 'color' && (
          <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />
        )}
        {target == 'search' && (
          <img className="icon-search" src={SearchIconGreyInp} alt="" />
        )}

        <InputField
          value={inputValue}
          onChange={handleInputChange}
          name={name}
          type="text"
          placeholder={placeholder || languageData.fr.Choose_an_option}
          label={label}
          style="form"
          isDisabled={isDisabled}
          onClick={() => setShowDropdown((prevState) => !prevState)}
        />
      </div>

      {/* Dropdown */}
      {target === 'color' ? (
        <div className={`InputDropDownList ${showDropdown ? '' : 'hide'}`}>
          <ul className="fl_col gp10 pd10-t-b">
            {(filteredOptions ?? options).map((option, index) => (
              <OptionItem
                Codecolor={option.color}
                key={index}
                target={target}
                value={option?.label}
                icon={Codecolor}
                onClick={(value, icon) =>
                  handleOptionClick(value, option.value, icon)
                }
              />
            ))}
          </ul>
        </div>
      ) : (
        <div className={`InputDropDownList ${showDropdown ? '' : 'hide'}`}>
          {showDropdown && (
            <ul className="fl_col gp10 pd10-t-b">
              {(filteredOptions ?? options).map((option, index) => (
                <OptionItem
                  hideIcon={hideOptionItem}
                  key={index}
                  target={target}
                  value={option?.label}
                  icon={
                    isValidURL(option.icon ?? '')
                      ? option.icon
                      : SmallPlaceholderImage
                  }
                  onClick={(value, icon) =>
                    handleOptionClick(value, option.value, icon)
                  }
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectInputCatalogue;
