import React from 'react';
import TabItem from '../../../components/ProfileInfo/TabItem';
import HomeIconPrimary from '../../../assets/icons/HomeIconPrimary.svg';
import HomeIconGrey from '../../../assets/icons/HomeIconGrey.svg';
import ArrowCircleRight from '../../../assets/icons/ArrowCircleRightIconPrimary.svg';
import { languageData } from '../../../i18n';
interface TabSwitcherProps {
  setActiveMiniTab: React.Dispatch<React.SetStateAction<string>>;
  activeMiniTab: string;
  showFiche?: boolean;
  showInfoFinanciere?: boolean;
}
export default function TabSwitcher(props: TabSwitcherProps) {
  const onSwitchMiniTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    props.setActiveMiniTab(target.id);
    props.setActiveMiniTab(target.id === '' ? '1' : target.id);
  };
  const AddScroll = () => {
    const tab = document.querySelector(
      '.TabSwitcher .tab-wrapper .list-menu-tabs',
    ) as HTMLElement;
    if (tab) {
      tab.scrollBy(150, 0);
    }
  };
  const OpenMobileMenu = () => {
    document
      .querySelector('.TabSwitcher .tab-wrapper .list-menu-tabs')
      ?.classList.toggle('Active-menu');
  };

  return (
    <div className="TabSwitcher fl_row ai_c gp20">
      <div className="tab-wrapper fl-1">
        <div
          className="list-menu-tabs fl_row gp16 ai_c"
          onClick={OpenMobileMenu}
        >
          {props.showFiche && (
            <>
              <TabItem
                id="1"
                name={languageData.global.TabsTitle.Fiche}
                icon={
                  props.activeMiniTab === '1' ? HomeIconPrimary : HomeIconGrey
                }
                isActive={props.activeMiniTab === '1'}
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onSwitchMiniTab(e)
                }
              />
            </>
          )}
          {props.showInfoFinanciere && (
            <TabItem
              id="2"
              name={languageData.global.TabsTitle.Informationfinancière}
              isActive={props.activeMiniTab === '2'}
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                onSwitchMiniTab(e)
              }
            />
          )}

          {/* <TabItem id="3" name={languageData.global.TabsTitle.Responsable} /> */}
          <TabItem
            id="4"
            name={languageData.global.TabsTitle.Établissement}
            isActive={props.activeMiniTab === '4'}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              onSwitchMiniTab(e)
            }
          />

          {/* <TabItem
            id="5"
            name={languageData.global.TabsTitle.roles}
            isActive={props.activeMiniTab === '5'}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              onSwitchMiniTab(e)
            }
          />
          <TabItem
            id="6"
            name={languageData.global.TabsTitle.users}
            isActive={props.activeMiniTab === '6'}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              onSwitchMiniTab(e)
            }
          /> */}
          <TabItem
            id="7"
            name={languageData.global.TabsTitle.Catalogue}
            isActive={props.activeMiniTab === '7'}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              onSwitchMiniTab(e)
            }
          />
          <TabItem
            id="8"
            name={'Types de machine'}
            isActive={props.activeMiniTab === '8'}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              onSwitchMiniTab(e)
            }
          />
        </div>
      </div>
      <div className="slider-tab">
        <img
          loading="lazy"
          src={ArrowCircleRight}
          alt=""
          width={20}
          onClick={AddScroll}
        />
      </div>
    </div>
  );
}
