import React from 'react';
import { ReactNode } from 'react';
import HomeIconGrey from '../assets/icons/HomeIconGrey';

import EntrepriseIcon from '../assets/icons/EntrepriseIcon';
import PadLockIcon from '../assets/icons/PadLockIcon';
import ListeDesEntreprises from '../pages/etablissement/pages/ListeDesEntreprises';
import ProfileInfo from '../pages/etablissement/pages/EntrepriseProfileInfo';
import AddCreateCompany from '../container/Etablissement/TabsEtablissements/AddCreateCompany';
import EtablissementIcon from '../assets/icons/EtablissementIcon';
import EtablissementProfileInfo from '../pages/etablissement/pages/EtablissementProfileInfo';
import Catalogue from '../pages/catalogue/index';
// import RolePermission from '../container/Roles/RolePermissions';
import UsersListContainer from '../container/Users/UsersContainer';
import AddCompany from '../container/Etablissement/FormWizzard/AddCompany';
import HandleRoleContainer from '../container/Roles/HandleRoleContainer';
import AddUser from '../container/Users/AddUser';
import EditUser from '../container/Users/EditUser';
import RolePermissionsContainer from '../container/Etablissement/TabsEtablissements/RolePermissionsContainer';
import Carte from '../pages/catalogue/pages/Carte/Carte';
import AddCart from '../pages/catalogue/pages/Carte/AddCart';
import GlossaireIconPrimary from '../assets/icons/GlossaireIcon';
import ListeDesEtablissementContainer from '../container/Etablissement/TabsCompany/ListeDesEtablissementContainer';
import EditMyProfile from '../container/Users/EditMyProfile';

interface TabPayload {
  key: string;
  value: ReactNode;
}
interface Tab {
  name: string;
  icon: JSX.Element;
  isPinned: boolean;
  index: string;
  isActive?: boolean;
  isChanged?: boolean;
  tabContent: TabPayload;
}

const tabs_list: Tab[] = [
  {
    name: '',
    icon: <HomeIconGrey />,
    isPinned: true,
    isActive: false,
    index: '1',
    // tabContent: { key: 'TestTab2', value: null },
    tabContent: { key: 'TestTab2', value: null },
  },
  {
    name: 'Ajouter un établissement',
    icon: <EntrepriseIcon />,
    isPinned: false,
    index: '2',
    isChanged: false,
    isActive: true,
    tabContent: {
      key: 'Ajouter un établissement',
      value: <AddCreateCompany />,
    },
  },
  {
    name: 'Entreprise(s)',
    icon: <EntrepriseIcon />,
    isPinned: false,
    isActive: true,
    isChanged: false,
    index: '3',
    tabContent: {
      key: 'Entreprise(s)',
      value: <ListeDesEntreprises />,
    },
  },
  {
    name: 'Ajouter une entreprise',
    icon: <EntrepriseIcon />,
    isPinned: false,
    index: '4',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Ajouter une entreprise', value: <AddCompany /> },
  },
  {
    name: 'entreprise',
    icon: <EntrepriseIcon />,
    isPinned: false,
    index: '5',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'entreprise', value: <ProfileInfo /> },
  },
  {
    name: 'etablissement',
    icon: <EtablissementIcon />,
    isPinned: false,
    index: '6',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'etablissement', value: <EtablissementProfileInfo /> },
  },
  {
    name: 'Glossaire',
    icon: <GlossaireIconPrimary />,
    isPinned: false,
    index: '7',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'glossaire', value: <Catalogue /> },
  },
  {
    name: 'rolePermission',
    icon: <EtablissementIcon />,
    isPinned: false,
    index: '8',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'rolePermission', value: <RolePermissionsContainer /> },
  },
  {
    name: 'Roles(s)',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '9',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Roles(s)', value: <HandleRoleContainer /> },
  },
  {
    name: 'Utilisateur(s)',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '10',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Utilisateur(s)', value: <UsersListContainer /> },
  },
  {
    name: 'Ajout utilisateur',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '11',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Ajout utilisateur', value: <AddUser /> },
  },
  {
    name: 'Profile utilisateur',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '12',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Profile utilisateur', value: <EditUser /> },
  },
  {
    name: 'Carte',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '13',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Carte', value: <Carte /> },
  },
  {
    name: 'Modifier Carte',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '14',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Modifier Carte', value: <AddCart /> },
  },
  {
    name: 'Établissement(s)',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '15',
    isActive: true,
    isChanged: false,
    tabContent: {
      key: 'Établissement(s)',
      value: <ListeDesEtablissementContainer />,
    },
  },
  {
    name: 'Profile',
    icon: <PadLockIcon />,
    isPinned: false,
    index: '16',
    isActive: true,
    isChanged: false,
    tabContent: { key: 'Profile', value: <EditMyProfile /> },
  },
];

export default tabs_list;
