import toast from 'react-hot-toast';
import { languageData } from '../i18n';
export const NotificationErrorProvider = (error: any) => {
  const status = error?.response?.status;
  const message = error?.response?.data?.failureResponse?.codeFI?.message;

  switch (status) {
    case 400:
      switch (message) {
        case 'ERROR_ITEM_EXIST':
          toast.error(languageData.fr.errorCode.error400_ITEM_EXIST, {
            position: 'top-right',
          });
          break;
        case 'GENERAL_CODE_TVA_EXIST':
          toast.error(
            languageData.fr.errorCode.error400_GENERAL_CODE_TVA_EXIST,
            {
              position: 'top-right',
            },
          );
          break;
        case 'isReferenced':
          toast.error(languageData.fr.errorCode.error400_FAMILY_REFERENCED, {
            position: 'top-right',
          });
          break;
        case 'cannot_rename_folder':
          toast.error(languageData.fr.errorCode.error400_CANNOT_RENAME_FOLDER, {
            position: 'top-right',
          });
          break;
        case 'alreadyDeleted':
          toast.error(languageData.fr.errorCode.error400_ALREADY_DELETED, {
            position: 'top-right',
          });
          break;
        case 'boucle_infinit_detected':
          toast.error(languageData.fr.errorCode.error400_BOUCLE_INFIFI, {
            position: 'top-right',
          });
          break;
        case 'cannot_delete_folder':
          toast.error(languageData.fr.errorCode.error400_CNNOT_DELETE_FOLDER, {
            position: 'top-right',
          });
          break;
        case 'max_selection_ingredient_invalide':
          toast.error(
            languageData.fr.errorCode
              .error400_max_selection_ingredient_invalide,
            {
              position: 'top-right',
            },
          );
          break;
        default:
          toast.error(languageData.fr.errorCode.error400, {
            position: 'top-right',
          });
          break;
      }
      break;

    case 401:
      localStorage.removeItem('accessToken');
      toast.error(languageData.fr.errorCode.error401, {
        position: 'top-right',
      });
      window.location.replace('/');
      break;

    default:
      toast.error(languageData.fr.errorCode.error400, {
        position: 'top-right',
      });
      break;
  }
};

export const NotificationSuccessProvider = (
  successType: 'add' | 'edit' | 'delete',
  element: 'Sous famille'|'Etape'|'Famille' |'Article' | 'Menu' | 'Carte' | 'Badge'| 'Categorie'|'Poste de travail' | 'Tag' | 'Tva'| 'Allergène' | 'Dossier'| 'Sous dossier' | 'Image'| 'Points de ventes et machines'| 'Type de machine',
) => {
  switch (successType) {
    case 'add':
      toast.success(element+languageData.fr.successcode.successAdd, {
        position: 'top-right',
      });
      break;
    case 'edit':
      toast.success(element+languageData.fr.successcode.successEdit, {
        position: 'top-right',
      });
      break;
    case 'delete':
      toast.success(element+languageData.fr.successcode.successDelete, {
        position: 'top-right',
      });
      break;
    default:
      break;
  }
};
