import React from 'react';
import CardFileds from '../../container/CardFileds';
import PaymentMode from '../Catalogue/Etapes/PaymentMode';
import CheckBox from '../Catalogue/Checkbox';
import NumberField from '../Catalogue/NumberField';
import {
  ArticleChanges,
  Composition,
  purshaseMethod,
  screen,
} from '../../models/services/module.catalogue';
import { languageData } from '../../i18n';
/**
 * ModalAddEtape component description.
 *
 * @ModalAddEtape
 *
 */
interface ModalAddEtapeProps {
  screens?: screen[];
  onChangeFieldArticleLevel?: <K extends keyof ArticleChanges>(
    field: K,
    value: ArticleChanges[K],
  ) => void;
  articleChanges?: ArticleChanges;
  purshaseMethods?: purshaseMethod[];
  onClickPurshaseMethod?: (p: purshaseMethod) => void;
}
const ModalAddEtape: React.FC<ModalAddEtapeProps> = ({
  screens,
  onChangeFieldArticleLevel,
  articleChanges,
  purshaseMethods,
  onClickPurshaseMethod,
}) => {
  console.log('articlxxxx', articleChanges);
  return (
    <div className="ModalAddEtape ModalAddEtape fl_col fl-1">
      <div className="body-wrapper w-full">
        <CardFileds haslabel label="Modes de vente" className="w-full">
          <PaymentMode
            hasError
            activeMethods={articleChanges?.purshaseMethod}
            purshaseMethods={purshaseMethods}
            //   surplace={articleChanges?articleChanges?.purshaseMethod?.findIndex((_) => _.id)>-1:false}

            onClick={onClickPurshaseMethod}
          />
        </CardFileds>
        <CardFileds haslabel label="Modes de vente" className="w-full">
          <div className="fl_row gp20 NumberField-grid">
            <NumberField
              defaultValue={parseFloat('' + articleChanges?.priceOnSite) ?? 0}
              onChange={(e) =>
                onChangeFieldArticleLevel &&
                onChangeFieldArticleLevel('priceOnSite', e.target.value)
              }
              label={languageData.fr.addProduct.pricePlace}
              prefix="€"
            />
            <NumberField
              defaultValue={parseFloat('' + articleChanges?.priceEmporter) ?? 0}
              onChange={(e) =>
                onChangeFieldArticleLevel &&
                onChangeFieldArticleLevel('priceEmporter', e.target.value)
              }
              label={languageData.fr.addProduct.priceEmporter}
              prefix="€"
            />
            <NumberField
              defaultValue={parseFloat('' + articleChanges?.priceDelivery) ?? 0}
              onChange={(e) =>
                onChangeFieldArticleLevel &&
                onChangeFieldArticleLevel('priceDelivery', e.target.value)
              }
              label={languageData.fr.addProduct.priceDelivery}
              prefix="€"
            />
            <NumberField
              min={1}
              step="3"
              defaultValue={
                parseFloat('' + articleChanges?.maxNbrSelection) ?? 1
              }
              onChange={(e) =>
                onChangeFieldArticleLevel &&
                onChangeFieldArticleLevel('maxNbrSelection', e.target.value)
              }
              label={languageData.fr.sidebarIngredient.NbrMxSect}
            />
          </div>
        </CardFileds>
        <CardFileds haslabel label={languageData.fr.modalsCatalogues.screens}>
          <div className="fl_row gp20 jc_st fl_wrap screen_list w-full">
            {screens &&
              screens?.map((_, __) => (
                <CheckBox
                  isChecked={
                    articleChanges?.screens?.find(
                      (s) => (s.screenId ? s.screenId : s.id) == _.id,
                    ) != undefined
                  }
                  onChange={(e) =>
                    onChangeFieldArticleLevel &&
                    onChangeFieldArticleLevel(
                      'screens',
                      e.target.checked
                        ? [...(articleChanges?.screens || []), _]
                        : articleChanges?.screens?.filter(
                            (s) =>
                              (s.screenId ?? s.id) !== (_.screenId ?? _.id),
                          ),
                    )
                  }
                  label={_.designation}
                  boxStyle
                />
              ))}
            {screens?.length == 0 && (
              <p className="p14 w-400 DarkBlueGray-txt txt-nowrap ">
                {' '}
                {languageData.fr.modalsCatalogues.no_screens}{' '}
              </p>
            )}
          </div>
        </CardFileds>
      </div>
    </div>
  );
};
export default ModalAddEtape;
