import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddElementIcon from '../../../assets/icons/AddElementCircleWhiteIcon.svg';
import CatalogueIconPrimary from '../../../assets/icons/CatalogueIconPrimary.svg';
import GlossaireIconPrimary from '../../../assets/icons/GlossaireIconPrimary.svg';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import ButtonElement from '../../../components/button/button';
import tabs_list from '../../../data/tabsList';
import { languageData } from '../../../i18n';
import { cardSingle } from '../../../models/services/module.cart';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { OpenTab } from '../../../services/tabs/OpenTab';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import PageTable from '../../Table/PageTable';
import TableContent from '../../Table/TableContent';
import TableSection from '../../Table/TableSection';
import { updateCart } from '../../../store/actions/catalogues/actions';

interface CatalogueTabContentProps {
  companyId: number;
  companyName: string;
}
export default function CatalogueTabContent(props: CatalogueTabContentProps) {
  const [carts, setcarts] = useState<cardSingle[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const cartAdded = useSelector(
    (state: { uiCatalogue: { cartAdded: boolean } }) =>
      state.uiCatalogue?.cartAdded,
  );
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const onClickGlossaire = () => {
    // HandelMenuToggleBtn();
    OpenTab({
      key: props.companyName,
      label: tabs_list[6].name,
      tabs: open_tabs,
      dispatch,
      id: 5,
      companyName: props.companyName,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };
  const openCarte = () => {
    OpenTab({
      key: props.companyName,
      label: tabs_list[12].name,
      tabs: open_tabs,
      dispatch,
      id: 11,
      companyName: props.companyName,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };
  const fetchCarts = async (companyId?: string) => {
    try {
      let cards = await catalogueApi.getAllCarts(companyId as string);
      setcarts((cards.data?.data?.result ?? []) as cardSingle[]);
    } catch (error) {
      setcarts([]);
    }
  };
  useEffect(() => {
    if (cartAdded) {
      fetchCarts(activeTabContent.companyId);
      dispatch(updateCart(false));
    }
  }, [cartAdded]);
  useEffect(() => {
    fetchCarts(activeTabContent.companyId);
  }, []);

  const onEdit = (id?: string, designation?: string) => {
    navigate(`/carte/add`, { state: { card: { id, designation } } });
    OpenTab({
      key: designation,
      label: tabs_list[13].name,
      tabs: open_tabs,
      dispatch,
      id: 12,
      companyName: designation,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };
  return (
    <PageTable className="CatalogueTabContent">
      <TableSection>
        <div className="tab-header fl_row w-full jc_s fl_wrap gp20 hide">
          <div className="table-info fl_col gp10">
            <div className="fl_row gp40">
              <p className="p18 w-900 DarkJungleGreen-txt">
                {languageData.global.catalogue.cartes}
              </p>
            </div>
          </div>
          <div className="fl_row gp8 ai_c fl_wrap jc_fe">
            {!activeTabContent?.isEtablishement && (
              <ButtonElement
                reverse
                buttonStyle="btn-white-primary"
                type="IconButton"
                buttonSize="large"
                text={languageData.global.catalogue.glossaire}
                icon={GlossaireIconPrimary}
                onClick={onClickGlossaire}
              />
            )}
            <ButtonElement
              reverse
              buttonStyle="btn-white-primary"
              type="IconButton"
              buttonSize="large"
              text={languageData.global.catalogue.stockStatus}
              icon={CatalogueIconPrimary}
            />

            {!activeTabContent?.isEtablishement && (
              <ButtonElement
                onClick={openCarte}
                reverse
                buttonStyle="btn-primary"
                type="IconButton"
                buttonSize="large"
                text={languageData.global.catalogue.CreateCart}
                icon={AddElementIcon}
              />
            )}
          </div>
        </div>
        <TableHeader
          isEtablishement={activeTabContent?.isEtablishement}
          type="catalogue"
          title={languageData.global.catalogue.cartes}
          textAddMobile="Nouveau"
          onClickGlossaire={onClickGlossaire}
          onClickOpenCart={openCarte}
        />
        <TableContent>
          <DesktopTableContainer>
            <Table className="Table TableCatalogues rd12">
              <Thead>
                <Tr>
                  <Td type="text" value={'Désignation'} width={97} />
                  <Td type="empty" width={3} />
                </Tr>
              </Thead>
              <Tbody>
                {carts &&
                  carts.map(
                    (_) =>
                      _.designation !== 'Test ZIED' && (
                        <Tr>
                          <Td type="text" value={_.designation} width={97} />
                          {/* <Td type="edit-catalogue" width={3} /> */}
                          <Td type="edit-catalogue" onClick={() => alert('')} />
                          <Td
                            width={3}
                            type="nav"
                            onClick={() => onEdit(_.id + '', _.designation)}
                          />
                        </Tr>
                      ),
                  )}
              </Tbody>
            </Table>
          </DesktopTableContainer>
        </TableContent>
      </TableSection>
    </PageTable>
  );
}
