import { ChangeEvent } from 'react';
import AddProductIconBlue from '../../../assets/icons/AddProductIconBlue.svg';
import AddProductIcon from '../../../assets/icons/AddProductIconLightGrey.svg';
import SearchIconGreyInp from '../../../assets/icons/SearchIconGreyInp.svg';
import Breadcrumbs from '../../../container/Breadcrumbs';
import { languageData } from '../../../i18n';
import { ArticleAllergen } from '../../../models/services/module.catalogue';
import InputField from '../../Input/InputCatalogue';
import AddProduct from '../Articles/AddProduct';
interface propstype {
  allerges: ArticleAllergen[];
  onDrag: (Article: ArticleAllergen) => void;
  onSelect: (article: ArticleAllergen, checked: boolean, all?: boolean) => void;
  topAddEnabled?: boolean;
  onDragSelected: () => void;
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  allChecked: boolean;
  onNewClick?: () => void;
}
export default function AllergenesNav({
  allerges,
  onDrag,
  onSelect,
  topAddEnabled,
  onDragSelected,
  onSearch,
  allChecked,
  onNewClick,
}: propstype) {
  return (
    <div className="AllergenesNav">
      <Breadcrumbs mainpage={'Glossaire'} subpage="Article" />
      <div className="nav-wrapper">
        <div className="fl_col gp10 pd10-r-l">
          <div className="search-inp">
            <img className="icon-search" src={SearchIconGreyInp} alt="" />
            <InputField
              onChange={onSearch}
              type="text"
              style="search"
              placeholder="Rercherche..."
            />
          </div>
          <div className="add-product-btn" onClick={onNewClick}>
            <span>+</span> {languageData.fr.sidebarAlgrs.new}
          </div>
        </div>
        <div className="products-list pd20-t-b pd8-r-l">
          <div className="products-wrapper">
            <div className="header-products fl_row jc_s pd10-t-b">
              <div className="fl_row ai_c gp8 ">
                <input
                  autoComplete="off"
                  checked={topAddEnabled && allChecked}
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) => {
                    onSelect({} as ArticleAllergen, e.target.checked, true);
                  }}
                />
                <p className="p12 OxfordBlue02-txt">Sélectionner tous</p>
              </div>
              <div
                className="Select-all fl_row gp8 c-p"
                onClick={onDragSelected}
              >
                <p
                  className={`p12   ${!topAddEnabled ? 'LightSilver-txt' : ''}`}
                >
                  Ajouter
                </p>
                <img
                  src={topAddEnabled ? AddProductIconBlue : AddProductIcon}
                  alt=""
                />
              </div>
            </div>
            <div className="listing-products fl_col gp12">
              {allerges?.map(
                (alg) =>
                  !alg?.disabled && (
                    <AddProduct
                      selected={alg.selected}
                      onChange={(e) => onSelect(alg, e.target.checked)}
                      onDrag={() => onDrag(alg)}
                      name={alg.name}
                      img={alg.url}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
