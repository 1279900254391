import { useMemo } from 'react';

export const usePasswordValidation = (password: string) => {
  const isLongEnough = useMemo(() => password.length >= 12, [password]);
  const hasDigit = useMemo(() => /\d/.test(password), [password]);
  const hasLowercase = useMemo(() => /[a-z]/.test(password), [password]);
  const hasUppercase = useMemo(() => /[A-Z]/.test(password), [password]);
  const hasSpecialCharacter = useMemo(
    () => /[!@#$%^&*(),.?":{}|<>]/.test(password),
    [password],
  );

  const level = useMemo(() => {
    if (
      isLongEnough &&
      hasDigit &&
      hasLowercase &&
      hasUppercase &&
      hasSpecialCharacter
    ) {
      return 'strong';
    }
    return 'low';
  }, [isLongEnough, hasDigit, hasLowercase, hasUppercase, hasSpecialCharacter]);

  return {
    isLongEnough,
    hasDigit,
    hasLowercase,
    hasUppercase,
    hasSpecialCharacter,
    level,
  };
};
