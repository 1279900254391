import React, { useEffect, useRef, useState } from 'react';
import ClearFilterIconGrey from '../../assets/icons/ClearFilterIconGrey.svg';
import DropwDownArrowBlack from '../../assets/icons/DropwDownArrowBlack.svg';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
interface MultiSelectProps {
  list?: string[];
  companies_list: serviceEntrepriseModels.companyListItem[];
  onChange: (selected: any) => void;
  name?: string;
  setCombineSearch: any;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  list,
  companies_list,
  onChange,
  name,
  setCombineSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  console.log('selected', selected);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleDocClick = (e: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };
  const clearSelection = () => {
    setSelected([]);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleDocClick, false);
    return () => {
      document.removeEventListener('mousedown', handleDocClick, false);
    };
  }, []);

  const toggleChangeListItem = (item: any) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((selectedItem) => selectedItem !== item)
        : [...prevSelected, item],
    );
  };
  useEffect(() => {
    if (selected.length == 0) {
      setCombineSearch((prev: any) => ({ ...prev, companyId: selected }));
    }
  }, [selected]);
  const renderSelected = () => {
    if (!selected.length) return 'Aucun sélectionné';
    if (selected.length === companies_list?.length) return 'Tous sélectionnés';
    if (selected.length === 1) return selected[0];
    return `${selected.length} Sélectionnée(s)`;
  };

  const renderDropDownIcon = () => (
    <img className="drop-down-icon" src={DropwDownArrowBlack} alt="" />
  );
  const ClearFilterIcon = () => (
    <img
      className="clear-filter"
      src={ClearFilterIconGrey}
      alt=""
      onClick={clearSelection}
    />
  );

  return (
    <div className="MultiSelect multi-select" ref={wrapperRef}>
      <button
        className={`multi-select__button fl_row jc_s ai_c ${isOpen ? 'is-open' : ''}`}
        onClick={toggleIsOpen}
      >
        <div className="SelectedText">
          <p className="p14 w-500">{renderSelected()}</p>
        </div>
        <div className="fl_row ai_c gp10">
          {ClearFilterIcon()}
          {renderDropDownIcon()}
        </div>
      </button>
      {isOpen && (
        <div className="multi-select__list-wrapper">
          {companies_list.map((item, index) => {
            const isChecked = selected.includes(item?.commercial_Name);
            return (
              <div
                key={item?.id}
                tabIndex={0}
                className={`multi-select__list-item ${isChecked ? 'checked-item' : ''}`}
                onClick={() => toggleChangeListItem(item?.commercial_Name)}
                onKeyUp={(e) =>
                  e.key === 'Enter' &&
                  toggleChangeListItem(item?.commercial_Name)
                }
              >
                <input
                  autoComplete="off"
                  tabIndex={-1}
                  id={item?.commercial_Name}
                  type="checkbox"
                  checked={isChecked}
                  value={item?.id}
                  readOnly
                  onChange={onChange}
                  name={name}
                />
                <label htmlFor={item?.commercial_Name}>
                  {item?.commercial_Name}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
