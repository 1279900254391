import { serviceAppUserModels } from '../models/services/module.appUser';
import { v4 as uuidv4 } from 'uuid';
export const transformUserDetailsToPermissions = (
  userDetails: serviceAppUserModels.AppUserDetails,
): serviceAppUserModels.Permission[] => {
  return userDetails.roles.map(
    (role: serviceAppUserModels.userDetailsRoles) => {
      const chainesIds: number[] = [];
      const restaurantsIds: number[] = [];
      const chainesIdsToDelete: number[] = [];
      const restaurantsIdsToDelete: number[] = [];
      role.access.forEach((access: serviceAppUserModels.userAccess) => {
        chainesIds.push(access.channelId);
        restaurantsIds.push(
          ...access.pdvList.map((pdv: serviceAppUserModels.pdvList) => pdv.id),
        );
      });

      return {
        rowId: uuidv4(),
        rolesId: role.roleIds,
        isActive: userDetails.isActive ?? false,
        chainesIds,
        restaurantsIds,
        chainesIdsToDelete,
        restaurantsIdsToDelete,
      };
    },
  );
};
