import { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import { convertDate } from '../../../utils/ConvertTDate';
import Modal from '../../../components/Modal/Modal';
import ValidatePayModalContent from '../../../components/Etablissement/Modal/ValidatePayModalContent';
import PaymentInvoice from '../../../components/CreditCard/PaymentInvoice';
import InvoiceContainer from '../Invoice/InvoiceContainer';
import MobileTableContainer from '../../../components/Table/MobileTableContainer';
import CalendarSendDarkIcon from '../../../assets/icons/CalendarSendDarkIcon.svg';
import CheckedCalendarGreenIcon from '../../../assets/icons/CheckedCalendarGreenIcon.svg';
import PageTable from '../../Table/PageTable';
import TableContent from '../../Table/TableContent';
import TableSection from '../../Table/TableSection';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import { getListFacutreRequest } from '../../../store/actions/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import Spinner from '../../../components/skeleton/spinner';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';
import DescktopTableBilling from './DescktopTableBilling';
import MobileTableBilling from './MobileTableBilling';
import TableHeaderBilling from './TableHeaderBilling';

type ExpandedCardState = {
  [key: string]: boolean;
};
export default function BillsListContainer() {
  const [showInvoice, setShowInvoice] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showValidatePayement, setShowValidatePayement] = useState(false);
  const [expandedCard, setExpandedCard] = useState<ExpandedCardState>({});
  const toggleExpand = (userId: string) => {
    setExpandedCard({ [userId]: !expandedCard[userId] });
  };
  const [facture, setFacture] =
    useState<serviceEtablissmentMachineTypeModels.ListFacutre>();
  const dispatch = useDispatch();

  const { ListFacture, loading, error } = useSelector(
    (state: {
      facture: {
        ListFacture: serviceEtablissmentMachineTypeModels.ListFacutre[];
        loading: boolean;
        error: string;
      };
    }) => state.facture,
  );

  const etablissementSheet = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissementSheet,
  );

  useEffect(() => {
    if (etablissementSheet?.id) {
      dispatch(getListFacutreRequest(etablissementSheet?.id as number));
    }
  }, [dispatch]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="BillsListContainer fl_col ai_e">
      {showInvoice && (
        <InvoiceContainer
          onClick={() => setShowInvoice(false)}
          factureId={facture?.id}
        />
      )}
      <Modal
        className="AddSubscriptionsModal"
        version="custom"
        title={'Réglement'}
        visibility={showValidatePayement}
        close
        maxWidth={640}
        content={
          <ValidatePayModalContent
            total={847.5}
            prefix="€"
            invoiceNumber={246012}
            onClickPay={() => setShowPayment(true)}
          />
        }
        onClickClose={() => setShowValidatePayement(false)}
      />

      <Modal
        className="AddSubscriptionsModal"
        version="custom"
        title={'Réglement'}
        visibility={showPayment}
        close
        maxWidth={640}
        content={
          <PaymentInvoice
            title="//////////////RETOUR API-back//////////////"
            transactionNumber={'735066F6-0009'}
            paymentDate="10 Juin 2024"
            cardNumber="4242 4242 4242 4242"
            onClickDownload={() => setShowInvoice(true)}
            onClickClose={() => setShowPayment(false)}
          />
        }
        onClickClose={() => setShowPayment(false)}
      />
      <PageTable className="BillsListContainer">
        <TableSection>
          <TableContent>
            <DesktopTableContainer className="BillsList">
              <Table>
                <TableHeaderBilling />
                <Tbody>
                  {ListFacture?.map(
                    (
                      item: serviceEtablissmentMachineTypeModels.ListFacutre,
                    ) => {
                      return (
                        <DescktopTableBilling
                          item={item}
                          id={item?.id}
                          reference={item?.reference}
                          totalTTC={item?.totalTTC}
                          iDate={item?.iDate}
                          convertDate={convertDate}
                          paymentDate={item?.paymentDate}
                          isPaid={item?.isPaid}
                          setShowValidatePayement={setShowValidatePayement}
                          setFacture={setFacture}
                          setShowInvoice={setShowInvoice}
                          isInProcessing={false}
                        />
                      );
                    },
                  )}
                </Tbody>
              </Table>
            </DesktopTableContainer>
            <MobileTableContainer className="BillsList-table-mobile">
              <MobileTableContainer>
                {ListFacture?.map(
                  (item: serviceEtablissmentMachineTypeModels.ListFacutre) => {
                    return (
                      <MobileTableBilling
                        item={item}
                        id={item?.id}
                        reference={item?.reference}
                        setShowInvoice={setShowInvoice}
                        CalendarSendDarkIcon={CalendarSendDarkIcon}
                        iDate={item?.iDate}
                        convertDate={convertDate}
                        CheckedCalendarGreenIcon={CheckedCalendarGreenIcon}
                        paymentDate={item?.paymentDate}
                        totalTTC={item?.totalTTC}
                        isPaid={item?.isPaid}
                        setFacture={setFacture}
                      />
                    );
                  },
                )}
              </MobileTableContainer>
            </MobileTableContainer>
          </TableContent>
        </TableSection>
      </PageTable>
    </div>
  );
}
