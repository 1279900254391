import React, { ChangeEvent, useState } from 'react';
import InputMask from 'react-input-mask';
import { languageData } from '../../i18n';

/**
 * ModalElementTva component description.
 *
 * @ModalElementTva
 *
 */

interface ModalElementTvaProps {
  itemTvaLabel?: string;
  itemTva?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;

}

const ModalElementTva: React.FC<ModalElementTvaProps> = ({
  itemTvaLabel,
  itemTva,
  onChange,

}) => {
  return (
    <div className="ItemTva fl_col fl-1">
      <label className="p12 w-500 DarkBlueGray-txt" htmlFor="ItemTva">
        {itemTvaLabel} *
      </label>
      <div className="field-wrapper fl_row ai_c">
        <InputMask
          title={languageData.fr.Modal.tva_tooltip}
          mask="99.99"
          id="ItemTva"
          //  type="number"
          //step="0.01"
          defaultValue={itemTva}
          onChange={onChange}
        />
        <p className="p14 Nevada-txt w-500">%</p>
      </div>
    </div>
  );
};

export default ModalElementTva;
