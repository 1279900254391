import { useDispatch } from 'react-redux';
import AddProductIconBlue from '../../assets/icons/AddProductIconBlue.svg';
import ChevronDouble from '../../assets/icons/Chevron-left-double.svg';
import CartNavItem from '../../components/Carts/CartNavItem';
import {
  carteConfig,
  modalData,
  resConfig,
} from '../../models/services/module.cart';
import { CategorySingle } from '../../models/services/module.category';
export default function SideBarCart({
  categories,
  showLeftSide,
  onHandleSider,
  onDrag,
  card,
  setcategoriesCart,
}: {
  categories: CategorySingle[];
  showLeftSide: boolean;
  onHandleSider: (x: boolean) => void;
  onDrag: (config: modalData, _: (res: resConfig[]) => void) => void;
  card: carteConfig;
  setcategoriesCart?: (_?: CategorySingle[]) => void;
}) {
  const dispatch = useDispatch();
  const HandleleftSide = () => {
    onHandleSider(!showLeftSide);
  };

  const onDragCategory = (ids: string[]) => {
    onDrag(
      {
        actionTarget: 'DRAG',
        categoryIds: ids,
        cardId: card.id + '',
      },
      (_) => {
        console.log('response drag', _);
        let cartegoriesCartArray: CategorySingle[] = [];
        ids?.map((id) => {
          let Category = categories.find((cat) => '' + cat.id == id);
          cartegoriesCartArray.push({
            ...Category,
            categoryCartId: _.find((c) => c.idCategory == Category?.id)?.id,
          } as CategorySingle);
        });
        setcategoriesCart && setcategoriesCart(cartegoriesCartArray);
        //
      },
    );
  };

  const onDragAll = () => {
    onHandleSider(false);

    onDragCategory(categories?.map((_) => '' + _.id));
    //dispatch(selectCategoryCarts(categories));
  };
  return (
    <div className="left-side pd20-t-b fl_col gp16">
      <div className="side-remote fl_row ai_c jc_s">
        <div className="fl_col gp5 txt_center" onClick={HandleleftSide}>
          <p className="p14 w-600 Independence-txt">Liste des catégories</p>
          <p className="p10 Nevada-txt">
            Appuyer sur le flèche pour ajouter une catégorie.
          </p>
        </div>
        <img src={ChevronDouble} alt=""  />
      </div>
      <div className="header-side fl_row jc_fe">
        <div className="fl_row gp12 ai_c c-p" onClick={onDragAll}>
          <p className="p12 w-600 primary-txt">Ajouter tous</p>
          <img src={AddProductIconBlue} alt="" />
        </div>
      </div>
      <div className="listing-cartes fl_col gp10">
        {categories?.map(
          (_, __) =>
            !_.disabled && (
              <CartNavItem
                OnClickAdd={() => onDragCategory(['' + _.id])}
                name={_.designation}
                img={_.imageUrl}
              />
            ),
        )}
      </div>
    </div>
  );
}
