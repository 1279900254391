import React, { useEffect } from 'react';
import InvoiceHeader from '../../../components/Invoice/InvoiceHeader';
import InvoiceBody from '../../../components/Invoice/InvoiceBody';
import SubscriptionInvoice from '../../../components/Invoice/SubscriptionInvoice';
import InvoiceFooter from '../../../components/Invoice/InvoiceFooter';
import BtnDownloadPdf from '../../../assets/icons/BtnDownloadPdf.svg';
import CloseIconWhite from '../../../assets/icons/CloseIconWhite.svg';
import { convertToPDF } from '../../../utils/ConvertPdf';
import { getFactureByIdRequest } from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/skeleton/spinner';
import { convertDate } from '../../../utils/ConvertTDate';
interface InvoiceContainerProps {
  onClick?: () => void;
  factureId?: number;
}

const InvoiceContainer: React.FC<InvoiceContainerProps> = ({
  onClick,
  factureId,
}) => {
  const { factureDetails, loading, error } = useSelector(
    (state: {
      factureDetails: {
        factureDetails: serviceEtablissmentMachineTypeModels.ListFacutreDetails;
        loading: boolean;
        error: string;
      };
    }) => state.factureDetails,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (factureId) {
      dispatch(getFactureByIdRequest(factureId));
    }
  }, [dispatch, factureId]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div id="InvoiceContainer">
      <div onClick={onClick} className="InvoiceOverlay"></div>
      <img
        onClick={onClick}
        className="close-btn"
        src={CloseIconWhite}
        alt=""
      />
      <div id="InvoiceData" className=" InvoiceContainer fl_col jc_s">
        <div className="fl_col gp40">
          <InvoiceHeader
            InvoiceNumber={factureDetails?.reference}
            emissionDate={convertDate(factureDetails?.dateEmission)}
            withdrawalDate={convertDate(factureDetails?.datePrelevement)}
            transmitterName={'FRENCHINNOV'}
            periodOfUseStart="05 Sep. 2024"
            periodOfUseEnd="04 Oct. 2024"
            transmitterAdresse={`${factureDetails?.establihementAddress} ${factureDetails?.establihementPostalCode} ${factureDetails?.establihementCountryAddress}`}
            addressedToAdresse={`${factureDetails?.establihementAddress} ${factureDetails?.establihementPostalCode} ${factureDetails?.establihementCountryAddress}`}
            transmitterPhone={'0184207745'}
            addressedToName={factureDetails?.establihementCommercialName}
            addressedToTvaNumber={factureDetails?.establihementVatNumber}
          />
          {factureDetails?.abonnements?.map(
            (item: serviceEtablissmentMachineTypeModels.AbonnementDeatails) => {
              return (
                <InvoiceBody
                  totalHt={item?.totalHT}
                  // taxPercentage={190}
                  tax={factureDetails?.totalMontantVAT}
                  totalTtc={factureDetails?.totalTTC}
                  title={item?.designation}
                  tva={item?.vat}
                  ht={factureDetails?.totalHT}
                >
                  <SubscriptionInvoice
                    title={item?.designation}
                    tva={item?.vat}
                    ht={item?.totalHT}
                  >
                    <p className="p10">{item?.description}</p>
                  </SubscriptionInvoice>
                </InvoiceBody>
              );
            },
          )}
        </div>
        <InvoiceFooter
          paymentBy={'prélèvement SEPA'}
          iban={'FR76 3000 3040 0000 0205 7986 427'}
        />
        <div className="download-btn">
          {/* <button >CONVERT!</button> */}
          <img width={30} onClick={convertToPDF} src={BtnDownloadPdf} alt="" />
        </div>
      </div>
    </div>
  );
};
export default InvoiceContainer;
