import React, { useState } from 'react';
import CardContainer from '../../CardContainer';
import ButtonElement from './../../../components/button/button';
import GridItems from '../../GridList/GridList';
import InputField from '../../../components/Input/Input';
import SelectInput from '../../../components/Input/SelectInput';
// import FrFlagCircle from '../../../assets/icons/FrFlagCircle.svg';
import { languageData } from '../../../i18n';

interface NaFCodes {
  id: number;
  reference: string;
  designation: string;
}
interface Status {
  id: number;
  isActive: boolean;
  name: string;
}
interface EtablissementSheet {
  address: string;
  city: string;
  commercial_Name: string;
  country: string;
  email: string;
  fixed_line_telephone_number: string;
  id: number;
  logo: any;
  mobile_telephone_number: string;
  naF_codes: NaFCodes;
  postal_code: string;
  share_capital: string;
  sireT_Code: string;
  vaT_Intracommunity: string;
}

interface EtablissementInfos {
  address: string;
  city: string;
  commercial_Name: string;
  country: string;
  fixed_line_telephone_number: string;
  logo: any;
  mobile_telephone_number: string;
  postal_code: string;
  sireT_Code: string;
  status: Status;
}

interface FicheTabEtablissementsProps {
  etablissementSheet: EtablissementSheet;
  etablissementInfos: EtablissementInfos;
  setFormDateState: any;
  formDateState: any;
  claimEtablissementModification: boolean;
}

export default function FicheTabEtablissements(
  props: FicheTabEtablissementsProps,
) {


  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDateState((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,

        email: value,
      },
    }));
  };
  const handleChangePhoneFix = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDateState((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,

        fixed_line_telephone_numbe: value,
      },
    }));
  };
  const handleChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDateState((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,

        mobile_telephone_number: value,
      },
    }));
  };

  return (
    <div className="TabContent">
      <div className="FicheTab FicheTabEtablissements fl_col gp16">
        {/* <CardContainer type="standard" ClassName="fl_row jc_fe">
        <ButtonElement
          buttonStyle="btn-primary"
          type={'TextButton'}
          text={'Sauvgarder'}
          buttonSize={'large'}
        />
      </CardContainer> */}
        <div className="Section-data fl_col gp10">
          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.generalInformation}
          >
            <GridItems grid={2}>
              
              <InputField
                isRequired={true}
                value={props.etablissementInfos?.commercial_Name}
                type="text"
                name="Nomcommercial"
                label={languageData.global.Form.commercialNameLabel}
                placeholder={props.etablissementInfos?.commercial_Name}
                onChange={() => alert('x')}
                style="form"
                isDisabled={true}
              />

              <InputField
                isRequired={true}
                value={props.etablissementSheet?.share_capital}
                type="text"
                name="Capitale sociale"
                label={languageData.global.Form.shareCapitalLabel}
                placeholder={props.etablissementSheet?.share_capital}
                onChange={() => alert('x')}
                style="form"
                isDisabled={true}
              />

              <InputField
                isRequired={true}
                value={props.formDateState?.payload?.email}
                type="email"
                name="Adresse courriel"
                label={languageData.global.Form.emailLabel}
                placeholder={props.etablissementSheet?.email}
                onChange={(e) => handleChangeEmail(e)}
                style="form"
                isDisabled={props.claimEtablissementModification ? false : true}
              />

              <SelectInput
                label="Fixe (Facultatif)"
                placeholder={
                  props.etablissementInfos?.fixed_line_telephone_number
                }
                target="reflags"
                isDisabled={props.claimEtablissementModification ? false : true}
                onChange={(e) => handleChangePhoneFix(e)}
                icons={[]}
                reference={[
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                ]}
                options={[
                  'Option01',
                  'Option02',
                  'Option03',
                  'Option0001',
                  'OptionGG',
                  'OptiDGDGGon03',
                  'Option01',
                  'OptioEn02',
                  'OptioEEEGn03',
                ]}
                //isRequired
              />

              <SelectInput
                label="Mobile"
                placeholder={props.etablissementInfos?.mobile_telephone_number}
                target="reflags"
                isDisabled={props.claimEtablissementModification ? false : true}
                onChange={(e) => handleChangeMobile(e)}
                icons={[]}
                reference={[
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                ]}
                options={[
                  'Option01',
                  'Option02',
                  'Option03',
                  'Option0001',
                  'OptionGG',
                  'OptiDGDGGon03',
                  'Option01',
                  'OptioEn02',
                  'OptioEEEGn03',
                ]}
                isRequired
              />
              {/* <InputField
                isRequired={false}
                name="Adresse courriel"
                label="Photo"
                placeholder="Asdr@gmail.com"
                
                onChange={() => alert('x')}
                style="upload"
                isDisabled={props.claimEtablissementModification ? false : true}
                uploadText="Importer un fichier jpg ou png (max. 5 Mo)"
              /> */}
            </GridItems>
          </CardContainer>

          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.Adresse_physique}
          >
            <GridItems grid={2}>
              <InputField
                isRequired={true}
                value={props.etablissementSheet?.address}
                type="text"
                name="Adresse"
                label={languageData.global.adresse}
                placeholder={props.etablissementSheet?.address}
                style="form"
                isDisabled={true}
              />
              <InputField
                isRequired={true}
                value={props.etablissementInfos?.city}
                name="city"
                label={languageData.global.state}
                type="text"
                placeholder={props.etablissementInfos?.city}
                style="form"
                // icons={flags}
                isDisabled={true}
                // onChange={(e: ChangeEvent<HTMLInputElement>) =>
                //   handleChangeCity(e)
                // }
                // haserror={
                //   props.validationRequested
                //     ? getError({
                //         category: 'generalInformation',
                //         fieldName: 'city',
                //         isRequired: true,
                //         fieldValue: props.formData.generalInformation?.city,
                //       })
                //     : false
                // }
                // errorMsg={
                //   props.validationRequested
                //     ? getErrorMessage({
                //         category: 'generalInformation',
                //         fieldName: 'city',
                //         isRequired: true,
                //         fieldValue: props.formData.generalInformation?.city,
                //       })
                //     : ''
                // }
              />

              <InputField
                isRequired={true}
                value={props.etablissementSheet?.postal_code}
                type="number"
                name="Code postal"
                label={languageData.global.Form.postalCodeLabel}
                placeholder={props.etablissementSheet?.postal_code}
                style="form"
                isDisabled={true}
              />
            </GridItems>
          </CardContainer>

          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.fiscal_info}
          >
            <GridItems grid={3}>
              <InputField
                isRequired={true}
                type="number"
                name="Siren"
                label={languageData.global.siret}
                placeholder={props.etablissementSheet?.sireT_Code}
                style="form"
                isDisabled={true}
              />
              <InputField
                isRequired={true}
                type="text"
                name="TVA intracommunautaire"
                label={languageData.global.vat_intra}
                placeholder={props.etablissementSheet?.vaT_Intracommunity}
                style="form"
                isDisabled={true}
              />
              <InputField
                isRequired={true}
                type="text"
                name="Numéro NAF"
                label={languageData.global.naf_number}
                placeholder={props.etablissementSheet?.naF_codes?.designation}
                style="form"
                isDisabled={true}
              />
            </GridItems>
          </CardContainer>
        </div>
      </div>
    </div>
  );
}
