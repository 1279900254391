import React from 'react';
import ModalElementName from '../ModalElementName';

import { ModalProps } from '../../../models/services/module.modals';
export default function MachineModalController(props: ModalProps) {
  let modalContentBody;
  let modalContentFooter;
  switch (props.familytype) {
    case 'add':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full jc_c">
          <ModalElementName
            hideDefault
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_fe">
          <div className="fl_row gp12 w-full">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                onClick={props.handleSave}
                className={`btn_cat fl-1 ${!props.itemName ? 'btn-disabledGrey' : props.itemName && props.itemName?.trim()?.length < 3 ? 'btn-lightGrey' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;

    default:
      modalContentBody = null;
      modalContentFooter = null;
  }
  return { modalContentBody, modalContentFooter };
}
