import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceEntrepriseModels.EntrepriseState = {
  entreprise: null,
  entreprise_general_info: null,
  entreprises: [],
  listCompaniesEstablishments: [],
  addError: undefined,
  addSuccess: undefined,
  totalPagesNumbers: 0,
  editCompanyFormData: {
    id: 0,
    email: '',
    fixed_line_telephone_number: '',
    mobile_telephone_number: '',
    logo: '',
  },
  companySheet: {
    address: '',
    city: '',
    commercial_Name: '',
    company_name: '',
    construction_date: '',
    country: '',
    email: '',
    fixed_line_telephone_number: '',
    id: 0,
    legal_form: '',
    logo: '',
    mobile_telephone_number: '',
    naF_codes: 0,
    postal_code: '',
    share_capital: '',
    sireN_code: '',
    vaT_Intracommunity: '',
  },
  companyInfos: {},
  financialData: {
    id: 0,
    card_number: 0,
    iban: '',
    owner: '',
    phone_number: '',
    swifT_BIC: '',
    royalties: [],
  },
  editResponse: undefined,
  companyId: 0,
  addCompanyFormData: null,
  addCompanyStep: 1,
  validationRequested: false,
  validationRequestedFiscal: false,
  validationRequestedStep2: false,
  stepsData: [],
  validationRequestedEditFicheTab: false,
  dirtyEditForms: false,
  editCompanyFormDataFinancial: {
    id: 0,
    owner: '',
    owner_mobile_number: '',
    iban: '',
    swifT_BIC: '',
    card_number: 0,
    royalties: [],
  },
  validationRequestedEditFinancialTab: false,
};
type Action =
  | serviceEntrepriseModels.entreprisePayload
  | serviceEntrepriseModels.entrepriseId
  | servicePaginationModels.PaginationModelType;

function reducer(
  state = initialState,
  action: {
    type: string;
    payload: Action;
    entreprises: serviceEntrepriseModels.setAllEntrepriseAction;
  },
) {
  switch (action.type) {
    case actionTypes.ADD_ENTREPRISE: {
      return { ...state, entreprise: action.payload };
    }
    case actionTypes.ADD_ENTREPRISE_SUCCESS: {
      return { ...state, addSuccess: true, companyId: action.payload };
    }
    case actionTypes.ADD_ENTREPRISE_FAILURE: {
      return { ...state, addSuccess: false };
    }
    case actionTypes.ADD_ENTREPRISE_GENERAL_INFO: {
      return { ...state, entreprise_general_info: action.payload };
    }
    case actionTypes.REMOVE_ENTREPRISE: {
      return {
        ...state,
        entrepriseId: action.payload,
      };
    }
    case actionTypes.EDIT_ENTREPRISE_GENERAL_INFO:
      return {
        ...state,
        entreprise: action.payload,
      };
    case actionTypes.EDIT_ENTREPRISE_FINANCIAL_INFO:
      return {
        ...state,
        entreprise: action.payload,
      };
    case actionTypes.GET_ENTREPRISE: {
      return { ...state, entreprise: action.payload };
    }
    case actionTypes.GET_ALL_ENTREPRISE:
      return {
        ...state,
        entreprises: [],
      };
    case actionTypes.SET_ALL_ENTREPRISE:
      return {
        ...state,
        entreprises: action.entreprises?.result,
        totalPagesNumbers: action.entreprises?.pagination?.nbPage,
      };
    case actionTypes.SET_ALL_ENTREPRISE_TOTAL_PAGE_NUMBER:
      return {
        ...state,
        totalPagesNumbers: action.payload,
      };
    case actionTypes.SET_COMPANY_SHEET:
      return {
        ...state,
        companySheet: action.payload,
      };
    case actionTypes.SET_COMPANY_INFOS:
      return {
        ...state,
        companyInfos: action.payload,
      };
    case actionTypes.GET_COMPANY_SHEET:
      return {
        ...state,
      };
    case actionTypes.GET_COMPANY_INFOS:
      return {
        ...state,
        companyInfos: action.payload,
      };
    case actionTypes.GET_COMPANY_FINANCIAL_INFOS:
      return {
        ...state,
      };
    case actionTypes.SET_COMPANY_FINANCIAL_INFOS:
      return {
        ...state,
        financialData: action.payload,
      };
    case actionTypes.EDIT_ENTREPRISE_SUCCESS:
      return {
        ...state,
        editResponse: true,
      };
    case actionTypes.EDIT_ENTREPRISE_FAILURE:
      return {
        ...state,
        editResponse: false,
      };
    case actionTypes.RESET_EDIT_RESPONSE:
      return {
        ...state,
        editResponse: undefined,
      };
    case actionTypes.RESET_ADD_SUCCESS_STATE:
      return {
        ...state,
        addSuccess: undefined,
      };
    case actionTypes.ADD_ENTREPRISE_FORM_DATA:
      return {
        ...state,
        addCompanyFormData: action.payload,
      };
    case actionTypes.RESET_ENTREPRISE_FORM_DATA:
      return {
        ...state,
        addCompanyFormData: null,
      };
    case actionTypes.ADD_COMPANY_STEP:
      return {
        ...state,
        addCompanyStep: action.payload,
      };
    /************************ add entreprise form states */
    case actionTypes.ADD_COMPANY_VALIDATION_REQUESTED:
      return {
        ...state,
        validationRequested: action.payload,
      };
    case actionTypes.ADD_COMPANY_VALIDATION_REQUESTED_STEP2:
      return {
        ...state,
        validationRequestedStep2: action.payload,
      };
    case actionTypes.ADD_COMPANY_VALIDATION_REQUESTED_FISCAL:
      return {
        ...state,
        validationRequestedFiscal: action.payload,
      };
    case actionTypes.ADD_COMPANY_STEPS_DATA:
      return {
        ...state,
        stepsData: action.payload,
      };
    /********************* */
    case actionTypes.EDIT_ENTREPRISE_FORM_DATA_FICHE:
      return {
        ...state,
        editCompanyFormData: action.payload,
      };
    case actionTypes.EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED:
      return {
        ...state,
        validationRequestedEditFicheTab: action.payload,
      };
    case actionTypes.EDIT_ENTREPRISE_FORM_SET_DIRTY:
      return {
        ...state,
        dirtyEditForms: action.payload,
      };
    case actionTypes.EDIT_ENTREPRISE_FORM_DATA_FINANCIAL:
      return {
        ...state,
        editCompanyFormDataFinancial: action.payload,
      };
    case actionTypes.EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED_FINANCIAL:
      return {
        ...state,
        validationRequestedEditFinancialTab: action.payload,
      };
    case actionTypes.GET_ALL_ENTREPRISE_ESTABLISHEMENTS_SUCCESS:
      return {
        ...state,
        listCompaniesEstablishments: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
