import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonElement from '../../../components/button/button';
import { languageData } from '../../../i18n';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import { setEditFormDirty } from '../../../store/actions/actions';
import CardContainer from '../../CardContainer';
import CheckBoxCounter from '../FormWizzard/CheckBoxCounter';
import FormCard from '../FormWizzard/FormCard';
import { addRedevanceDetails } from '../../../utils/addRedevance';
import { UpdateRedevanceDetails } from '../../../utils/UpdateRedevance';
import { DeleteRedevanceDetails } from '../../../utils/DeleteRedevance';
export default function Redevances() {
  const dispatch = useDispatch();
  const formData = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.editCompanyFormDataFinancial,
  );

  const handleChangeCounter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, name } = event.target;
    dispatch(setEditFormDirty(true));
    if (value === 'delete') {
      DeleteRedevanceDetails({
        formData: formData,
        dispatch,
        id,
      });
    } else {
      UpdateRedevanceDetails({
        formData: formData,
        dispatch,
        id,
        checked,
        value,
        name,
      });
    }
  };

  return (
    <CardContainer
      label={languageData.global.Form.redevance}
      collapse
      type={'form'}
    >
      <FormCard align="flex-end" grid={8} gap={'gp20'}>
        {formData?.royalties?.map(
          (item: serviceEntrepriseModels.royaltiesInformation) => (
            <div key={item.key}>
              <CheckBoxCounter
                label={item.name}
                ischecked={item.isActive}
                name={item.name}
                id={item.key}
                prefix="%"
                onChangeCounter={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeCounter(event)
                }
                initialCounter={item.pct}
                editItem={item.editItem}
                deleteItem={item.deleteItem}
              />
            </div>
          ),
        )}
        {formData.royalties?.length && formData.royalties?.length < 6 ? (
          <ButtonElement
            className="btn-repeater"
            type="IconButton"
            text={languageData.global.add}
            buttonSize="none"
            buttonStyle="fl_rev btn-grey-light"
            radius="rd8"
            textColor="GraniteGray-txt"
            onClick={() =>
              addRedevanceDetails({
                newFormData: formData,
                dispatch,
                key: 3,
              })
            }
          />
        ) : null}
      </FormCard>
    </CardContainer>
  );
}
