import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { addRole, resetAddResponse } from '../../store/actions/actions';
import { serviceRolesModels } from '../../models/services/module.role';
import { useEffect, useState } from 'react';
import Toast, { ToastProps } from '../../components/Toast/Toast';
import { getErrorEditCompany } from '../../utils/GetErrors';
import { languageData } from '../../i18n';
import NewRoleModalContent from './NewRoleModalContent';
interface AddRoleProps {
  openAddRole: boolean;
  setOpenAddRole: Dispatch<SetStateAction<boolean>>;
  companyId?: number | undefined;
  establishmentId?: number | undefined;
  fetchRoleslist: any;
  paginationModel: servicePaginationModels.PaginationModelType;
}

export default function AddRoleModal(props: AddRoleProps) {
  const [notValidForm, setNotValidForm] = useState<boolean | undefined>(
    undefined,
  );
  const [requestedValidation, setRequestedValidation] =
    useState<boolean>(false);
  const [isToastVisible, setIsToastVisible] = useState<boolean | undefined>(
    undefined,
  );
  const [toastTilte, setToastTitle] = useState<string>('');
  const [toastType, setToastType] = useState<ToastProps['type']>('info');
  const [formData, setFormData] = useState<serviceRolesModels.rolesAction>({
    type: 'action',
    payload: {
      name: '',
      active: undefined,
      companyId: props.companyId,
      establishmentId: props.establishmentId,
      permissions: [],
    },
  });
  const dispatch = useDispatch();
  const addSuccess = useSelector(
    (state: { role: serviceRolesModels.RolesState }) =>
      state.role.addRoleSuccess,
  );
  const activeTabContent = useSelector(
    (state: any) => state.tabs.activeTabContent,
  );

  useEffect(() => {
    setNotValidForm(
      getErrorEditCompany({
        fieldName: 'name',
        isRequired: true,
        fieldValue: formData?.payload.name,
      }),
    );
  }, [formData?.payload]);
  const handleAddRole = () => {
    setRequestedValidation(true);
    if (!notValidForm) {
      dispatch(addRole(formData?.payload));
      dispatch(resetAddResponse());
      props.setOpenAddRole(false);
      setFormData({
        type: 'action',
        payload: {
          name: '',
          active: undefined,
          companyId: props.companyId,
          establishmentId: props.establishmentId,
          permissions: [],
        },
      });
      setRequestedValidation(false);
    }
  };

  useEffect(() => {
    setIsToastVisible(false);
    switch (addSuccess) {
      case true: {
        setIsToastVisible(true);
        setToastType('success');
        setToastTitle(`${languageData.global.messages.addRoleSuccess}`);
        break;
      }
      case false: {
        setIsToastVisible(true);
        setToastType('error');
        setToastTitle(`${languageData.global.messages.failure}`);
        break;
      }
      default:
        setIsToastVisible(false);
        break;
    }
  }, [addSuccess]);
  useEffect(() => {
    setIsToastVisible(false);
  }, []);
  const handleCloseModal = () => {
    setFormData({
      type: 'action',
      payload: {
        name: '',
        active: '',
        companyId: props.companyId,
        establishmentId: props.establishmentId,
        permissions: [],
      },
    });
    setRequestedValidation(false);
    props.setOpenAddRole(false);
  };
  return (
    <>
      <Modal
        version="custom"
        title="Nouveau rôle"
        content={
          <NewRoleModalContent
            setFormData={setFormData}
            formData={formData}
            requestedValidation={requestedValidation}
          />
        }
        cancel
        save
        close
        visibility={props.openAddRole}
        maxWidth={771}
        onClickConfirm={handleAddRole}
        onClickClose={handleCloseModal}
        // key={uuidv4()}
      />
      <Toast
        type={toastType}
        description={toastTilte}
        isVisible={isToastVisible ? isToastVisible : false}
        timeout={4000}
      />
    </>
  );
}
