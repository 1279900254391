import React, { Children, ReactNode } from 'react';
import logo from '../../assets/icons/LogoFrenchInnov.svg';
import SubscriptionInvoice from './SubscriptionInvoice';
/**
 * Invoice component description.
 *
 * @Invoice
 *
 */

interface InvoiceProps {
  totalHt: number;
  taxPercentage?: number;
  tax: number;
  totalTtc: number;
  children: ReactNode;
  title: string;
  tva: number;
  ht: number;
}

const InvoiceBody: React.FC<InvoiceProps> = ({
  totalHt,
  tax,
  taxPercentage,
  totalTtc,
  children,
}) => {
  return (
    <div className="Body-Invoice">
      <div className="table-header fl_row">
        <p className="p10 w-700 row-1">Désignation</p>
        <p className="p10 w-700 row-2">TVA</p>
        <p className="p10 w-700 row-3">Montant HT</p>
      </div>
      <div className="table">
        <div className="table-body fl_col gp10">{children}</div>
      </div>
      <div className="table-footer jc_fe">
        <div className="fl_col">
          <div className="fl_row jc_s ai_c pd10-t-b">
            <p className="p10 w-900">Total HT</p>
            <p className="p10">{totalHt}€</p>
          </div>
          <div className="fl_row jc_s ai_c pd10-t-b">
            <p className="p10 w-900">Tax ({taxPercentage}%)</p>
            <p className="p10">€{tax}</p>
          </div>
          <div className="fl_row jc_s ai_c pd10-t-b">
            <p className="p10 w-900">Total TTC</p>
            <p className="p10 w-900">{totalTtc}€</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceBody;
