import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { IsTabFormDirty } from '../../../services/tabs/IsTabFormDirty';
import { initialState } from '../../../utils/addCompanyFormInitialState';

interface UnsavedChanges {
  newFormData: any;
  isDirty: boolean;
  unsavedChangesData: serviceTabsModels.unsavedChangesDataType[] | [];
}

export const useUnsavedChanges = (): UnsavedChanges => {
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );

  const tabFormDirtyIndex = IsTabFormDirty({
    array: unsavedChangesData,
    key: 'Ajouter une entreprise',
  });

  const isDirty = tabFormDirtyIndex !== -1;
  const newFormData = isDirty
    ? unsavedChangesData[tabFormDirtyIndex].value.payload
    : initialState;

  return { newFormData, isDirty, unsavedChangesData };
};
