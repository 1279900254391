import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  addUserFailure,
  addUserSuccess,
  checkUserByEmailInvalid,
  checkUserByEmailSuccess,
  deleteUserSuccess,
  editUserSuccess,
  failure,
  getUserByIdSuccess,
  getUsersListSuccess,
  resetLoggedIn,
  resetPasswordSuccesAction,
  setActiveEmailError,
  setUsersTotalPagesNumber,
  updateUserFailure,
  updateUserPassworFailure,
  updateUserPassworSuccess,
  updateUserSuccess,
  usedEmailErrorAction,
} from '../actions/actions';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import userApi from '../../services/apiHandlers/userApi';
import { unauthorizedError } from '../../utils/unauthorizedError';
import { usedEmailError } from '../../utils/usedEmailError';

export function* getUsersListSaga(
  action: serviceAppUserModels.AppUserListAction,
) {
  try {
    const response: AxiosResponse = yield userApi.getUsers(action.payload);
    if (response?.data) {
      yield put(getUsersListSuccess(response?.data?.result));
      yield put(setUsersTotalPagesNumber(response.data.pagination.nbPage));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* addUserSaga(action: serviceAppUserModels.AppUserAction) {
  try {
    const response: AxiosResponse = yield userApi.addUser(action.payload);
    if (response.data) {
      action._ && action._();
    }
    if (response.data?.result?.id) {
      yield put(addUserSuccess(response.data.result.id));
    } else {
      yield put(addUserFailure());
    }
  } catch (error: any) {
    if (usedEmailError(error)) {
      yield put(usedEmailErrorAction());
    }
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(addUserFailure());
  }
}
export function* getUserByIdSaga(
  action: serviceAppUserModels.AppUserDetailsAction,
) {
  try {
    const response: AxiosResponse = yield userApi.getUserById(action.payload);
    if (response?.data) {
      yield put(getUserByIdSuccess(response?.data?.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* deleteUserSaga(
  action: serviceAppUserModels.AppUserDetailsAction,
) {
  try {
    const response: AxiosResponse = yield userApi.deleteUser(action.payload);
    if (response?.data.code === 200) {
      yield put(deleteUserSuccess(true));
    }
  } catch (error: any) {
    yield put(deleteUserSuccess(false));
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* editUserSaga(action: serviceAppUserModels.AppUserAction) {
  try {
    const response: AxiosResponse = yield userApi.editUser(action.payload);

    if (response.data?.result?.id) {
      yield put(editUserSuccess(true));
    } else {
      yield put(editUserSuccess(false));
    }
  } catch (error: any) {
    if (usedEmailError(error)) {
      yield put(usedEmailErrorAction());
    }
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(editUserSuccess(false));
  }
}
export function* checkUserByEmailSaga(
  action: serviceAppUserModels.AppUserDetailsAction,
) {
  try {
    const response: AxiosResponse = yield userApi.checkUserByEmail(
      action.payload,
    );
    if (response?.data?.code === 200) {
      yield put(checkUserByEmailSuccess(true));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
    yield put(checkUserByEmailSuccess(false));
    if (error?.response?.data?.failureResponse?.codeFI?.code === 806) {
      yield put(checkUserByEmailInvalid(true));
    }
    if (error?.response?.data?.failureResponse?.codeFI?.code === 824) {
      yield put(setActiveEmailError(true));
    }
  }
}
export function* resetPasswordSaga(
  action: serviceAppUserModels.setPasswordAction,
) {
  try {
    const response: AxiosResponse = yield userApi.resetPassword(action.payload);
    if (response?.data?.code === 200) {
      yield put(resetPasswordSuccesAction(true));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(resetPasswordSuccesAction(false));
    yield put(failure(error));
  }
}
interface IupdateUser {
  lastName: string;
  email: string;
  firstName: string;
  phoneNumber: string;
}
export function* updateUserProfile(action: { type: string; payload: any }) {
  try {
    const response: AxiosResponse = yield userApi.editProfileUser(
      action.payload,
    );
    if (response?.data?.code === 200) {
      yield put(editUserSuccess(true));
      yield put(updateUserSuccess(response?.data?.result));
    } else {
      yield put(editUserSuccess(false));
    }
  } catch (error: any) {
    if (usedEmailError(error)) {
      yield put(usedEmailErrorAction());
    }
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(editUserSuccess(false));
    yield put(updateUserFailure(error.message));
  }
}

export function* updatePasswordUserSaga(action: {
  type: string;
  payload: any;
}) {
  try {
    const response: AxiosResponse = yield userApi.updatePasswordUser(
      action.payload,
    );
    if (response?.data?.code === 200) {
      yield put(resetPasswordSuccesAction(true));
      yield put(updateUserPassworSuccess(response?.data?.result));
    }
  } catch (error: any) {
    yield put(
      updateUserPassworFailure(error?.response?.data?.failureResponse?.codeFI),
    );
  }
}
