import React, { useEffect, useState } from 'react';
import ProgressBarFormWizzard from '../../../../components/FormWizard/FormWizzardProgressBar';
import FormWizzardHeaderSingleStep from '../../../../components/FormWizard/FormWizzardHeaderSingleStep';

import { useSelector } from 'react-redux';
import { serviceEtablissementModels } from '../../../../models/services/module.etablissement';
import { StepItem } from '../../../../utils/addCompanyStepsDataInitialState';

export default function FormWizzardHeader() {
  const stepsData = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.stepsDataEtablissement,
  );
  const doneStepsCount = stepsData.filter(
    (step: StepItem) => step.isActive,
  ).length;
  const GetProgressBarWidth = () => {
    const stepsLength = stepsData.length;
    const progressBarWidth =
      parseFloat((100 / stepsLength).toFixed(2)) * doneStepsCount;
    return progressBarWidth;
  };
  const [progressWidth, setProgressWidth] = useState(0);
  useEffect(() => {
    setProgressWidth(GetProgressBarWidth());
  }, []);
  return (
    <div className="FormWizzardHeader">
      <ProgressBarFormWizzard
        progress={`${progressWidth}%`}
        stepsLength={stepsData?.length}
        doneStepsCount={doneStepsCount}
      />
      <div className="header-wrapper fl_row jc_c txt_center">
        <div className="header-desktop fl_row">
          {stepsData.map((step: StepItem, index: number) => (
            <FormWizzardHeaderSingleStep
              key={index}
              {...step}
              activePartOne={step.activePartOne}
              activePartTwo={step.activePartTwo}
              donePartOne={step.donePartOne}
              donePartTwo={step.donePartTwo}
            />
          ))}
        </div>
        <div className="mobile-header"></div>
      </div>
    </div>
  );
}
