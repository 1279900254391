import React, { ChangeEvent, useEffect, useState } from 'react';
import BottomArrowGreyIcon from '../../assets/icons/BottomArrowGreyIcon.svg';
import FrFlagCircle from '../../assets/icons/FrFlagCircle.svg';
import SearchIconGreyInp from '../../assets/icons/SearchIconGreyInp.svg';
import InputField from './Input';
import OptionItem from './OptionItem';

interface SelectInputProps {
  label?: string;
  name?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: string;
  isDisabled?: boolean;
  haserror?: boolean;
  errorMsg?: string;
  placeholder?: string;
  options: string[];
  icons?: string[];
  target: 'country' | 'dropdown' | 'search' | 'reference' | 'reflags';
  onKeyUp?: () => void;
  onSelect?: (
    selectedValue: string,
    selectedIcon?: string,
    reference?: string
  ) => void;
  reference?: string[];
  isRequired?: boolean;
  defaultValue?: string | number | undefined;
  value?: string | number | undefined;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  type,
  style,
  isDisabled,
  placeholder,
  icons,
  options,
  onChange,
  target,
  onKeyUp,
  onSelect,
  haserror,
  errorMsg,
  reference,
  isRequired,
  defaultValue,
  value,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>('');
  const [inputIcon, setInputIcon] = useState('');
  const [localHasError, setLocalHasError] = useState(haserror || false);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
    if (typeof defaultValue === 'string' && defaultValue === '') {
      setInputValue('');
    }
  }, [defaultValue]);

  // Update localHasError state when the haserror prop changes
  useEffect(() => {
    setLocalHasError(haserror || false);
  }, [haserror]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowDropdown(false);

    if (onChange) {
      onChange(e);
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowDropdown(false);
  };

  const handleOptionClick = (
    optionValue: string,
    optionIcon?: string,
    ref?: string
  ) => {
    setInputValue(optionValue);
    setShowDropdown(false);

    if (onSelect) {
      onSelect(optionValue, optionIcon, ref);
    }
  };

  const handleContainerClick = () => {
    setLocalHasError(false); // Set localHasError to false when the container is clicked
  };

  const filteredItems = options
    .map((option, index) => {
      const referenceVal = reference ? reference[index] : undefined;
      const optionLower = option.toLowerCase();
      const referenceLower = referenceVal ? referenceVal.toLowerCase() : '';

      if (
        optionLower.includes(inputValue.toString().toLowerCase()) ||
        referenceLower.includes(inputValue.toString().toLowerCase())
      ) {
        return {
          option,
          icon: icons && icons.length > index ? icons[index] : undefined,
          reference: referenceVal,
        };
      }

      return null;
    })
    .filter(Boolean);

  return (
    <div
      className={`InputFieldDropDown fl_col gp8 ${target}_field ${
        localHasError ? 'haserror' : ''
      }`}
      onClick={handleContainerClick}
    >
      <div className="inp-select-field">
        {target === 'reflags' && (
          <div className="country-details fl_row ai_c gp20">
            <img
              className="icon icon-flag"
              src={
                inputIcon
                  ? require(`../../data/flags/${inputIcon}`)
                  : FrFlagCircle
              }
              alt=""
            />
            <input
              autoComplete="off"
              className="country-code"
              type="text"
              value={'+33'}
            />
          </div>
        )}
        <div className="inp fl_col gp8">
          <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />

          {target === 'reflags' ? (
            <InputField
              defaultValue={defaultValue}
              value={value}
              onChange={handleValueChange}
              name={name}
              type="text"
              placeholder={placeholder}
              label={label}
              style="form"
              isDisabled={isDisabled}
              onClick={() => setShowDropdown((prevState) => !prevState)}
              haserror={localHasError}
              isRequired={isRequired}
            />
          ) : (
            <InputField
              defaultValue={defaultValue}
              value={inputValue}
              onChange={onChange}
              name={name}
              type="text"
              placeholder={placeholder}
              label={label}
              style="form"
              isDisabled={isDisabled}
              onClick={() => setShowDropdown((prevState) => !prevState)}
              haserror={localHasError}
              isRequired={isRequired}
            />
          )}
          {target === 'dropdown' && (
            <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />
          )}
          {target === 'search' && (
            <img className="icon-search" src={SearchIconGreyInp} alt="" />
          )}
          <div className={`InputDropDownList ${showDropdown ? '' : 'hide'}`}>
            <ul className="fl_col gp10 pd10-t-b">
              {filteredItems.map(
                (item, index) =>
                  item && (
                    <OptionItem
                      key={index}
                      target={target}
                      value={item.option}
                      icon={item.icon}
                      reference={item.reference}
                      onClick={(value, icon, ref) =>
                        handleOptionClick(value, icon, ref)
                      }
                    />
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
      {localHasError && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
    </div>
  );
};

export default SelectInput;
