import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalAddCarte from '../../../../components/Modal/ModalAddCarte';
import tabs_list from '../../../../data/tabsList';
import {
  cardSingle,
  carteConfig,
} from '../../../../models/services/module.cart';
import { serviceTabsModels } from '../../../../models/services/module.tabs';
import { catalogueApi } from '../../../../services/apiHandlers/catalogueApi';
import { CloseTab } from '../../../../services/tabs/CloseTab';
import {
  postCart,
  updateCart,
} from '../../../../store/actions/catalogues/actions';
import AddCart from './AddCart';
import { OpenTab } from '../../../../services/tabs/OpenTab';
export default function Carte() {
  const navigate = useNavigate();
  const [newCartModal, setnewCartModal] = useState(true);
  const [carteConfig, setcarteConfig] = useState<Partial<carteConfig>>({});
  const [carts, setcarts] = useState<cardSingle[]>([]);
  const [carteAdded, setcarteAdded] = useState(false);
  const dispatch = useDispatch();
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );

  const closeTab = () => {
    CloseTab({
      key: activeTabContent.key,
      open_tabs,
      name: tabs_list[12].name,
      dispatch,
    });
  };
  const clseModal = () => {
    closeTab();
    setnewCartModal(false);
  };
  const navigateToCreation = () => {
    dispatch(
      postCart(carteConfig, (_) => {
        navigate(`/carte/add`, { state: _ });
        setcarteAdded(true);
        setnewCartModal(false);
        dispatch(updateCart(true));
      }),
    );
  };
  const handleSubmit = () => {
    navigateToCreation();
  };
  const onChangeField = <K extends keyof carteConfig>(
    field: K,
    value: carteConfig[K],
  ) => {
    let ar = carteConfig as carteConfig;
    value == -1 ? delete ar[field] : (ar[field] = value);
    setcarteConfig({ ...ar });
  };
  const fetchCarts = async () => {
    try {
      let cards = await catalogueApi.getAllCarts();
      setcarts((cards.data?.data?.result ?? []) as cardSingle[]);
    } catch (error) {
      setcarts([]);
    }
  };
  useEffect(() => {
    fetchCarts();
  }, []);
  const onEdit = (id?: string, designation?: string) => {
    navigate(`/carte/add`, { state: { card: { id, designation } } });
  };

  return (
    <div>
      <ModalAddCarte
        configCarte={carteConfig}
        onCartConfigChange={onChangeField}
        handelclose={clseModal}
        version={'Item'}
        type={'edit'}
        title={'Nouvelle carte'}
        visibility={newCartModal}
        close
        handleSave={handleSubmit}
      />
      {carteAdded && <AddCart></AddCart>}
      {/*  <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: 20,
        }}
      >
        <ButtonElement
          type={'TextButton'}
          text={languageData.fr.Modal.cart}
          buttonSize={'small'}
          buttonStyle="btn-primary"
          onClick={handleOpenNewCart}
        />
      </div> */}
      {/* 

      <p className="p20 pd20">Liste des cartes</p>
      <div className="ListingCard">
        {carts?.map((_) => (
          <MenuItem
          cart
            editMenu={() => onEdit(_.id + '', _.designation)}
            iscategory
            name={_.designation}
          ></MenuItem>
        ))}
      </div> */}
    </div>
  );
}
