import { takeEvery } from 'redux-saga/effects';
import * as CataloguesactionTypes from '../actions/catalogues/actionTypes';
import { startGetBadges, startPostBadges } from './catalogue/badgeSaga';
import {
  startDeleteAllergens,
  startGetAllergens,
  startPostAllergens,
} from './catalogue/allergenSaga';
import { startDeleteTva, startGetTva, startPostTva } from './catalogue/tvaSaga';
import {
  startDeleteTags,
  startGetTags,
  startPostTags,
} from './catalogue/tagSaga';
import {
  startDeleteArticle,
  startGetArticleWithId,
  startGetArticles,
  startPostArticles,
} from './catalogue/articleSaga';
import {
  startDeleteFamily,
  startGetFamilies,
  startPostFamily,
} from './catalogue/familySaga';
import {
  startGetSubFamilies,
  startPostSubFamily,
} from './catalogue/subFamilySaga';
import {
  startDeleteFolder,
  startGetFolders,
  startGetSubfolders,
  startPostFolder,
} from './catalogue/fileManagerSaga';
import {
  startDelteImage,
  startGetImages,
  startPostImage,
} from './catalogue/imageSaga';
import {
  startDeleteScreen,
  startGetPurshaseMethod,
  startGetScreenById,
  startGetScreens,
  startPostScreen,
} from './catalogue/screenSaga';
import {
  deleteLevels,
  getLevelById,
  startGetAllLevels,
  startPostLevels,
} from './catalogue/levelSaga';
import {
  startDeleteMenubyId,
  startGetAllMenu,
  startGetMenubyId,
  startPostMenu,
} from './catalogue/menuSaga';
import {
  deleteCategoryById,
  getCategoryById,
  postCategory,
  startGetAllCategory,
} from './catalogue/categorySaga';
import {
  dragUndrag,
  getCardById,
  getListPvFiltred,
  postAssignPv,
  postCart,
} from './catalogue/cardSaga';
export function* watchCatalogueSagas() {
  yield takeEvery(CataloguesactionTypes.START_GET_BADGES, startGetBadges);
  yield takeEvery(CataloguesactionTypes.POST_BADGE, startPostBadges);
  yield takeEvery(CataloguesactionTypes.START_GET_ALLERGEN, startGetAllergens);
  yield takeEvery(CataloguesactionTypes.POST_ALLERGEN, startPostAllergens);
  yield takeEvery(CataloguesactionTypes.DELETE_ALLERGEN, startDeleteAllergens);
  yield takeEvery(CataloguesactionTypes.START_GET_TVA, startGetTva);
  yield takeEvery(CataloguesactionTypes.DELETE_TVA, startDeleteTva);
  yield takeEvery(CataloguesactionTypes.POST_TVA, startPostTva);
  yield takeEvery(CataloguesactionTypes.START_GET_TAG, startGetTags);
  yield takeEvery(CataloguesactionTypes.DELETE_TAG, startDeleteTags);
  yield takeEvery(CataloguesactionTypes.POST_TAG, startPostTags);
  yield takeEvery(CataloguesactionTypes.START_GET_ARTICLE, startGetArticles);
  yield takeEvery(CataloguesactionTypes.START_GET_FAMILY, startGetFamilies);
  yield takeEvery(CataloguesactionTypes.DELETE_FAMILY, startDeleteFamily);
  yield takeEvery(CataloguesactionTypes.POST_FAMILY, startPostFamily);
  yield takeEvery(CataloguesactionTypes.POST_SUBFAMILY, startPostSubFamily);
  yield takeEvery(
    CataloguesactionTypes.START_GET_SUBFAMILY,
    startGetSubFamilies,
  );
  yield takeEvery(CataloguesactionTypes.START_GET_ARTICLE, startGetArticles);
  yield takeEvery(CataloguesactionTypes.DELETE_ARTICLE, startDeleteArticle);
  yield takeEvery(CataloguesactionTypes.POST_ARTICLE, startPostArticles);
  yield takeEvery(
    CataloguesactionTypes.START_GET_ARTICLE_ID,
    startGetArticleWithId,
  );
  yield takeEvery(CataloguesactionTypes.START_GET_FOLDER, startGetFolders);
  yield takeEvery(
    CataloguesactionTypes.START_GET_SUBFOLDER,
    startGetSubfolders,
  );
  yield takeEvery(CataloguesactionTypes.POST_FOLDER, startPostFolder);
  yield takeEvery(CataloguesactionTypes.DELETE_FOLDER, startDeleteFolder);
  yield takeEvery(CataloguesactionTypes.UPLOAD_IMAGE, startPostImage);
  yield takeEvery(CataloguesactionTypes.GET_IMAGE, startGetImages);
  yield takeEvery(CataloguesactionTypes.DELETE_IMAGE, startDelteImage);
  yield takeEvery(CataloguesactionTypes.START_POST_SCREEN, startPostScreen);
  yield takeEvery(CataloguesactionTypes.START_GET_SCREEN, startGetScreens);
  yield takeEvery(CataloguesactionTypes.START_DELETE_SCREEN, startDeleteScreen);

  yield takeEvery(
    CataloguesactionTypes.START_GET_PURSAHSE_METHOD,
    startGetPurshaseMethod,
  );
  yield takeEvery(
    CataloguesactionTypes.START_GET_SCREEN_BY_ID,
    startGetScreenById,
  );
  yield takeEvery(CataloguesactionTypes.START_GET_LEVELS, startGetAllLevels);
  yield takeEvery(CataloguesactionTypes.START_POST_LEVELS, startPostLevels);
  yield takeEvery(CataloguesactionTypes.START_DELETE_LEVELS, deleteLevels);
  yield takeEvery(CataloguesactionTypes.START_GET_MENUS, startGetAllMenu);
  yield takeEvery(CataloguesactionTypes.START_GET_LEVEL_BY_ID, getLevelById);
  yield takeEvery(CataloguesactionTypes.START_POST_MENUS, startPostMenu);
  yield takeEvery(CataloguesactionTypes.START_GET_MENU_BY_ID, startGetMenubyId);
  yield takeEvery(CataloguesactionTypes.DELETE_MENU, startDeleteMenubyId);
  yield takeEvery(
    CataloguesactionTypes.START_GET_CATEGORIES,
    startGetAllCategory,
  );
  yield takeEvery(CataloguesactionTypes.START_POST_CATEGORY, postCategory);
  yield takeEvery(
    CataloguesactionTypes.START_GET_CATEGORY_BY_ID,
    getCategoryById,
  );
  yield takeEvery(
    CataloguesactionTypes.START_DELETE_CATEGORY,
    deleteCategoryById,
  );
  yield takeEvery(CataloguesactionTypes.POST_CART, postCart);
  yield takeEvery(CataloguesactionTypes.GET_LIST_PV_FILTRED, getListPvFiltred);
  yield takeEvery(CataloguesactionTypes.POST_ASSIGN_PV, postAssignPv);
  yield takeEvery(CataloguesactionTypes.DRAG_UNDRAG_PV, dragUndrag);
  yield takeEvery(CataloguesactionTypes.GET_CARD_BY_ID, getCardById);
}
