import React from 'react';
import { languageData } from '../../i18n';

/**
 * ProgressBarFormWizzard component description.
 *
 * @ProgressBarFormWizzard
 *
 */

interface ProgressBarFormWizzardProps {
  progress: string;
  stepsLength: number;
  doneStepsCount: number;
}

const ProgressBarFormWizzard: React.FC<ProgressBarFormWizzardProps> = ({
  progress,
  stepsLength,
  doneStepsCount,
}) => {
  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-pc ProgressBarFormWizzard ErrigalWhite-bg">
        <span className="primary-bg" style={{ width: progress }} />
      </div>

      <div className="progress-bar-mobile ProgressBarFormWizzard">
        <div className="steps-number fl_row jc_fe">
          <p className="p14 w-600 primary-txt">
            {languageData.global.step}
            {doneStepsCount}{' '}
            <span>
              {languageData.global.Pagination.of}
              {stepsLength}
            </span>{' '}
          </p>
        </div>
        <div className="progressbar-container">
          <span className="primary-bg" style={{ width: progress }} />
        </div>
      </div>
    </div>
  );
};

export default ProgressBarFormWizzard;
