import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStepsData } from '../../../store/actions/actions';
import addCombineEtablissementStepsDataInitialState from '../../../utils/addCombineEtablissementStepsDataInitialState';
import stepsDataInitialState from '../../../utils/addEtablissementStepsDataInitialState';
// Assuming you have a RootState type defined

const useInitializeStepsData = () => {
  const dispatch = useDispatch();
  const combineEtablissement = useSelector(
    (state: {
      combineEtablissementReducer: { combineEtablissement: boolean };
    }) => state.combineEtablissementReducer.combineEtablissement,
  );

  useEffect(() => {
    dispatch(
      setStepsData(
        combineEtablissement
          ? addCombineEtablissementStepsDataInitialState
          : stepsDataInitialState,
      ),
    );
  }, [combineEtablissement]);
};

export default useInitializeStepsData;
