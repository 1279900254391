import React, { ChangeEvent, useState } from 'react';
import SelectInput from '../Input/Catalogue/SelectInput';
import { Option } from '../../utils/catalogueHelper/FamilyHelper';
import SelectInputCatalogue from '../Input/SelectedInputCatalogue';
import { languageData } from '../../i18n';

/**
 * ModalFamilyElement component description.
 *
 * @ModalFamilyElement
 *
 */

interface ModalFamilyElementProps {
  ModalFamilyLabel?: string;
  valueinp?: string;
  onChange?: (id: number) => void;
  options?: Option[];
  titleOption?: string;
  dropDownDisabled?: boolean;
  placehlolderOption?: string;
}

const ModalFamilyElement: React.FC<ModalFamilyElementProps> = ({
  ModalFamilyLabel,
  onChange,
  valueinp,
  options,
  titleOption,
  dropDownDisabled,
  placehlolderOption,
}) => {
  return (
    <div className="ModalFamilyElement fl_col fl-1">
      <label
        className="p12 w-500 DarkBlueGray-txt"
        htmlFor="ModalFamilyElement"
      >
        {ModalFamilyLabel}
      </label>
      <SelectInputCatalogue
      hideOptionItem
        isDisabled={dropDownDisabled}
        onChange={onChange}
        label={titleOption}
        placeholder={placehlolderOption ?? languageData.fr.Choose_an_option}
        target="dropdown"
        options={options ?? []}
      ></SelectInputCatalogue>
    </div>
  );
};

export default ModalFamilyElement;
