import { call, put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
  fetchCompanyMachineTypesSuccess,
  fetchCompanyMachineTypesFailure,
  fetchMachineSuperTypesSuccess,
  fetchMachineSuperTypesFailure,
  addCompanyMachineTypeFailure,
  addCompanyMachineTypeSuccess,
  updateCompanyMachineTypeSuccess,
  updateCompanyMachineTypeFailure,
} from '../actions/actions';
import entrepriseApi from '../../services/apiHandlers/entrepriseApi';
import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import { NotificationErrorProvider } from '../../utils/Notificationprovider';

export function* fetchCompanyMachineTypesSaga(
  action: serviceCampanyMachineTypeModels.FetchCompanyMachineTypesRequestAction,
) {
  try {
    const { companyId, typeOfMachineTypeId } = action.payload;
    const response: AxiosResponse = typeOfMachineTypeId
      ? yield entrepriseApi.getCampanyMachineTypesbysuperType(
          companyId,
          typeOfMachineTypeId as string,
        )
      : yield entrepriseApi.getCampanyMachineTypes(companyId);
    if (response.data) {
      yield put(fetchCompanyMachineTypesSuccess(response.data));
      action.payload?._ && action.payload?._(response.data?.result);
    }
  } catch (error: any) {
    yield put(fetchCompanyMachineTypesFailure(error.message));
  }
}
export function* fetchMachineSuperTypesSaga() {
  try {
    const response: AxiosResponse =
      yield entrepriseApi.getCampanyMachineSuperTypes();
    if (response.data) {
      yield put(fetchMachineSuperTypesSuccess(response.data));
    }
  } catch (error: any) {
    yield put(fetchMachineSuperTypesFailure(error.message));
  }
}
export function* addCompanyMachineTypeSaga(
  action: serviceCampanyMachineTypeModels.AddCompanyMachineTypesRequestAction,
) {
  try {
    const response: AxiosResponse = yield entrepriseApi.addCompanyMachineType(
      action.payload,
    );
    yield put(addCompanyMachineTypeSuccess(response.data));
    action?._ && action?._();
  } catch (error: any) {
    yield put(addCompanyMachineTypeFailure({ error: error.message }));
  }
}

export function* updateCampanyMachineTypes(action: {
  type: string;
  payload: {
    machineTypeId: number;
    body: string;
    _?: () => void;
    toDelete: boolean;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      action.payload.toDelete
        ? entrepriseApi.deleteCampanyMachineType
        : entrepriseApi.updateCampanyMachineType,
      action.payload.machineTypeId,
      action.payload.body,
    );
    if (response.data) {
      yield put(updateCompanyMachineTypeSuccess(response.data));
      action?.payload?._ && action?.payload?._();
    } else {
      yield put(updateCompanyMachineTypeFailure('No response data received.'));
    }
  } catch (error: any) {
    yield put(updateCompanyMachineTypeFailure(error.message));
    NotificationErrorProvider(error);
  }
}
