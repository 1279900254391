import React from 'react';
import ModalElementName from '../ModalElementName';
import ModalElementTva from '../ModalElementTva';
import { ModalProps } from '../../../models/services/module.modals';
import BinIconRed from '../../../assets/icons/BinIconRed.svg';
export default function TvaModalController(props: ModalProps) {
  let modalContentBody;
  let modalContentFooter;

  switch (props.type) {
    case 'edit':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full">
          <ModalElementName
          isDefault={props.isDefault}
            onSelectAsDefault={props.onSelectAsDefault}
            placeholder={props.placeholder}
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
          <ModalElementTva
            onChange={props.onChangeSecondInput}
            itemTvaLabel={props.itemTvaLabel}
            itemTva={props.itemTva}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_s">
          {props.deleteitem && !props.hideDelete && (
            <button
              className="fl_row gp8 btn_cat btn-delete"
              onClick={props.handeldelete}
            >
              <img src={BinIconRed} alt="" />
              {props.deleteitemTxt}
            </button>
          )}
          <h1></h1>
          <div className="fl_row gp12">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 1}
                onClick={props.handleSave}
                className={`btn_cat ${!props.itemName ? 'btn-disabledGrey' : (props.itemName && props.itemName?.trim()?.length < 1) || parseInt(props.itemTva ?? '') < 0 || props.itemTva?.includes('N') ? 'btn-lightGrey' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;
    default:
      modalContentBody = null;
      modalContentFooter = null;
  }

  return {
    modalContentBody,
    modalContentFooter,
  };
}
