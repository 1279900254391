import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddSubscriptionsModalContent from '../../../components/Etablissement/Modal/AddSubscriptionsModalContent';
import ConfirmEnableStatusModal from '../../../components/Etablissement/Modal/ConfirmEnableStatusModal';
import Modal from '../../../components/Modal/Modal';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';
import {
  createAbonnementRequest,
  fetchAbonnementDataRequest,
  getAbonnementByIdRequest,
  resetAbonnementState,
  updateAbonnementRequest,
  updateAbonnementStatusRequest,
} from '../../../store/actions/actions';
import { languageData } from '../../../i18n';
import { convertDate } from '../../../utils/ConvertTDate';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import Spinner from '../../../components/skeleton/spinner';
import Pagination from '../../../components/Table/Pagination-table';
import { usePagination } from '../../../pages/etablissement/pages/usePagination';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import PageTable from '../../Table/PageTable';
import TableSection from '../../Table/TableSection';
import TableContent from '../../Table/TableContent';
import CardContainer from '../../CardContainer';
import TableFilter from './SubscriptionsTable/TableFilter';
import TableUserCard from './SubscriptionsTable/TableCard';
import TableCardType from './SubscriptionsTable/TableCardType';

export default function SubscriptionsListAdminContainer() {
  const [showModalAddSub, setShowModalAddSub] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [abonnmentId, setAbonnmentId] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [addAbonnment, setAddAbonnment] =
    useState<serviceEtablissmentMachineTypeModels.IaddAbonnment>({
      designation: '',
      description: '',
      totalHT: '',
      totalTTC: 0,
      vat: '',
      intervalleId: 3,
    });
  const dispatch = useDispatch();

  const { ListAbonnement } = useSelector(
    (state: {
      ListAbonnement: {
        ListAbonnement: serviceEtablissmentMachineTypeModels.createAbonnementResponse;
      };
    }) => state.ListAbonnement,
  );
  const { loading, error } = useSelector(
    (state: { ListAbonnement: { loading: boolean; error: string } }) =>
      state.ListAbonnement,
  );

  const { isAdmin } = useSelector(
    (state: { auth: serviceAuthModels.authState }) => state.auth,
  );

  const { abonnement } = useSelector(
    (state: {
      AbonnementById: {
        abonnement: serviceEtablissmentMachineTypeModels.IaddAbonnment;
      };
    }) => state.AbonnementById,
  );

  const etablissementSheet = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissementSheet,
  );
  const paginationConfig = useMemo(
    () => ({
      currentPage: 1,
      itemsPerPage: 10,
    }),
    [],
  );
  const {
    currentPage,
    itemsPerPage,
    handleNextClick,
    handlePreviousClick,
    handlePageChange,
  } = usePagination(paginationConfig);

  const pagination = useMemo(
    () => ({
      paginationModel: {
        page: currentPage,
        pageSize: itemsPerPage,
      },
    }),
    [currentPage, itemsPerPage],
  );

  useEffect(() => {
    dispatch(
      fetchAbonnementDataRequest(
        etablissementSheet?.id as number,
        pagination?.paginationModel?.page,
        pagination?.paginationModel?.pageSize,
      ),
    );
  }, [dispatch, pagination]);

  const calculeTheTotalTTC = () => {
    const { totalHT, vat } = addAbonnment;
    const percentage = 100;
    const theAdditons = 1;
    const calculatedTotalTTC =
      parseFloat(totalHT.toString()) *
      (theAdditons + parseFloat(vat.toString()) / percentage);

    return calculatedTotalTTC;
  };

  useEffect(() => {
    setAddAbonnment((prev) => ({
      ...prev,
      designation: abonnement?.designation || '',
      description: abonnement?.description || '',
      totalHT: abonnement?.totalHT || '',
      vat: abonnement?.vat || '',
    }));
  }, [abonnement]);

  useEffect(() => {
    const calculatedTotalTTC = calculeTheTotalTTC();
    setAddAbonnment((prev) => ({
      ...prev,
      totalTTC: calculatedTotalTTC,
    }));
  }, [addAbonnment.totalHT, addAbonnment.vat]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddAbonnment((prev) => ({
      ...prev,
      [name]:
        name === 'totalHT' || name === 'vat' ? parseFloat(value) || '' : value,
    }));
  };
  const validateFields = () => {
    const { designation, description, totalHT, vat } = addAbonnment;

    if (designation?.trim() === '') {
      setErrorMessage(languageData.fr.messages.Empty);
      return false;
    }
    if (description?.trim() === '') {
      setErrorMessage(languageData.fr.messages.Empty_description);
      return false;
    }
    if (!totalHT || parseFloat(totalHT?.toString()) <= 0) {
      setErrorMessage(languageData.fr.messages.totalHT);
      return false;
    }
    if (!vat || parseFloat(vat?.toString()) <= 0) {
      setErrorMessage(languageData.fr.messages.vat);
      return false;
    }
    return true;
  };

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLFormElement>,
  ) => {
    validateFields();
  };

  const handleSubmit = () => {
    if (validateFields()) {
      dispatch(
        createAbonnementRequest(
          etablissementSheet?.id as number,
          addAbonnment as serviceEtablissmentMachineTypeModels.IaddAbonnment,
          () => {
            dispatch(
              fetchAbonnementDataRequest(
                etablissementSheet?.id as number,
                pagination?.paginationModel?.page,
                pagination?.paginationModel?.pageSize,
              ),
            );
          },
        ),
      );
      setShowModalAddSub(false);
    }
  };

  const handleUpdate = () => {
    if (validateFields()) {
      dispatch(
        updateAbonnementRequest(abonnement?.id as number, addAbonnment, () => {
          dispatch(
            fetchAbonnementDataRequest(
              etablissementSheet?.id as number,
              pagination?.paginationModel?.page,
              pagination?.paginationModel?.pageSize,
            ),
          );
        }),
      );
      setShowModalAddSub(false);
    }
  };

  const handleGetAbByID = (id: number) => {
    dispatch(getAbonnementByIdRequest(id));
  };

  const handleChangeStatusAbonnement = () => {
    if (abonnmentId) {
      dispatch(
        updateAbonnementStatusRequest(
          abonnmentId?.id,
          !abonnmentId?.isActive,
          () => {
            dispatch(
              fetchAbonnementDataRequest(
                etablissementSheet?.id as number,
                pagination?.paginationModel?.page,
                pagination?.paginationModel?.pageSize,
              ),
            );
          },
        ),
      );
      setShowModalConfirm(false);
    }
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  return (
    <PageTable className="subscriptions-list-table fl_col gp10">
      <Modal
        className="AddSubscriptionsModal"
        version="custom"
        title={languageData.fr.add_abonnement}
        visibility={showModalAddSub}
        onClickClose={() => setShowModalAddSub(false)}
        close
        maxWidth={640}
        content={
          <AddSubscriptionsModalContent
            calculeTheTotalTTC={addAbonnment.totalTTC.toFixed(2)}
            addAbonnment={addAbonnment}
            handleSubmit={() => {
              abonnement?.id ? handleUpdate() : handleSubmit();
            }}
            onBlur={handleOnBlur}
            onClickClose={() => setShowModalAddSub(false)}
            errorMsg={errorMessage}
            errorMessage={errorMessage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange(e)
            }
          />
        }
      />

      <Modal
        className="ConfirmEnableStatusModal"
        version="custom"
        title={languageData.fr.add_abonnement}
        visibility={showModalConfirm}
        close
        onClickClose={() => setShowModalConfirm(false)}
        maxWidth={544}
        content={
          <ConfirmEnableStatusModal
            onClickClose={() => setShowModalConfirm(false)}
            onClickValidate={handleChangeStatusAbonnement}
          />
        }
      />
      {isAdmin == false ? (
        <TableSection>
          {isAdmin ? (
            <TableHeader
              type="add"
              title={languageData.fr.abonnement}
              onClickAdd={() => {
                dispatch(resetAbonnementState());
                setShowModalAddSub(true);
              }}
              textAdd={languageData.fr.add}
            />
          ) : (
            <TableHeader type="add" title={languageData.fr.abonnement} />
          )}

          <TableContent>
            <DesktopTableContainer className="Admin-table">
              <Table className="Table SubscriptionsList">
                <Thead>
                  <Tr>
                    <Td
                      type="text"
                      value={languageData.fr.Modal.Modaldesignation}
                      width={60}
                      align="left"
                    />
                    <Td
                      type="text"
                      value="Total TTC"
                      width={5}
                      align="center"
                    />
                    <Td
                      type="text"
                      value="Intervalle"
                      width={5}
                      align="center"
                    />
                    <Td
                      type="text"
                      value="Ajouté le"
                      width={5}
                      align="center"
                    />
                    <Td type="text" value="Statut" width={5} align="center" />
                    <Td type="empty" width={5} />
                  </Tr>
                </Thead>
                <Tbody>
                  {ListAbonnement && ListAbonnement.result?.length > 0 ? (
                    ListAbonnement?.result?.map(
                      (
                        item: serviceEtablissmentMachineTypeModels.createAbonnementResponse,
                      ) => (
                        <Tr key={item.id}>
                          <Td
                            style="bold"
                            type="text"
                            value={item?.designation}
                            width={80}
                            align="left"
                          />
                          <Td
                            type="text"
                            value={item?.totalTTC + 'Є'}
                            width={5}
                            align="center"
                          />
                          <Td
                            type="text"
                            value={item?.intervalle?.designation}
                            width={5}
                            align="center"
                          />
                          <Td
                            type="text"
                            value={convertDate(item.iDate)}
                            width={5}
                            align="center"
                          />
                          <Td
                            type="ToggleButton"
                            // onChange={() => {
                            //   setAbonnmentId(item as any);
                            //   setShowModalConfirm(true);
                            // }}
                            // checked={item.isActive}
                            width={5}
                            align="center"
                            statusToggle="active"
                            onClickvalidate={() => alert('Validate')}
                          />
                          <Td
                            type="editStatus"
                            width={5}
                            onClick={() => {
                              setShowModalAddSub(true);
                              handleGetAbByID(item.id);
                            }}
                          />
                        </Tr>
                      ),
                    )
                  ) : (
                    <Tr>
                      <Td
                        type="text"
                        value={'languageData.fr.messages.no_data'}
                      />
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </DesktopTableContainer>
          </TableContent>
        </TableSection>
      ) : (
        <CardContainer ClassName="user-table h-full" type="standard">
          <div className="advanced-table-header fl_col gp16">
            <TableFilter />
            <div className="table-header-elements">
              <div className="elements-wrapper fl_row Independence-txt p10 w-900 uper">
                <div className="row-1">
                  <p>Abonnement</p>
                </div>
                <div className="row-2 fl_row fl-1 jc_s">
                  <p>Statut</p>
                  <p>Montant</p>
                  <p>Date de?????????</p>
                  <p>Date de ???????????</p>
                </div>
              </div>
            </div>
          </div>

          <div className="table-elements fl_col gp16">
            <TableCardType type="expired">
              <TableUserCard
                title={'FIOEAT Mobile'}
                type={'expired'}
                total="9.00"
                prefix="€"
                dateStart="02 Sep 2024"
                dateEnd="02 Sep 2025"
                onClickActivate={() => alert("onClickSubscribe")}
              />
              <TableUserCard
                title={'FIOEAT Mobile'}
                type={'expired'}
                total="9.00"
                prefix="€"
                dateStart="02 Sep 2024"
                dateEnd="02 Sep 2025"
              />
            </TableCardType>
            <TableCardType type="activated">
              <TableUserCard
                title={'FIOEAT Mobile'}
                type={'activated'}
                total="9.00"
                prefix="€"
                dateStart="02 Sep 2024"
                dateEnd="02 Sep 2025"
              />
            </TableCardType>
            <TableCardType type='canceled'>
              <TableUserCard
                title={'FIOEAT Mobile'}
                type={'canceled'}
                total="9.00"
                prefix="€"
                dateStart="02 Sep 2024"
                dateEnd="02 Sep 2025"
              />
            </TableCardType>
          </div>
        </CardContainer>
      )}

      {/* <Pagination
        totalPagesNumbers={ListAbonnement?.pagination?.nbPage}
        nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleNextClick(currentPage)
        }
        prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handlePreviousClick(currentPage)
        }
        currentPage={currentPage}
        totalPages={ListAbonnement?.pagination?.nbPage}
        handlePageChange={handlePageChange}
      /> */}
    </PageTable>
  );
}
