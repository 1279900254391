import React, { ChangeEvent, useState } from 'react';
import EditItemIcon from '../../assets/icons/EditItemIconCatalogue.svg';
import PlaceHolderImg from '../../assets/icons/placeholderImage.svg';
import { isValidURL } from '../../utils/catalogueHelper/EmptyHelper';
import DeleteButton from '../../assets/icons/removeImg.svg';

/**
 * ModalElementImage component description.
 *
 * @ModalElementImage
 *
 */

interface ModalElementImageProps {
  itemImg?: string;
  editItem?: (event: React.MouseEvent<HTMLImageElement>) => void;
  onClickRemove?: () => void;
}

const ModalElementImage: React.FC<ModalElementImageProps> = ({
  itemImg,
  editItem,
  onClickRemove,
}) => {
  return (
    <div className="ItemImgBox">
      <img
        className="itemImg"
        src={isValidURL(itemImg ?? '') ? itemImg : PlaceHolderImg}
        alt=""
      />
      <div>
        <img
          src={EditItemIcon}
          alt=""
          className="EditItem"
          onClick={editItem}
        />
        {/* {isValidURL(itemImg ?? '') && (
          <img
            className="RemovedItem"
            src={DeleteButton}
            alt=""
            onClick={onClickRemove}
          />
        )} */}
      </div>
    </div>
  );
};

export default ModalElementImage;
