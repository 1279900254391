import React, { useEffect } from 'react';
const ListeDesEntreprisesContainer = React.lazy(
  () =>
    import(
      '../../../container/Etablissement/TabsCompany/ListeDesEntreprisesContainer'
    ),
);
import { OpenTab } from '../../../services/tabs/OpenTab';
import tabs_list from '../../../data/tabsList';
import { getAllEntreprise } from '../../../store/actions/actions';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { usePagination } from './usePagination';

export default function ListeDesEntreprises() {
  const dispatch = useDispatch();
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const companies_list = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.entreprises,
  );
  const totalPagesNumbers = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.totalPagesNumbers,
  );

  const initialData = {
    currentPage: 1,
    itemsPerPage: 11,
  };

  const {
    currentPage,
    itemsPerPage,
    handlePreviousClick,
    handleNextClick,
    handlePageChange,
  } = usePagination(initialData);

  useEffect(() => {
    const data = {
      paginationModel: { page: currentPage, pageSize: itemsPerPage },
    };

    dispatch(getAllEntreprise(data));
  }, [currentPage]);

  const handleClickOpenAddCompany = () => {
    OpenTab({
      key: tabs_list[3].tabContent.key,
      label: tabs_list[3].name,
      tabs: open_tabs,
      dispatch,
    });
  };

  const handleClickOpenCompanyDetails = (id: number, name: string) => {
    
    OpenTab({
      key: tabs_list[4].tabContent.key,
      label: tabs_list[4].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
      companyId:''+id
    });
  };
  const onClickGlossaire = (id: number, name: string) => {
    OpenTab({
      key: tabs_list[4].tabContent.key,
      label: tabs_list[4].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
      miniTab: 'catalogue',
    });
  };

  return (
    <ListeDesEntreprisesContainer
      list={companies_list}
      handleClickOpenAddCompany={handleClickOpenAddCompany}
      handleClickOpenCompanyDetails={handleClickOpenCompanyDetails}
      onClickGlossaire={onClickGlossaire}
      handleNextClick={handleNextClick}
      handlePreviousClick={handlePreviousClick}
      currentPage={currentPage}
      totalPages={totalPagesNumbers}
      handlePageChange={handlePageChange}
    />
  );
}
