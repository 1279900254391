import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetVerifResponse } from '../../../store/actions/actions';
import { IsTabFormDirty } from '../../../services/tabs/IsTabFormDirty';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import tabs_list from '../../../data/tabsList';

const useFormValidation = () => {
  const dispatch = useDispatch();
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );

  useEffect(() => {
    if (!unsavedChangesData) return;
    const tabFormDirtyIndex = IsTabFormDirty({
      array: unsavedChangesData,
      key: tabs_list[1].name,
    });

    const isDirty = tabFormDirtyIndex !== -1;
    if (!isDirty) dispatch(resetVerifResponse());
  }, [unsavedChangesData]);
};

export default useFormValidation;
