import React from 'react';
import ModalElementName from '../ModalElementName';
import ModalFamilyElement from '../ModalFamilyElement';
import ModalSubFamilyElement from '../ModalSubFamilyElement';
import { ModalProps } from '../../../models/services/module.modals';
export default function FamilyModalController(props: ModalProps) {
  let modalContentBody;
  let modalContentFooter;
  switch (props.familytype) {
    case 'add':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full jc_c">
          <ModalElementName
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_fe">
          <div className="fl_row gp12 w-full">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                onClick={props.handleSave}
                className={`btn_cat fl-1 ${!props.itemName ? 'btn-disabledGrey' : props.itemName && props.itemName?.trim()?.length < 3 ? 'btn-lightGrey' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;
    case 'choose':
      modalContentBody = (
        <div className="body-wrapper fl_row w-full jc_c">
          <div className="select-menu-wrapper fl_col gp16 w-full">
            {props.chooseArray &&
              props.chooseArray?.map((c) => (
                <button
                  key={c.value}
                  className="select-menu-family pd16 rd12 txt_left fl_col gp4"
                  onClick={() => props.onChoose && props.onChoose(c.value)}
                >
                  <p className="p16 txt_left w-500 YankeesBlue-txt">
                    {c.label}
                  </p>
                  {/*  
                          <p className="p16 Charcoal-txt w-300">
                            Ex. : Text descriptif.
                          </p>*/}
                </button>
              ))}
          </div>
        </div>
      );
      modalContentFooter = <div className="wrapperFooter w-full jc_fe"></div>;
      break;
    case 'edit':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full jc_c">
          <ModalElementName
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_fe">
          <div className="fl_row gp12 w-full">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                onClick={props.handleSave}
                className={`btn_cat fl-1 ${!props.itemName ? 'btn-disabledGrey' : props.itemName && props.itemName?.trim()?.length < 3 ? 'btn-lightGrey' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;

    case 'addsub':
      modalContentBody = (
        <div className="body-wrapper fl_col gp20 w-full jc_c ai_c">
          <div className="fl_col fl-1" style={{ width: 200 }}>
            {/* <SelectInput
                      onChange={onSelectChange}
                      options={options ? options : []}
            ></SelectInput> */}
          </div>
          <ModalFamilyElement
            placehlolderOption={props.placehlolderOption}
            dropDownDisabled={props.dropDownDisabled}
            titleOption={props.titleOption}
            options={props.options}
            onChange={props.onSelect}
          />
          <ModalSubFamilyElement
            ModalSubFamilyLabel={props.ModalSubFamilyLabel}
            onChange={props.onChange}
            valueinp={props.valueinp}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_fe">
          <div className="fl_row gp12 w-full">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={
                  !props.itemName ||
                  !props.valueinp ||
                  props.valueinp?.length < 3
                }
                onClick={props.handleSave}
                className={`btn_cat fl-1 ${
                  !props.valueinp
                    ? 'btn-disabledGrey'
                    : !props.itemName ||
                        (props.valueinp && parseInt(props.valueinp) == -1)
                      ? 'btn-disabledGrey'
                      : (props.valueinp && props.valueinp?.length < 3) ||
                          !props.itemName
                        ? 'btn-disabledGrey'
                        : 'btn-primary'
                }`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;

    default:
      modalContentBody = null;
      modalContentFooter = null;
  }
  return { modalContentBody, modalContentFooter };
}
