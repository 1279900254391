import { languageData } from '../../../i18n';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';

export const validateFiscalInfoEtablissement = (
  fiscalInformation: serviceEtablissementModels.fiscalInformation,
) => {
  const errors = {
    siret_error: false,
    siret_error_msg: '',
    tva_error: false,
    tva_error_msg: '',
    naf_error: false,
    naf_error_msg: '',
  };
  if (
    !fiscalInformation.sireT_code ||
    fiscalInformation.sireT_code === '' ||
    (fiscalInformation.sireT_code && fiscalInformation.sireT_code.length > 14)
  ) {
    errors.siret_error = true;
    errors.siret_error_msg =
      fiscalInformation.sireT_code === '' || !fiscalInformation.sireT_code
        ? languageData.global.Form.errorMsg.requiredFieldMissing
        : languageData.global.Form.errorMsg.siretLength;
  }

  if (
    !fiscalInformation.vaT_Intracommunity ||
    fiscalInformation.vaT_Intracommunity === ''
  ) {
    errors.tva_error = true;
    errors.tva_error_msg =
      languageData.global.Form.errorMsg.requiredFieldMissing;
  }

  if (fiscalInformation.naF_code_id === 0 || !fiscalInformation.naF_code_id) {
    errors.naf_error = true;
    errors.naf_error_msg =
      languageData.global.Form.errorMsg.requiredFieldMissing;
  }

  return errors;
};
