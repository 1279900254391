import React, { ChangeEvent } from 'react';
import CheckBox from '../Catalogue/Checkbox';
import { languageData } from '../../i18n';

/**
 * ModalElementName component description.
 *
 * @ModalElementName
 *
 */

interface ModalElementNameProps {
  itemNameLabel?: string;
  itemName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  tooltip?: string;
  onSelectAsDefault?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDefault?: boolean;
  hideDefault?: boolean;
}

const ModalElementName: React.FC<ModalElementNameProps> = ({
  itemNameLabel,
  itemName,
  onChange,
  placeholder,
  tooltip,
  onSelectAsDefault,
  isDefault,
  hideDefault,
}) => {
  return (
    <div title={tooltip} className="ItemName fl_col fl-1">
      <label
        title={tooltip}
        className="p12 w-500 DarkBlueGray-txt"
        htmlFor="ItemName"
      >
        {itemNameLabel} *
      </label>
      <input
        autoComplete="off"
        title={tooltip}
        placeholder={placeholder}
        pattern="[1-6]"
        maxLength={25}
        id="ItemName"
        type="text"
        onChange={onChange}
        defaultValue={itemName}
      />

      {(!hideDefault) && (
        <div className="pd20-t">
          <CheckBox
            defaultValue={isDefault}
            label={languageData.fr.Modal.df_tva}
            onChange={onSelectAsDefault}
          />
        </div>
      )}
    </div>
  );
};

export default ModalElementName;
