import { serviceEtablissementModels } from '../../models/services/module.etablissement';
import axiosInstance, { BaseUrl, authUploadAxios } from './apiGate';
interface bodyRequest {
  designation: string;
  description: string;
  totalHT: number;
  totalTTC: number;
  vat: number;
  intervalleId: number;
}
const etablissementApi = {
  addEtablissement: async (body: serviceEtablissementModels.Etablissement) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/addEstablishement?companyId=${body.companyId}`,
        body,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAllEtablissements: async (
    body: servicePaginationModels.PaginationModelType,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListCompanysEstablishement?page=${body.paginationModel.page}&pageSize=${body.paginationModel.pageSize}&companyId=${body.companyId}&claimType=Etablissement&module=Géneral.Affichage`,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getEtablissementByIdInfos: async (id: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getInfosEstablishement?establishementId=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getEtablissementByIdSheet: async (id: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getEstablishementSheet?establishementId=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getEtablissementFinancialInfos: async (id: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getEstablishementFinancialInfos?establishementId=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  editEtablissementFinancialInfos: async (
    body: serviceEtablissementModels.financialInformation,
  ) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl +
          `/v1/updateFinancialInfosEstablishement?establishementId=${body.id}`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  editEtablissementGenaralInfos: async (
    body: serviceEtablissementModels.generalInformation,
  ) => {
    try {
      const formData = new FormData();
      formData.append('email', body.email ?? '');
      formData.append(
        'fixed_line_telephone_number',
        body.fixed_line_telephone_number ?? '',
      );
      formData.append('logo', body.logo ?? '');
      formData.append(
        'mobile_telephone_number',
        body.mobile_telephone_number ?? '',
      );
      const response = await authUploadAxios.put(
        BaseUrl +
          `/v1/updateGeneralInfosEstablishement?establishementId=${body.id}`,
        formData,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSyncEtablissement: async (establishementId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/synchronizeEstablishementMachines?establishementId=${establishementId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getListMachine: async (
    establishementId: number,
    page?: number,
    pageSize?: number,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListMachines?establishementId=${establishementId}&page=${page}&pageSize=${pageSize}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getListMachineSuper: async (establishementId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/listMachinesTypeGroupBySuperType?establishementId=${establishementId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addMachineTypeSuper: async (
    payload: serviceEtablissementModels.IaddMachineTypeSuperPaylode,
  ) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/affectMachineToTypes`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  filteredListEtablissement: async (payload: any) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl +
          `/v1/getListEstablishements?claimType=${'Etablissement'}&module=${'Géneral.Affichage'}`,
        { ...payload.body },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getListIntervallesAbonnement: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getListIntervallesAbonnement`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getAbonnementById: async (abonnementId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getAbonnementById?abonnementId=${abonnementId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  addAbonnement: async (establishementId: number, payload: bodyRequest) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/addAbonnement?establishementId=${establishementId}`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  ListAbonnement: async (
    establishementId: number,
    page?: number,
    pageSize?: number,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListAbonnementsSuperAdmin?establishementId=${establishementId}&page=${page}&pageSize=${pageSize}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateAbonnement: async (abonnementId: number, payload: bodyRequest) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/updateAbonnement?abonnementId=${abonnementId}`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getListStatutsEstablishements: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getListStatutsEstablishements`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStatusAbonnment: async (idAbonnement: number, data: boolean) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/updateStatusAbonnement?idAbonnement=${idAbonnement}`,
        data,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /********** **********/
  //Get List facture

  getListFacture: async (
    idEstablishement: number,
    page?: number,
    pageSize?: number,
  ) => {
    try {
      // const response = await axiosInstance.get(
      //   BaseUrl +
      //     `/v1/getListFacture?establishmentId=${establishmentId}&page=${page}&pageSize=${pageSize}`,
      // );
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getListFactures?idEstablishement=${idEstablishement}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**************** *****/
  //get facture details
  getFactureById: async (idFacture: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getDetailsFacture?idFacture=${idFacture}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default etablissementApi;
