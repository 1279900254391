import { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import CloseBtn from '../../assets/icons/CloseBtnBleuIcon.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MarketIconGrey from '../../assets/icons/MarketIconGrey.svg';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
import SaveIconWhitePrimary from '../../assets/icons/SaveButtonPrimary.svg';
import SaveIconWhite from '../../assets/icons/SaveIconWhite.svg';
import DeleteButton from '../../assets/icons/delete-Button.svg';
import BackGreyDarkIcon from '../../assets/icons/flip-backward.svg';
import CartBox from '../../components/Carts/CartBox';
import CartBoxSkeleton from '../../components/Carts/CartBoxSkeleton';
import CartItem from '../../components/Carts/CartItem';
import CarteMenuStep from '../../components/Carts/CarteMenuStep';
import FieldName from '../../components/Catalogue/FieldName';
import Modal from '../../components/Modal/Catalogue/CatalogueModal';
import ModalAddCarteSettings from '../../components/Modal/ModalAddCarteSettings';
import tabs_list from '../../data/tabsList';
import { languageData } from '../../i18n';
import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import {
  CardPostSuccess,
  Channel,
  carteConfig,
  filtredPvConfig,
  modalData,
  resConfig,
} from '../../models/services/module.cart';
import {
  CompositionMenu,
  LevelArticle,
  Menu,
  MenuLevel,
  MenuObjectPayload,
} from '../../models/services/module.catalogue';
import {
  Category,
  CategorySingle,
} from '../../models/services/module.category';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { catalogueApi } from '../../services/apiHandlers/catalogueApi';
import { CloseTab } from '../../services/tabs/CloseTab';
import { fetchCompanyMachineTypesRequest } from '../../store/actions/actions';
import {
  dragUnDragCartCat,
  getCardById,
  getFiltredPvList,
  getLevelById,
  postAssignPv,
  startGetCategories,
  startGetCategoryByid,
  startGetMenuByID,
  updateCart,
  updateCategoriesCard,
} from '../../store/actions/catalogues/actions';
import {
  NotificationErrorProvider,
  NotificationSuccessProvider,
} from '../../utils/Notificationprovider';
import SideBarCart from './SideBarCart';
interface modalDataLcl {
  visible: boolean;
  title?: string;
  actionTarget?: string;
  actionTargetId?: string;
}
interface pointOfSaleMachines {
  id: number;
  pointOfSaleId?: number;
  typeMachineId?: number;
  cardId: string;
  posId?: number;
  typeMachinesId?: number;
}
interface pointOfSaleMachine_Cards {
  id: number;
  pointOfSaleId?: number;
  typeMachineId?: number;
  cardId?: string;
}
export default function CartContainer() {
  const dispatch = useDispatch();
  const [showLeftSide, setShowLeftSide] = useState(true);
  const [category, setcategory] = useState<Partial<Category>>({});
  const [Card, setcard] = useState<Partial<carteConfig>>({});
  const [menu, setmenu] = useState<Partial<Menu>>();
  const [menuDetails, setmenuDetails] = useState<Partial<MenuObjectPayload>>();
  const [selectedLevel, setselectedLevel] = useState<Partial<MenuLevel>>();
  const [levelsArticles, setlevelsArticles] = useState<LevelArticle[]>();
  const [loading, setloading] = useState(false);
  const [loadingByid, setloadingbId] = useState(true);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [categoriesCart, setcategoriesCart] = useState<CategorySingle[]>([]);
  const [cartChanges, setcartChanges] = useState(0);
  const [newCartName, setnewCartName] = useState<string>();
  const [userWillExist, setuserWillExist] = useState(false);
  const [Machines, setMachines] = useState<
    serviceCampanyMachineTypeModels.MachineType[]
  >([]);
  const [settingPv, setsettingPv] = useState<modalDataLcl>({
    visible: false,
    title: '',
  });
  interface menusView {
    type: string;
    data: Menu[];
  }
  const [menus, setmenus] = useState<menusView[]>([]);
  const [paths, setpaths] = useState<string[]>([]);
  const [listPv, setlistPv] = useState<Channel[]>();
  const [selectedCategory, setselectedCategory] = useState<
    Partial<CategorySingle>
  >({});
  /* const categoriesCart = useSelector(
    (state: { categoryCart: { categoriesCart: CategorySingle[] } }) =>
      state.categoryCart.categoriesCart,
  ); */
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const activeTabName = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.activeTab,
  );
  const { state } = useLocation();
  const card: CardPostSuccess = state?.card;
  useEffect(() => {
    if (!loadingByid) fetchCategories();
  }, [loadingByid]);
  console.log({ Machines });
  const fetchMachines = async () => {
    const id = await localStorage.getItem('idCompany');
    const params = {
      companyId: parseInt(id ?? ''),
    };
    dispatch(
      fetchCompanyMachineTypesRequest(params, (_) => {
        console.log('Machines2', _);
        setMachines(
          _.map((m) => ({
            ...m,
          })),
        );
      }),
    );
  };
  useEffect(() => {
    if (settingPv.visible) fetchMachines();
  }, [settingPv]);
  useEffect(() => {
    if (card && !Card.id) {
      setcard(card as carteConfig);
    }
  }, [card]);
  const HandleleftSide = () => {
    setShowLeftSide((prev) => !prev);
  };
  const HandBackSide = () => {
    let m = menus;

    if (m.length - 1 > 0) m.pop();
    let p = paths;
    p.pop();
    setmenus([...m]);
    setpaths([...p]);

    setlevelsArticles([]);
    setmenuDetails(undefined);
    //setcategory({});
  };
  const clearBackSide = () => {
    setmenus([]);
    //setpaths([]);
    setlevelsArticles([]);
    setmenuDetails(undefined);
    //setcategory({});
  };
  const [categories, setcategories] = useState<CategorySingle[]>([]);
  const fetchCategories = () => {
    dispatch(
      startGetCategories((_) => {
        setcategories(
          withDisabledCategoryDragged(_ as CategorySingle[], categoriesCart),
        );
      }, true),
    );
  };
  useEffect(() => {
    if (categoriesCart) {
      setcategories([
        ...withDisabledCategoryDragged(categories, categoriesCart),
      ]);
    }
  }, [categoriesCart, loadingByid, category, cartChanges]);

  const onUnDragCategory = (Category: CategorySingle) => {
    Category.id == selectedCategory.id ? setcategory({}) : null;
    let tt =
      categoriesCart.filter(
        (category) =>
          (category?.idCategory ?? category.id) !==
          (Category.idCategory ?? Category.id),
      ) ?? [];
    setcategoriesCart(tt);
  };
  const withDisabledCategoryDragged = (
    menus: CategorySingle[],
    menuDragged: CategorySingle[],
  ): CategorySingle[] =>
    menus?.map((l) => {
      const disabledSet = new Set(
        menuDragged?.map((l) => l.idCategory ?? l.id),
      );
      return {
        ...l,
        selected: false,
        disabled: disabledSet.has(l.idCategory ?? l.id),
      } as CategorySingle;
    });
  const OnSelectCategory = (c: CategorySingle) => {
    clearBackSide();
    setselectedCategory(c);
    handleFetchCategoryByiD(c);
    setpaths([c.name ?? '']);
  };
  const handleFetchCategoryByiD = (c: CategorySingle) => {
    setloading(true);
    dispatch(
      startGetCategoryByid(
        c.idCategory ? '' + c.idCategory : '' + c.id,
        (_) => {
          setloading(false);
          setcategory(_ as Category);
          let x = [
            ...(_?.categoryArticles.map((_) => ({
              ..._,
              isComposition: true,
            })) ?? []),
            ...(_?.categoryMenus ?? []),
          ];
          x.sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0)) as Menu[];
          setmenus((prev) => [...prev, { type: 'submenu', data: x as Menu[] }]);
        },
      ),
    );
  };
  const onSelectMenuFromMenu = (menu: Menu) => {
    setmenus((prev) => [
      ...prev,
      { type: 'submenu', data: menuDetails?.menuSubMenus as Menu[] },
    ]);
    setpaths((prev) => [...prev, menu.designation]);
    onSelectMenu(menu);
  };
  const onSelectMenu = (menu: Menu) => {
    setmenu(menu);
    setpaths((prev) => [...prev, menu.designation ?? menu.name ?? '']);

    // setShowLeftSide(false);
    //  setmenuDetails(undefined);
    setlevelsArticles(undefined);
    setselectedLevel(undefined);
    setloading(true);
    dispatch(
      startGetMenuByID(
        menu.idSubMenu ? '' + menu.idSubMenu : '' + menu.idMenu,
        (_) => {
          setmenuDetails(_);
          if (_?.menuSubMenus && _?.menuSubMenus.length > 0) {
            setmenus((prev) => [
              ...prev,
              { type: 'submenu', data: _?.menuSubMenus as Menu[] },
            ]);
          }
          if (_?.menuLevels && _?.menuLevels?.length > 0)
            onSelectLevel(_?.menuLevels[0] as MenuLevel);
          setloading(!true);
        },
      ),
    );
  };
  const onSelectLevel = (level: MenuLevel) => {
    setselectedLevel(level);
    startGetLevelById(level);
  };
  const startGetLevelById = (selectedLevel: MenuLevel) => {
    dispatch(
      getLevelById('' + selectedLevel.idLevel, (_) => {
        if (menus[menus.length - 1].type != 'level')
          setmenus((prev) => [...prev, { type: 'level', data: [] }]);
        setlevelsArticles(_?.levelArticles as LevelArticle[]);
      }),
    );
  };

  const onSelectMenuCategory = (menu: Menu) => {
    setmenus((prev) => [
      ...prev,
      { type: 'submenu', data: category.categoryMenus as Menu[] },
    ]);
    setpaths((prev) => [...prev, menu.designation]);

    onSelectMenu(menu);
  };
  const handleOpenPvSetting = (title: string, actionData?: filtredPvConfig) => {
    setsettingPv({ visible: true, title: title, ...actionData });
  };
  const handleClsePvSetting = () => {
    setsettingPv({ visible: false, title: '' });
    setlistPv([]);
    // fetchMachines();
  };
  const onGetListPv = (config: filtredPvConfig, title: string) => {
    handleOpenPvSetting(title, config);
    dispatch(
      getFiltredPvList({ ...config, cardId: '' + Card.id }, (_) => {
        setlistPv(_.map((p) => ({ ...p })));
      }),
    );
  };
  const onDragEndSubMenu = (result: DropResult) => {
    if (!result.destination) return;
    const newItems = Array.from(categoriesCart);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    console.log({ newItems });
    setcategoriesCart(newItems);
  };

  const handlePvSelection = (
    isChecked?: boolean,
    all?: boolean,
    chId?: number,
  ) => {
    setlistPv((prev) =>
      prev?.map((item) =>
        all
          ? {
              ...item,
              assigned: isChecked,
            }
          : item.id == chId
            ? {
                ...item,
                assigned: isChecked,
              }
            : { ...item },
      ),
    );
  };
  const handleMachineSelection = (
    isChecked?: boolean,

    chId?: number,
    pvId?: number,
  ) => {
    setlistPv((prev) =>
      prev?.map((item) =>
        item.id == pvId
          ? {
              ...item,
              typeMachines: item.typeMachines?.map((m) =>
                m.id == chId ? { ...m, assigned: isChecked ?? false } : m,
              ),
            }
          : { ...item },
      ),
    );
  };
  const onSubmitAssingPV = () => {
    const pvs = listPv?.filter((_) => _.assigned) as Channel[];

    const machs: number[] = Machines.filter((_) => _.assigned).map((m) => m.id);
    dispatch(
      postAssignPv(
        {
          actionTarget: '' + settingPv.actionTarget,
          actionTargetId: '' + settingPv.actionTargetId,
          cardId: '' + Card.id,
          pointOfSaleMachines: pvs?.flatMap((p) =>
            p.typeMachines
              ?.filter((pm) => pm.assigned)
              ?.map((m) => ({
                id: 0,
                posId: p.id as number,
                typeMachinesId: m.id as number,
                cardId: '' + Card.id,
              })),
          ) as pointOfSaleMachines[],
          pointOfSaleMachine_Cards: pvs?.flatMap((p) =>
            p.typeMachines
              ?.filter((pm) => pm.assigned)
              ?.map((m) => ({
                id: 0,
                pointOfSaleId: p.id as number,
                typeMachineId: m.id as number,
                cardId: '' + Card.id,
              })),
          ) as pointOfSaleMachine_Cards[],
        },
        () => {
          NotificationSuccessProvider('add', 'Points de ventes et machines');
          setsettingPv({ visible: false });
          setlistPv([]);
        },
      ),
    );
  };

  const dragUnDrag = (config: modalData, _: (ob: resConfig[]) => void) => {
    dispatch(
      dragUnDragCartCat({ ...config }, (configRes) => {
        try {
          _(configRes as resConfig[]);
        } catch (error) {
          console.log(error);
        }
      }),
    );
  };
  const getCardByParamsId = () => {
    setloadingbId(true);
    dispatch(
      getCardById(('' + Card.id) as string, (_) => {
        setnewCartName(Card.designation);
        setloadingbId(false);
        setcategoriesCart(
          _.cardCategories.map((c) => ({
            ...c,
            imageUrl: c.url,
            designation: c.name,
          })) as CategorySingle[],
        );
        setcartChanges(cartChanges + 1);
      }),
    );
  };
  useEffect(() => {
    if (Card.id) {
      getCardByParamsId();
    }
  }, [Card]);
  const onUNDragCategory = (Category: CategorySingle) => {
    dragUnDrag(
      {
        actionTarget: 'UNDRAG',
        categoryIds: [(Category.idCategory ?? Category.categoryCartId) + ''],
        cardId: Card.id + '',
      },
      (_) => {
        onUnDragCategory(Category);
      },
    );
  };
  const onUserWant = () => {
    setshowDeleteModal(true);
  };
  const onUserWantExist = () => {
    setuserWillExist(true);
  };
  const onUpdateCarte = async () => {
    try {
      let res = await catalogueApi.postCart({
        id: Card.id,
        designation: newCartName,
      });

      NotificationSuccessProvider('edit', 'Carte');
      closeTab();
      dispatch(updateCart(true));
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const handleCancelDelete = () => {
    setshowDeleteModal(false);
  };
  const onDelete = async () => {
    try {
      await catalogueApi.deleteCard(Card.id ? '' + Card.id : '');
      NotificationSuccessProvider('delete', 'Carte');
      dispatch(updateCart(true));
      closeTab();
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const changeCartName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let n = event.target.value;
    setnewCartName(n);
  };
  const closeTab = () => {
    CloseTab({
      key: activeTabName,
      open_tabs,
      name: tabs_list[13].name,
      dispatch,
    });
  };
  const cancelQuit = () => {
    setuserWillExist(false);
  };
  console.log({ menusXX: menus, paths });
  return (
    <div className="CartContainer h-full">
      <div className="container-wrapper h-full">
        <div className="container-header fl_row ai_c jc_s pd8-t-b pd16-r-l cart-name-header">
          <div className="label-name">
            <FieldName
              onChange={changeCartName}
              defaultValue={Card.designation}
              label={languageData.fr.card.intitule}
              placeholder={languageData.fr.card.placeholder}
              onClickEdit={() => alert('')}
            />
          </div>
          <div className="cart-tools fl_row gp10">
            {/* {!activeTabContent.isEtablishement && (
              <img
                src={MarketIconGrey}
                alt=""
                onClick={() =>
                  onGetListPv(
                    {
                      actionTarget: 'CARD',
                      actionTargetId: '' + Card.id,
                    },
                    Card.designation ?? '',
                  )
                }
              />
            )} */}
            {!activeTabContent.isEtablishement && (
              <img src={DeleteButton} alt="" onClick={onUserWant} />
            )}
            {!activeTabContent.isEtablishement && (
              <img
                style={{
                  opacity: newCartName != Card.designation ? 1 : 0.5,
                  cursor:
                    newCartName != Card.designation ? 'pointer' : 'not-allowed',
                }}
                src={
                  newCartName != Card.designation
                    ? SaveIconWhitePrimary
                    : SaveIconWhite
                }
                alt=""
                onClick={
                  newCartName != Card.designation ? onUpdateCarte : undefined
                }
              />
            )}
            <img src={CloseBtn} alt="" onClick={onUserWantExist} />
          </div>
        </div>
        <div className="container-body pd8 h-full">
          <div className="body-wrapper fl_row gp8 h-full">
            <div className="fl_row gp20 category-wrapper">
              {showLeftSide &&
                (Card.id ? !loadingByid : true) &&
                !activeTabContent.isEtablishement && (
                  <div className="category-list">
                    <SideBarCart
                      card={Card}
                      onDrag={dragUnDrag}
                      setcategoriesCart={(_c) => {
                        setcategoriesCart([
                          ...categoriesCart,
                          ...(_c as CategorySingle[]),
                        ]);
                      }}
                      showLeftSide={showLeftSide}
                      onHandleSider={setShowLeftSide}
                      categories={categories}
                    ></SideBarCart>
                  </div>
                )}
              <div className="fl_col gp20">
                <div className="showLeftSide" onClick={HandleleftSide}>
                  <img src={EditGreyIcon} alt="" />
                  <p className="p16 w-500">Catégories</p>
                </div>
                <div className="category-items listing-cartes fl_col gp10">
                  {categoriesCart.length == 0 ? (
                    <div className="empty-list fl_col gp4">
                      {/* <p className="p16 w-500 label-item">Catégories</p> */}
                      <p className="p16 w-500">Acune catégorie ajoutée</p>
                      <p className="p12">
                        Ajoutez une ou plusieurs catégories de la liste pour
                        composer votre carte.
                      </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* {!showLeftSide && (
                )} */}

                  <DragDropContext onDragEnd={onDragEndSubMenu}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="fl_col gp10 droppable-drag"
                        >
                          {true &&
                            categoriesCart?.map((_, __) => (
                              <Draggable
                                key={__}
                                draggableId={`${__}`}
                                index={__}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <CartItem
                                      hideAllOption={
                                        activeTabContent.isEtablishement
                                      }
                                      selected={selectedCategory.id == _.id}
                                      onSelect={() => OnSelectCategory(_)}
                                      name={_.designation}
                                      img={_.imageUrl}
                                      OnClickRemove={
                                        !activeTabContent.isEtablishement
                                          ? () => onUNDragCategory(_)
                                          : undefined
                                      }
                                      OnClickMarkets={() =>
                                        onGetListPv(
                                          {
                                            actionTarget: 'CATEGORY',
                                            actionTargetId: _.idCategory
                                              ? '' + _.id
                                              : '' + _.categoryCartId,
                                            navigationId: Card.id + '',
                                          },
                                          _.designation,
                                        )
                                      }
                                      OnClickSettings={() =>
                                        alert('OnClickRemove')
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>

            <div className="right-side pd16-r-l pd8-t-b">
              {paths.length > 0 && (
                <div className="path-menu fl_row gp16 ai_c pd8-t-b ">
                  <img
                    className="c-p"
                    src={BackGreyDarkIcon}
                    alt=""
                    onClick={HandBackSide}
                  />
                  <div className="fl_row gp16 ai_c">
                    {paths.map((m, i) => (
                      <>
                        <p className="p14 OxfordBlue02-txt">{m}</p>
                        {i < paths.length - 1 && (
                          <p className="p14 OxfordBlue02-txt">{' > '}</p>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              )}
              <div className="side-wrapper fl_row gp20">
                <div className="fl_col gp20">
                  <div className="fl_row gp8 ">
                    <div
                      className={`right-side-grid cart-items-listing   ${showLeftSide ? '' : 'extend-block'}`}
                    >
                      {!loading &&
                        menus.length > 0 &&
                        menus[menus.length - 1]?.data?.map((_, __) => (
                          <CartItem
                            className="CartBox"
                            hideAllOption={activeTabContent.isEtablishement}
                            selected={menu?.id == _.id}
                            onSelect={() =>
                              !_?.isComposition && onSelectMenu(_)
                            }
                            name={_.name ?? _.designation}
                            img={_.url}
                            OnClickMarkets={() =>
                              onGetListPv(
                                {
                                  actionTarget:
                                    menus.length >= 2 ? 'SUBMENU' : 'MENU',
                                  actionTargetId: '' + _.id,
                                  navigationId: selectedCategory.idCategory
                                    ? '' + selectedCategory.id
                                    : selectedCategory.categoryCartId + '',
                                },
                                _.designation,
                              )
                            }
                            OnClickSettings={() => alert('OnClickRemove')}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className="fl_col gp20 ">
                  <div className="fl_row gp8 ">
                    {!loading &&
                      menus[menus.length - 1]?.type == 'level' &&
                      menuDetails?.menuLevels &&
                      menuDetails?.menuLevels?.length > 0 && (
                        <div className="left-side-grid fl_col gp8 pd8 rd12">
                          <p className="p14 label-step txt_center">Etapes</p>
                          {menuDetails?.menuLevels.map((_, __) => (
                            <CarteMenuStep
                              hideAllOption={activeTabContent.isEtablishement}
                              onClick={() => onSelectLevel(_)}
                              OnClickMarkets={() =>
                                onGetListPv(
                                  {
                                    actionTarget: 'LEVEL',
                                    actionTargetId: '' + _.id,
                                    navigationId: menu?.id + '',
                                  },
                                  _.designation ?? '',
                                )
                              }
                              title={_.designation}
                              order={__ + 1}
                              active={_.id == selectedLevel?.id}
                              totalSlot={_.maxNbrSelection}
                              checkedSlot={_.minNbrSelection ?? 0}
                            />
                          ))}
                        </div>
                      )}
                    <div className="right-side-grid cart-items-listing ">
                      {/*  {!loading &&
                        menus.length == 0 &&
                        category.categoryMenus?.map((_) => (
                          <CartBox
                            hideAllOption={activeTabContent.isEtablishement}
                            // selected={menu?.id == _.id}
                            onSelect={() => onSelectMenuCategory(_ as Menu)}
                            name={_.designation ?? ''}
                            img={_.url}
                            OnClickMarkets={() =>
                              onGetListPv(
                                {
                                  actionTarget: 'MENU',
                                  actionTargetId: '' + _.id,
                                  navigationId: selectedCategory.idCategory
                                    ? '' + selectedCategory.id
                                    : selectedCategory.categoryCartId + '',
                                },
                                _.designation ?? '',
                              )
                            }
                            OnClickSettings={() => alert('OnClickRemove')}
                          />
                        ))} */}
                      {/*  {!loading &&
                        menuDetails?.menuSubMenus?.map((_) => (
                          <CartBox
                            hideAllOption={activeTabContent.isEtablishement}
                            //  selected={menu?.id == _.id}
                            onSelect={() => onSelectMenuFromMenu(_ as Menu)}
                            name={_.name ?? ''}
                            img={_.url}
                            OnClickMarkets={() =>
                              onGetListPv(
                                {
                                  actionTarget: 'SUBMENU',
                                  actionTargetId: '' + _.id,
                                  navigationId: menu?.id + '',
                                },
                                _.name ?? '',
                              )
                            }
                            OnClickSettings={() => alert('OnClickRemove')}
                          />
                        ))} */}
                      {/*  {!loading &&
                        menus.length == 0 &&
                        category.categoryArticles?.map((_) => (
                          <CartBox
                            hideAllOption={activeTabContent.isEtablishement}
                            //  selected={selectedLevel?.id == _.id}
                            prefix="€"
                            name={_.name ?? ''}
                            img={_.url} 
                             OnClickMarkets={() =>
                              onGetListPv(
                                {
                                  actionTarget: 'CATEGORY_ARTICLE',
                                  actionTargetId: '' + _.id,
                                  navigationId:
                                    (selectedCategory?.idCategory
                                      ? selectedCategory.id
                                      : selectedCategory.categoryCartId) + '',
                                },
                                _.name ?? '',
                              )
                            } 
                          />
                    */}
                      {!loading &&
                        levelsArticles &&
                        levelsArticles?.map((_) => (
                          <CartBox
                            hideAllOption={activeTabContent.isEtablishement}
                            // selected={selectedLevel?.id == _.id}
                            prefix="€"
                            name={_.name ?? ''}
                            img={_.url}
                            /*  OnClickMarkets={() =>
                              onGetListPv(
                                {
                                  actionTarget: 'LEVEL_ARTICLE',
                                  actionTargetId: '' + _.id,
                                  navigationId: selectedLevel?.id + '',
                                },
                                _.name ?? '',
                              )
                            } */
                          />
                        ))}
                      {loading &&
                        Array.from({ length: 24 }).map((_) => (
                          <CartBoxSkeleton></CartBoxSkeleton>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {settingPv.visible && (
        <ModalAddCarteSettings
          handleMachineSelection={handleMachineSelection}
          machines={[...Machines]}
          handlePvSelection={handlePvSelection}
          channelList={listPv}
          handelclose={handleClsePvSetting}
          description={[
            'Sélectionner les points de vente ou vous souhaitez faire apparaître la carte.',
          ]}
          subtitle="Points de vente affectés"
          title={settingPv.title ?? ''}
          version={'Item'}
          type={'edit'}
          visibility={settingPv.visible}
          close
          handleSave={onSubmitAssingPV}
          maxWidth={680}
        />
      )}
      <Modal
        target="badge"
        version={'Item'}
        type={'delete'}
        title={languageData.fr.modalsCatalogues.confirmDelete}
        visibility={showDeleteModal}
        close={true}
        cancel={true}
        cancelTxt={languageData.fr.Modal.cancel}
        deleteitem={true}
        deleteitemTxt={languageData.fr.Modal.delete}
        handeldelete={onDelete}
        closeModal={handleCancelDelete}
        description={[languageData.fr.Modal.confirmDelete]}
      />
      <Modal
        closeModal={cancelQuit}
        handleSave={closeTab}
        close
        version={'Item'}
        reject
        rejectTxt={languageData.fr.modalsCatalogues.quitKey}
        cancel
        cancelTxt={languageData.fr.modalsCatalogues.quit}
        target="unsavedchanges"
        title={languageData.fr.modalsCatalogues.userExittitle}
        visibility={userWillExist}
        description={[languageData.fr.modalsCatalogues.userExitSubTitle]}
      ></Modal>
    </div>
  );
}
