import React, { ReactNode, useState } from 'react';
/**
 * CartCheckboxParentElement component description.
 *
 * @CartCheckboxParentElement
 *
 */

interface CartCheckboxParentElementProps {
  name: string;

  open?: boolean;
  children?: ReactNode;
  isChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  circle?: boolean;
  stylepurpel?: boolean;
  hideBg?: boolean;
}
const CartCheckboxParentElement: React.FC<CartCheckboxParentElementProps> = ({
  name,
  children,

  onChange,
  isChecked,
  circle,
  stylepurpel,
  hideBg,
}) => {
  const [showDropDownList, setShowDropDownList] = useState(true);
  const HandleDropDownList = () => {
    setShowDropDownList((prevState) => !prevState);
  };
  return (
    <div
      className={`CartCheckboxParentElement ${circle ? 'circle-checkbox' : ''} ${stylepurpel ? 'stylepurpel-checkbox' : ''}`}
    >
      <div
        className={`wrapper-elements fl_col gp10 ${showDropDownList ? 'ActiveList' : ''}`}
      >
        <div
          className="CheckboxParent fl_row jc_s ai_c"
          style={
            hideBg
              ? {
                  backgroundColor: 'white',
                }
              : undefined
          }
        >
          <div className="left-side fl_row gp10">
            <input
              autoComplete="off"
              onChange={onChange}
              checked={isChecked}
              type="checkbox"
              name="CheckboxParent"
              id="CheckboxParent"
            />
            {circle && <span className="circle-checkbox-shape"></span>}
            <label htmlFor="CheckboxParent DarkJungleGreen-txt p16 w-900">
              {name}
            </label>
          </div>
        </div>
        {children && showDropDownList && (
          <div className="ChildList fl_col gp10 pd20-r-l">{children}</div>
        )}
      </div>
    </div>
  );
};

export default CartCheckboxParentElement;
