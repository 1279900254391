import React, { useState } from 'react';
import AddElementIcon from '../../assets/icons/AddElementCirclePrimaryIcon.svg';
import AddElementCircleWhiteIcon from '../../assets/icons/AddElementCircleWhiteIcon.svg';
import SynchronizeIconBleu from '../../assets/icons/SynchronizeIconBleu.svg';
import ButtonElement from '../button/button';
import { languageData } from '../../i18n';
import CardContainer from '../../container/CardContainer';
import InputField from '../Input/Input';
import TrashIconRed from '../../assets/icons/TrashIconRed.svg';
import SaveIconWhite from '../../assets/icons/SaveIconTransparentWhite.svg';
import FilterIconCircleGrey from '../../assets/icons/FilterIconCircleGrey.svg';
import GlossaireIconPrimary from '../../assets/icons/GlossaireIconPrimary.svg';
import CatalogueIconPrimary from '../../assets/icons/CatalogueIconPrimary.svg';
import MultiSelect from '../Input/MultiSelect';
import ToggleSelect from '../Input/ToggleSelect';
import ToggleElement from '../Input/ToggleElement';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';

/**
 * TableHeader component description.
 *
 * @TableHeader
 *
 */

interface TableHeaderProps {
  type:
    | 'title'
    | 'add'
    | 'save'
    | 'role'
    | 'adduser'
    | 'edituser'
    | 'synch'
    | 'filter'
    | 'catalogue';
  title?: string;
  description?: string;
  slot?: number;
  textAdd?: string;
  onClickAdd?: () => void;
  onClickSave?: () => void;
  className?: string;
  labelSwitcher?: string;
  onChange?: any;
  onClickDelete?: () => void;
  ischecked?: boolean;
  hideDelete?: boolean;
  roleById?: serviceTabsModels.IRole;
  handleRoleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rolePermissions?: any;
  haserror?: boolean;
  errorMsg?: string;
  filteredAddButton?: boolean;
  isClaimsValid?: boolean;
  activeMiniTab?: string;
  fichShow?: boolean;
  claimEtablissementAjout?: boolean;
  claimEtablissementModification?: boolean;
  claimEtablissementFicheModification?: boolean;
  claimsUser?: boolean;
  claimUserFio?: boolean;
  isDefault?: boolean;
  companies_list?: serviceEntrepriseModels.companyListItem[];
  setCombineSearch?: any;
  status?: serviceEtablissmentMachineTypeModels.getList[];
  textAddMobile?: string;
  onClickGlossaire?: () => void;
  onClickOpenCart?: () => void;
  disabled?: boolean;
  isEtablishement?: boolean;
  hasPassword?: boolean;
  showSync?: boolean;
  isAdmin?: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  type,
  title,
  description,
  slot,
  onClickAdd,
  onClickSave,
  textAdd,
  className,
  labelSwitcher,
  onChange,
  onClickDelete,
  ischecked,
  hideDelete,
  rolePermissions,
  haserror,
  errorMsg,
  filteredAddButton,
  isClaimsValid,
  fichShow,
  claimEtablissementAjout,
  claimEtablissementModification,
  claimEtablissementFicheModification,
  claimsUser,
  claimUserFio,
  companies_list,
  setCombineSearch,
  status,
  textAddMobile,
  onClickGlossaire,
  onClickOpenCart,
  disabled,
  isEtablishement,
  hasPassword,
  showSync,
  isAdmin,
}) => {
  const HeaderContent = () => {
    const [selected, setSelected] = useState<string | null>(null);
    const [showSearchMode, setShowSearchMode] = useState(false);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const HandelFilterOptions = () => {
      setShowFilterOptions((prevState) => !prevState);
    };

    switch (type) {
      case 'title':
        return (
          <div className={`header-wrapper pd12-t-b fl_row ai_c rd12`}>
            {title && <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>}
          </div>
        );
        break;
      case 'save':
        return (
          <div
            className={`header-wrapper fl_row ai_c rd12 ${title ? 'jc_s' : 'jc_fe'}`}
          >
            {(isClaimsValid && fichShow) ||
            (claimEtablissementModification &&
              claimEtablissementFicheModification) ? (
              <>
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
                <ButtonElement
                  buttonStyle="btn-primary"
                  type="TextButton"
                  text={
                    disabled
                      ? languageData.global.Modal.loading
                      : languageData.global.Modal.save
                  }
                  buttonSize="large"
                  isDisabled={disabled}
                  onClick={onClickSave}
                />
              </>
            ) : null}
          </div>
        );
        break;
      case 'add':
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10">
              <div className="fl_row gp40">
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
                {slot && <span className="counter p12 w-500"> {slot}</span>}
              </div>
              {description && (
                <p className="p14 Independence-txt description-title">
                  {description}
                </p>
              )}
            </div>
            {filteredAddButton ||
            onClickAdd ||
            (claimEtablissementAjout && claimsUser) ||
            claimUserFio ? (
              <div className="btn-add gp8" onClick={onClickAdd}>
                <img src={AddElementIcon} alt="" />

                {textAdd ? (
                  <p className="">
                    <span className="textAdd p16 w-700 primary-txt">
                      {textAdd}
                    </span>{' '}
                    <span className="textAddMobile p16 w-700 primary-txt">
                      {textAddMobile}
                    </span>
                  </p>
                ) : (
                  <p className="p16 w-700 primary-txt">
                    {' '}
                    {languageData.fr.add}
                  </p>
                )}
              </div>
            ) : null}
          </div>
        );
        break;
      case 'role':
        return (
          <div className="header-wrapper fl_row ai_c rd12 jc_s">
            <div className="fl_row gp20 ai_c left-side-header">
              <InputField
                style="form"
                label={languageData.fr.SubMenuLabel.Intitule_role}
                name="name"
                value={rolePermissions?.role?.name}
                onChange={onChange}
                haserror={haserror}
                errorMsg={errorMsg}
              />
              <div className="fl_row">
                <InputField
                  style="switcher"
                  label={labelSwitcher}
                  name="active"
                  checked={rolePermissions?.role?.active}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="fl_row gp20 right-side-header">
              {hideDelete && (
                <ButtonElement
                  buttonStyle="btn-red-light"
                  type={'IconButton'}
                  text={languageData.global.supprimer}
                  buttonSize={'large'}
                  onClick={onClickDelete}
                  icon={TrashIconRed}
                  reverse
                />
              )}

              <ButtonElement
                buttonStyle="btn-primary"
                type={'TextButton'}
                text={languageData.global.Modal.save}
                buttonSize={'large'}
                onClick={onClickSave}
              />
            </div>
          </div>
        );
        break;

      case 'adduser':
        return (
          <div className="header-wrapper fl_row ai_c rd12 jc_s">
            {title && <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>}
            <div className="fl_row gp20 ai_c">
              <div className="fl_row">
                <InputField
                  style="switcher"
                  label={labelSwitcher}
                  onChange={onChange}
                  checked={ischecked}
                />
              </div>
              <ButtonElement
                buttonStyle="btn-primary"
                type={'IconButton'}
                text={languageData.global.Modal.save}
                buttonSize={'large'}
                onClick={onClickSave}
                icon={SaveIconWhite}
                reverse
              />
            </div>
          </div>
        );
      case 'edituser':
        return (
          <div className="header-wrapper fl_row ai_c rd12 jc_s">
            {title && <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>}
            <div className="fl_row gp20 ai_c">
              {hasPassword && (
                <div className="fl_row">
                  <InputField
                    style="switcher"
                    checked={ischecked}
                    label={labelSwitcher}
                    onChange={onChange}
                  />
                </div>
              )}

              <ButtonElement
                buttonStyle="btn-red-light"
                type={'IconButton'}
                text={languageData.global.supprimer}
                buttonSize={'large'}
                onClick={onClickDelete}
                icon={TrashIconRed}
                reverse
              />

              <ButtonElement
                reverse
                buttonStyle="btn-primary"
                type={'IconButton'}
                text={languageData.global.Modal.save}
                buttonSize={'large'}
                onClick={onClickSave}
                icon={SaveIconWhite}
              />
            </div>
          </div>
        );
      case 'synch':
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10">
              <div className="fl_row gp40">
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
                {slot && <span className="counter p12 w-500"> {slot}</span>}
              </div>
              {description && (
                <p className="p14 Independence-txt description-title">
                  {description}
                </p>
              )}
            </div>
            {isAdmin || showSync ? (
              <div className="btn-synch gp8" onClick={onClickAdd}>
                <img src={SynchronizeIconBleu} alt="" />
                <p className="p16 w-700 primary-txt"> Synchroniser</p>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'filter':
        const handleCheckbox = (id: string) => {
          setSelected(id);
        };
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10 pd12-t-b">
              <div className="fl_row gp40">
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
              </div>
            </div>
            <div className="filter-table fl_row ai_c gp20">
              <InputField
                type="text"
                name="name_PhoneNumber"
                onChange={onChange}
                style="filter"
                isDisabled={false}
                allowOnlyLetters
                placeholder="Rechercher..."
              />
              <MultiSelect
                onChange={onChange}
                setCombineSearch={setCombineSearch}
                name="companyId"
                companies_list={companies_list || []}
                list={[
                  'Five Pizza Original AZ',
                  'FIO Etablissement  AMEP VEEJffR AfZAZAZA',
                  'FIO Etablissement  na APEMRfRRPRp',
                  'Five Pizza Original XD',
                  'FIO Etablissement  nananananannana',
                  'FIO Etablissement  bababababbababababaff',
                  'Five Pizza Original TG',
                  'FIO Etablissement  AMEP VEEJR AZAZAZA',
                  'FIO Etablissement  na APEMfRRRPRp',
                  'Five Pizza Original NH',
                  'FIO Etablissement  nananananfffannana',
                  'FIO Etablissement  bababababbababababa',
                  'Five Pizza Original JTT',
                  'FIO Etablissement  AMEP VEEJR AfZAZAZA',
                  'FIO Etablissement  na APEMRfRRPRffp',
                  'Five Pizza Original PME',
                  'FIO Etablissement  nananananffannana',
                  'FIO Etablissement  bababababbffababababa',
                ]}
              />
              <ToggleSelect>
                {status?.map((item: any) => (
                  <ToggleElement
                    key={item?.id}
                    name="status_CompanyId"
                    label={item?.name}
                    id={String(item?.id)}
                    value={item?.id}
                    active={selected === item?.name}
                    onClick={() => handleCheckbox(item?.name)}
                    onChange={onChange}
                  />
                ))}
              </ToggleSelect>
            </div>
          </div>
        );

      case 'catalogue':
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10">
              {title && (
                <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
              )}
            </div>
            <div className="fl_row gp10">
              {!isEtablishement && (
                <ButtonElement
                  reverse
                  buttonStyle="btn-white-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.glossaire}
                  icon={GlossaireIconPrimary}
                  onClick={onClickGlossaire}
                />
              )}
              <ButtonElement
                reverse
                buttonStyle="btn-white-primary"
                type="IconButton"
                buttonSize="large"
                text={languageData.global.catalogue.stockStatus}
                icon={CatalogueIconPrimary}
              />

              {!isEtablishement && (
                <ButtonElement
                  onClick={onClickOpenCart}
                  reverse
                  buttonStyle="btn-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.CreateCart}
                  icon={AddElementCircleWhiteIcon}
                />
              )}
            </div>
          </div>
        );
    }
  };
  return (
    <>
      <CardContainer
        type="standard"
        ClassName={`${type === 'save' ? 'save-settings' : ''} ${haserror ? 'pd20-b' : ''}  ${className ? className : ''}`}
      >
        <div className={`table-header  type_${type}`}>{HeaderContent()}</div>
      </CardContainer>
    </>
  );
};

export default TableHeader;
