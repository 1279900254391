import React from 'react';

interface ToggleElementProps {
  label: string;
  id: string;
  active?: boolean;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: number | string;
}

const ToggleElement: React.FC<ToggleElementProps> = ({
  label,
  id,
  active = false,
  onClick,
  onChange,
  name,
  value,
  
}) => {
  // Function to handle custom value logic
  const handleValue = () => {
    switch (label) {
      case 'active':
        return 'Activé';
      case 'deactivated':
        return 'Désactivé';
      case 'in_approval':
        return 'Tout';
    }
  };

  return (
    <div
      className={`ToggleElement ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      <label className="p14 Independence-txt w-700 uper" htmlFor={id}>
        {handleValue() || label}
      </label>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={active}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default ToggleElement;
