import React from 'react';
import CardContainer from '../../CardContainer';
import FormCard from '../FormWizzard/FormCard';
import InputField from '../../../components/Input/Input';
import GridItems from '../../GridList/GridList';
import { languageData } from '../../../i18n';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import { getErrorEditCompany } from '../../../utils/GetErrors';
import { getErrorMessageEditCompany } from '../../../utils/GetErrorMessages';
import flagsAndNamesSeparator from '../../../utils/FlagsAndNamesSeparator';
import checkCartType from '../../../utils/checkCartType';
import {
  setEditFormDataFinancial,
  setEditFormDirty,
  setValidationRequestedEditFinancialTab,
} from '../../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import Redevances from './Redevances';
import { updateRoyalties } from '../../../utils/UpdateRedevance';
import SelectInput2 from '../../../components/Input/CountrySelect/SelectInput2';
interface propsType {
  id: number;
  isClaimsValid?: boolean;
  InformationFinancièreModifier?: boolean;
}
export default function InformationFinanciere(props: propsType) {
  const dispatch = useDispatch();
  const [phoneCode] = React.useState<string>();
  const [cardType, setCardType] = React.useState('unknown');
  const { countriesCodes, countriePhoneCodes, flags } =
    flagsAndNamesSeparator();
  const formData = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.editCompanyFormDataFinancial,
  );

  const financialData = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.financialData,
  );
  const validationRequestedFinancial = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.validationRequestedEditFinancialTab,
  );
  React.useEffect(() => {
    const firstNumber = formData?.card_number?.toString().charAt(0);

    setCardType(checkCartType(firstNumber ?? 'unknown'));
  }, [formData?.card_number]);

  React.useEffect(() => {
    dispatch(setValidationRequestedEditFinancialTab(false));
    dispatch(
      setEditFormDataFinancial({
        ...financialData,
        id: props.id,
        royalties: updateRoyalties(financialData.royalties),
      }),
    );
  }, []);

  const onSelectMobilePhone = (selectedValue: string) => {
    dispatch(setEditFormDirty(true));
    dispatch(
      setEditFormDataFinancial({
        ...formData,
        id: props.id,
        owner_mobile_number: phoneCode ? phoneCode + selectedValue : '',
      }),
    );
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setEditFormDirty(true));

    if (name === 'card_number') {
      const firstNumber = value.charAt(0);
      setCardType(checkCartType(firstNumber));
    }

    dispatch(
      setEditFormDataFinancial({
        ...formData,
        id: props.id,
        [name]: value,
      }),
    );
  };
  return (
    <div className="TabContent">
      <div className="FicheTab  fl_col gp16">
        <CardContainer
          type="form"
          ClassName="fl_row jc_fe"
          collapse
          label={languageData.global.bank_account_info}
        >
          <p className="p13">{languageData.global.payment_reception}</p>

          <FormCard
            grid={1}
            gap="gp20"
            fieldsetLabel={languageData.global.bank_account_iban}
          >
            <GridItems grid={2} gap={20}>
              <InputField
                isRequired={true}
                type="text"
                name="owner"
                label={languageData.global.account_holder}
                isDisabled={
                  props.isClaimsValid && props.InformationFinancièreModifier
                    ? false
                    : true
                }
                placeholder={financialData?.owner}
                value={formData.owner || ''}
                onChange={handleChange}
                style="form"
                haserror={
                  validationRequestedFinancial
                    ? getErrorEditCompany({
                        fieldName: 'owner',
                        isRequired: true,
                        fieldValue: formData?.owner,
                      })
                    : false
                }
                errorMsg={
                  validationRequestedFinancial
                    ? getErrorMessageEditCompany({
                        fieldName: 'owner',
                        fieldValue: formData?.owner,
                      })
                    : ''
                }
              />

              <SelectInput2
                name="phone_number"
                onSelect={onSelectMobilePhone}
                isDisabled={
                  props.isClaimsValid && props.InformationFinancièreModifier
                    ? false
                    : true
                }
                label={languageData.global.Form.mobileLabel}
                //defaultValue={formData?.owner_mobile_number}
                placeholder={financialData?.phone_number}
                value={formData?.phone_number} 
                target="reflags"
                icons={flags}
                reference={countriePhoneCodes}
                options={countriesCodes}
                isRequired
                onChange={handleChange}
                haserror={
                  validationRequestedFinancial
                    ? getErrorEditCompany({
                        fieldName: 'phone_number',
                        isRequired: true,
                        fieldValue: formData?.phone_number,
                      })
                    : false
                }
                errorMsg={
                  validationRequestedFinancial
                    ? getErrorMessageEditCompany({
                        fieldName: 'phone_number',

                        fieldValue: formData?.phone_number,
                      })
                    : ''
                }
              />

              <InputField
                isRequired={true}
                type="number"
                name="iban"
                label={languageData.global.iban}
                isDisabled={
                  props.isClaimsValid && props.InformationFinancièreModifier
                    ? false
                    : true
                }
                placeholder={financialData?.iban}
                onChange={handleChange}
                style="form"
                haserror={
                  validationRequestedFinancial
                    ? getErrorEditCompany({
                        isRequired: true,
                        fieldName: 'iban',
                        fieldValue: formData?.iban,
                      })
                    : false
                }
                errorMsg={
                  validationRequestedFinancial
                    ? getErrorMessageEditCompany({
                        fieldName: 'iban',

                        fieldValue: formData?.iban,
                      })
                    : ''
                }
              />
              <InputField
                isRequired={false}
                type="number"
                name="swifT_BIC"
                label={languageData.global.swift_bic}
                placeholder={
                  financialData?.swifT_BIC && financialData?.swifT_BIC
                }
                isDisabled={false}
                onChange={handleChange}
                style="form"
              />
              <FormCard
                grid={1}
                fieldsetLabel={languageData.global.credit_card}
              >
                <InputField
                  isRequired={true}
                  name="card_number"
                  type="number"
                  placeholder={financialData?.card_number?.toString()}
                  label={languageData.global.credit_card_number}
                  onChange={handleChange}
                  style="card"
                  isDisabled={
                    props.isClaimsValid && props.InformationFinancièreModifier
                      ? false
                      : true
                  }
                  cardType={cardType}
                  maxLengthCardNumber={16}
                  maxLength={16}
                  className="haserror"
                  haserror={
                    validationRequestedFinancial
                      ? getErrorEditCompany({
                          fieldName: 'card_number',
                          isRequired: true,
                          fieldValue: formData?.card_number,
                        })
                      : false
                  }
                  errorMsg={
                    validationRequestedFinancial
                      ? getErrorMessageEditCompany({
                          fieldName: 'card_number',

                          fieldValue: formData?.card_number,
                        })
                      : ''
                  }
                />
              </FormCard>
            </GridItems>
          </FormCard>
        </CardContainer>
        <Redevances />
      </div>
    </div>
  );
}
