// const useUserFormValidation = <T extends object>(obj: T): boolean => {
//   for (const key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       if (key === 'phoneNumber') {
//         continue; // Skip this field
//       }
//       const value = obj[key];
//       if (
//         value === '' ||
//         value === 0 ||
//         value === undefined ||
//         value === null
//       ) {
//         return true;
//       }
//     }
//   }
//   return false;

import { serviceAppUserModels } from '../../models/services/module.appUser';
import validateEmail from '../../utils/EmailValidation';

// };
const useUserFormValidation = (
  obj: serviceAppUserModels.AppUserPayload,
): boolean => {
  const { user, permissions } = obj;

  // Validate user object
  if (
    !user?.email ||
    !validateEmail(user?.email) ||
    !user?.firstName ||
    !user?.lastName ||
    user?.isActive === undefined
  ) {
    return true;
  }

  // Validate permissions array
  for (const permission of permissions) {
    if (
      // !permission.rowId ||
      !permission.rolesId.length ||
      // permission.isActive === undefined ||
      !permission.chainesIds.length 
    ) {
      return true;
    }
  }

  return false;
};

export default useUserFormValidation;
export const missingRequiredField = (obj: string): boolean => {
  if (
    obj === '' ||
    obj === undefined ||
    obj === null ||
    obj.trim().length === 0
  ) {
    return true;
  }
  return false;
};
