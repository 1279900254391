export interface CountriesFlagsAndCodesItem {
  flag: string;
  country: string;
  code: string;
  key: number;
  phoneCode: string;
}

const CountriesFlagsAndCodes = [
  {
    flag: 'ad.svg',
    country: 'Andorra',
    code: 'ad',
    key: 1,
    phoneCode: '+376',
  },
  {
    flag: 'ae.svg',
    country: 'United Arab Emirates',
    code: 'ae',
    key: 2,
    phoneCode: '+971',
  },
  {
    flag: 'af.svg',
    country: 'Afghanistan',
    code: 'af',
    key: 3,
    phoneCode: '+93',
  },
  {
    flag: 'ag.svg',
    country: 'Antigua & Barbuda',
    code: 'ag',
    key: 4,
    phoneCode: '+1 268',
  },
  {
    flag: 'ai.svg',
    country: 'Anguilla',
    code: 'ai',
    key: 5,
    phoneCode: '+1 264',
  },
  {
    flag: 'al.svg',
    country: 'Albania',
    code: 'al',
    key: 6,
    phoneCode: '+355',
  },
  {
    flag: 'am.svg',
    country: 'Armenia',
    code: 'am',
    key: 7,
    phoneCode: '+374',
  },
  {
    flag: 'ad.svg',
    country: 'Angola',
    code: 'ad',
    key: 8,
    phoneCode: '+244',
  },
  {
    flag: 'aq.svg',
    country: 'Antarctica',
    code: 'aq',
    key: 9,
    phoneCode: '0',
  },
  {
    flag: 'ar.svg',
    country: 'Argentina',
    code: 'ar',
    key: 10,
    phoneCode: '+54',
  },
  {
    flag: 'as.svg',
    country: 'American Samoa',
    code: 'as',
    key: 11,
    phoneCode: '+1 684',
  },
  {
    flag: 'at.svg',
    country: 'Austria',
    code: 'at',
    key: 12,
    phoneCode: '+43',
  },
  {
    flag: 'au.svg',
    country: 'Australia',
    code: 'au',
    key: 13,
    phoneCode: '+61',
  },
  {
    flag: 'aw.svg',
    country: 'Aruba',
    code: 'aw',
    key: 14,
    phoneCode: '+297',
  },
  {
    flag: 'ax.svg',
    country: 'Åland Islands',
    code: 'ax',
    key: 15,
    phoneCode: '0',
  },
  {
    flag: 'az.svg',
    country: 'Azerbaijan',
    code: 'az',
    key: 16,
    phoneCode: '+994',
  },
  {
    flag: 'ba.svg',
    country: 'Bosnia & Herzegovina',
    code: 'ba',
    key: 17,
    phoneCode: '0',
  },
  {
    flag: 'bb.svg',
    country: 'Barbados',
    code: 'bb',
    key: 18,
    phoneCode: '+1 246',
  },
  {
    flag: 'bd.svg',
    country: 'Bangladesh',
    code: 'bd',
    key: 19,
    phoneCode: '+880',
  },
  {
    flag: 'be.svg',
    country: 'Belgium',
    code: 'be',
    key: 20,
    phoneCode: '+32',
  },
  {
    flag: 'bf.svg',
    country: 'Burkina Faso',
    code: 'bf',
    key: 21,
    phoneCode: '+226',
  },
  {
    flag: 'bg.svg',
    country: 'Bulgaria',
    code: 'bg',
    key: 22,
    phoneCode: '+359',
  },
  {
    flag: 'bh.svg',
    country: 'Bahrain',
    code: 'bh',
    key: 23,
    phoneCode: '+973',
  },
  {
    flag: 'bi.svg',
    country: 'Burundi',
    code: 'bi',
    key: 24,
    phoneCode: '+257',
  },
  {
    flag: 'bj.svg',
    country: 'Benin',
    code: 'bj',
    key: 25,
    phoneCode: '+229',
  },
  {
    flag: 'bl.svg',
    country: 'St. Barthélemy',
    code: 'bl',
    key: 26,
    phoneCode: '0',
  },
  {
    flag: 'bm.svg',
    country: 'Bermuda',
    code: 'bm',
    key: 27,
    phoneCode: '+1 441',
  },
  {
    flag: 'bn.svg',
    country: 'Brunei',
    code: 'bn',
    key: 28,
    phoneCode: '+673',
  },
  {
    flag: 'bo.svg',
    country: 'Bolivia',
    code: 'bo',
    key: 29,
    phoneCode: '+591',
  },
  {
    flag: 'bq.svg',
    country: 'Caribbean Netherlands',
    code: 'bq',
    key: 30,
    phoneCode: '+31',
  },
  {
    flag: 'br.svg',
    country: 'Brazil',
    code: 'br',
    key: 31,
    phoneCode: '+55',
  },
  {
    flag: 'bs.svg',
    country: 'Bahamas',
    code: 'bs',
    key: 32,
    phoneCode: '+1 242',
  },
  {
    flag: 'bt.svg',
    country: 'Bhutan',
    code: 'bt',
    key: 33,
    phoneCode: '+975',
  },
  {
    flag: 'bv.svg',
    country: 'Bouvet Island',
    code: 'bv',
    key: 34,
    phoneCode: '0',
  },
  {
    flag: 'bw.svg',
    country: 'Botswana',
    code: 'bw',
    key: 35,
    phoneCode: '+267',
  },
  {
    flag: 'by.svg',
    country: 'Belarus',
    code: 'by',
    key: 36,
    phoneCode: '+375',
  },
  {
    flag: 'bz.svg',
    country: 'Belize',
    code: 'bz',
    key: 37,
    phoneCode: '+501',
  },
  {
    flag: 'ca.svg',
    country: 'Canada',
    code: 'ca',
    key: 38,
    phoneCode: '+1',
  },
  {
    flag: 'cc.svg',
    country: 'Cocos (Keeling) Islands',
    code: 'cc',
    key: 39,
    phoneCode: '0',
  },
  {
    flag: 'cg.svg',
    country: 'Congo - Kinshasa',
    code: 'cg',
    key: 40,
    phoneCode: '+242',
  },
  {
    flag: 'cf.svg',
    country: 'Central African Republic',
    code: 'cf',
    key: 41,
    phoneCode: '+236',
  },
  {
    flag: 'cd.svg',
    country: 'Congo - Brazzaville',
    code: 'cd',
    key: 42,
    phoneCode: '+242',
  },
  {
    flag: 'ch.svg',
    country: 'Switzerland',
    code: 'ch',
    key: 43,
    phoneCode: '+41',
  },
  {
    flag: 'ci.svg',
    country: 'Côte d’Ivoire',
    code: 'ci',
    key: 44,
    phoneCode: '0',
  },
  {
    flag: 'ck.svg',
    country: 'Cook Islands',
    code: 'ck',
    key: 45,
    phoneCode: '+682',
  },
  {
    flag: 'cl.svg',
    country: 'Chile',
    code: 'cl',
    key: 46,
    phoneCode: '+56',
  },
  {
    flag: 'cm.svg',
    country: 'Cameroon',
    code: 'cm',
    key: 47,
    phoneCode: '+237',
  },
  {
    flag: 'cn.svg',
    country: 'China',
    code: 'cn',
    key: 48,
    phoneCode: '+86',
  },
  {
    flag: 'co.svg',
    country: 'Colombia',
    code: 'co',
    key: 49,
    phoneCode: '+57',
  },
  {
    flag: 'cp.svg',
    country: 'Clipperton Island',
    code: 'cp',
    key: 50,
    phoneCode: '0',
  },
  {
    flag: 'cr.svg',
    country: 'Costa Rica',
    code: 'cr',
    key: 51,
    phoneCode: '+506',
  },
  {
    flag: 'cu.svg',
    country: 'Cuba',
    code: 'cu',
    key: 52,
    phoneCode: '+53',
  },
  {
    flag: 'cv.svg',
    country: 'Cape Verde',
    code: 'cv',
    key: 53,
    phoneCode: '+238',
  },
  {
    flag: 'cw.svg',
    country: 'Curaçao',
    code: 'cw',
    key: 54,
    phoneCode: '0',
  },
  {
    flag: 'cx.svg',
    country: 'Christmas Island',
    code: 'cx',
    key: 55,
    phoneCode: '+61',
  },
  {
    flag: 'cy.svg',
    country: 'Cyprus',
    code: 'cy',
    key: 56,
    phoneCode: '+537',
  },
  {
    flag: 'cz.svg',
    country: 'Czechia',
    code: 'cz',
    key: 57,
    phoneCode: '0',
  },
  {
    flag: 'de.svg',
    country: 'Germany',
    code: 'de',
    key: 58,
    phoneCode: '+49',
  },
  {
    flag: 'dg.svg',
    country: 'Diego Garcia',
    code: 'dg',
    key: 59,
    phoneCode: '+246',
  },
  {
    flag: 'dj.svg',
    country: 'Djibouti',
    code: 'dj',
    key: 60,
    phoneCode: '+253',
  },
  {
    flag: 'dk.svg',
    country: 'Denmark',
    code: 'dk',
    key: 61,
    phoneCode: '+45',
  },
  {
    flag: 'dm.svg',
    country: 'Dominica',
    code: 'dm',
    key: 62,
    phoneCode: '+1 767',
  },
  {
    flag: 'do.svg',
    country: 'Dominican Republic',
    code: 'do',
    key: 63,
    phoneCode: '+1 767',
  },
  {
    flag: 'dz.svg',
    country: 'Algeria',
    code: 'dz',
    key: 64,
    phoneCode: '+213',
  },
  {
    flag: '',
    country: 'Ceuta & Melilla',
    code: 'ea',
    key: 65,
    phoneCode: '0',
  },
  {
    flag: 'ec.svg',
    country: 'Ecuador',
    code: 'ec',
    key: 66,
    phoneCode: '+593',
  },
  {
    flag: 'ee.svg',
    country: 'Estonia',
    code: 'ee',
    key: 67,
    phoneCode: '+372',
  },
  {
    flag: 'eg.svg',
    country: 'Egypt',
    code: 'eg',
    key: 68,
    phoneCode: '+20',
  },
  {
    flag: 'eh.svg',
    country: 'Western Sahara',
    code: 'eh',
    key: 69,
    phoneCode: '0',
  },
  {
    flag: 'er.svg',
    country: 'Eritrea',
    code: 'er',
    key: 70,
    phoneCode: '+291',
  },
  {
    flag: 'es.svg',
    country: 'Spain',
    code: 'es',
    key: 71,
    phoneCode: '+34',
  },
  {
    flag: 'et.svg',
    country: 'Ethiopia',
    code: 'et',
    key: 72,
    phoneCode: '+251',
  },
  {
    flag: 'eu.svg',
    country: 'European Union',
    code: 'eu',
    key: 73,
    phoneCode: '0',
  },
  {
    flag: 'fi.svg',
    country: 'Finland',
    code: 'fi',
    key: 74,
    phoneCode: '+358',
  },
  {
    flag: 'fj.svg',
    country: 'Fiji',
    code: 'fj',
    key: 75,
    phoneCode: '+679',
  },
  {
    flag: 'fk.svg',
    country: 'Falkland Islands',
    code: 'fk',
    key: 76,
    phoneCode: '+500',
  },
  {
    flag: 'fm.svg',
    country: 'Micronesia',
    code: 'fm',
    key: 77,
    phoneCode: '+691',
  },
  {
    flag: 'fo.svg',
    country: 'Faroe Islands',
    code: 'fo',
    key: 78,
    phoneCode: '+298',
  },
  {
    flag: 'fr.svg',
    country: 'France',
    code: 'fr',
    key: 79,
    phoneCode: '+33',
  },
  {
    flag: 'ga.svg',
    country: 'Gabon',
    code: 'ga',
    key: 80,
    phoneCode: '+241',
  },
  {
    flag: 'gb.svg',
    country: 'United Kingdom',
    code: 'gb',
    key: 81,
    phoneCode: '+44',
  },
  {
    flag: 'gd.svg',
    country: 'Grenada',
    code: 'gd',
    key: 82,
    phoneCode: '+1 473',
  },
  {
    flag: 'ge.svg',
    country: 'Georgia',
    code: 'ge',
    key: 83,
    phoneCode: '+995',
  },
  {
    flag: 'gf.svg',
    country: 'French Guiana',
    code: 'gf',
    key: 84,
    phoneCode: '+594',
  },
  {
    flag: 'gg.svg',
    country: 'Guernsey',
    code: 'gg',
    key: 85,
    phoneCode: '0',
  },
  {
    flag: 'gh.svg',
    country: 'Ghana',
    code: 'gh',
    key: 86,
    phoneCode: '+233',
  },
  {
    flag: 'gi.svg',
    country: 'Gibraltar',
    code: 'gi',
    key: 87,
    phoneCode: '+350',
  },
  {
    flag: 'gl.svg',
    country: 'Greenland',
    code: 'gl',
    key: 88,
    phoneCode: '+299',
  },
  {
    flag: 'gm.svg',
    country: 'Gambia',
    code: 'gm',
    key: 89,
    phoneCode: '+220',
  },
  {
    flag: 'gn.svg',
    country: 'Guinea',
    code: 'gn',
    key: 90,
    phoneCode: '+224',
  },
  {
    flag: 'gp.svg',
    country: 'Guadeloupe',
    code: 'gp',
    key: 91,
    phoneCode: '+590',
  },
  {
    flag: 'gq.svg',
    country: 'Equatorial Guinea',
    code: 'gq',
    key: 92,
    phoneCode: '+240',
  },
  {
    flag: 'gr.svg',
    country: 'Greece',
    code: 'gr',
    key: 93,
    phoneCode: '+30',
  },
  {
    flag: 'gs.svg',
    country: "South Georgia & South', Sandwich Islands",
    code: 'gs',
    key: 94,
    phoneCode: '+995',
  },
  {
    flag: 'gt.svg',
    country: 'Guatemala',
    code: 'gt',
    key: 95,
    phoneCode: '+502',
  },
  {
    flag: 'gu.svg',
    country: 'Guam',
    code: 'gu',
    key: 96,
    phoneCode: '+1 671',
  },
  {
    flag: 'gw.svg',
    country: 'Guinea-Bissau',
    code: 'gw',
    key: 97,
    phoneCode: '+224',
  },
  {
    flag: 'gy.svg',
    country: 'Guyana',
    code: 'gy',
    key: 98,
    phoneCode: '+595',
  },
  {
    flag: 'hk.svg',
    country: 'Hong Kong SAR China',
    code: 'hk',
    key: 99,
    phoneCode: '+86',
  },
  {
    flag: 'hm.svg',
    country: 'Heard & McDonald Islands',
    code: 'hm',
    key: 100,
    phoneCode: '0',
  },
  {
    flag: 'hn.svg',
    country: 'Honduras',
    code: 'hn',
    key: 101,
    phoneCode: '+504',
  },
  {
    flag: 'hr.svg',
    country: 'Croatia',
    code: 'hr',
    key: 102,
    phoneCode: '+385',
  },
  {
    flag: 'ht.svg',
    country: 'Haiti',
    code: 'ht',
    key: 103,
    phoneCode: '+509',
  },
  {
    flag: 'hu.svg',
    country: 'Hungary',
    code: 'hu',
    key: 104,
    phoneCode: '+36',
  },
  {
    flag: 'ic.svg',
    country: 'Canary Islands',
    code: 'ic',
    key: 105,
    phoneCode: '0',
  },
  {
    flag: 'id.svg',
    country: 'Indonesia',
    code: 'id',
    key: 106,
    phoneCode: '+62',
  },
  {
    flag: 'ie.svg',
    country: 'Ireland',
    code: 'ie',
    key: 107,
    phoneCode: '+353',
  },
  {
    flag: 'il.svg',
    country: 'Israel',
    code: 'il',
    key: 108,
    phoneCode: '+972',
  },
  {
    flag: 'im.svg',
    country: 'Isle of Man',
    code: 'im',
    key: 109,
    phoneCode: '0',
  },
  {
    flag: 'in.svg',
    country: 'India',
    code: 'in',
    key: 110,
    phoneCode: '+91',
  },
  {
    flag: 'io.svg',
    country: 'British Indian Ocean Territory',
    code: 'io',
    key: 111,
    phoneCode: '+246',
  },
  {
    flag: 'iq.svg',
    country: 'Iraq',
    code: 'iq',
    key: 112,
    phoneCode: '+964',
  },
  {
    flag: 'ir.svg',
    country: 'Iran',
    code: 'ir',
    key: 113,
    phoneCode: '+98',
  },
  {
    flag: 'is.svg',
    country: 'Iceland',
    code: 'is',
    key: 114,
    phoneCode: '+354',
  },
  {
    flag: 'it.svg',
    country: 'Italy',
    code: 'it',
    key: 115,
    phoneCode: '+39',
  },
  {
    flag: 'je.svg',
    country: 'Jersey',
    code: 'je',
    key: 116,
    phoneCode: '0',
  },
  {
    flag: 'jm.svg',
    country: 'Jamaica',
    code: 'jm',
    key: 117,
    phoneCode: '+1 876',
  },
  {
    flag: 'jo.svg',
    country: 'Jordan',
    code: 'jo',
    key: 118,
    phoneCode: '+962',
  },
  {
    flag: 'jp.svg',
    country: 'Japan',
    code: 'jp',
    key: 119,
    phoneCode: '+81',
  },
  {
    flag: 'ke.svg',
    country: 'Kenya',
    code: 'ke',
    key: 120,
    phoneCode: '+254',
  },
  {
    flag: 'kg.svg',
    country: 'Kyrgyzstan',
    code: 'kg',
    key: 121,
    phoneCode: '+996',
  },
  {
    flag: 'kh.svg',
    country: 'Cambodia',
    code: 'kh',
    key: 122,
    phoneCode: '+855',
  },
  {
    flag: 'ki.svg',
    country: 'Kiribati',
    code: 'ki',
    key: 123,
    phoneCode: '+686',
  },
  {
    flag: 'km.svg',
    country: 'Comoros',
    code: 'km',
    key: 124,
    phoneCode: '+269',
  },
  {
    flag: 'kn.svg',
    country: 'St. Kitts & Nevis',
    code: 'kn',
    key: 125,
    phoneCode: '+1 869',
  },
  {
    flag: 'kp.svg',
    country: 'North Korea',
    code: 'kp',
    key: 126,
    phoneCode: '+850',
  },
  {
    flag: 'kr.svg',
    country: 'South Korea',
    code: 'kr',
    key: 127,
    phoneCode: '+82',
  },
  {
    flag: 'kw.svg',
    country: 'Kuwait',
    code: 'kw',
    key: 128,
    phoneCode: '+965',
  },
  {
    flag: 'ky.svg',
    country: 'Cayman Islands',
    code: 'ky',
    key: 129,
    phoneCode: '+ 345',
  },
  {
    flag: 'kz.svg',
    country: 'Kazakhstan',
    code: 'kz',
    key: 130,
    phoneCode: '+7 7',
  },
  {
    flag: 'la.svg',
    country: 'Laos',
    code: 'la',
    key: 131,
    phoneCode: '+856',
  },
  {
    flag: 'lb.svg',
    country: 'Lebanon',
    code: 'lb',
    key: 132,
    phoneCode: '+961',
  },
  {
    flag: 'lc.svg',
    country: 'St. Lucia',
    code: 'lc',
    key: 133,
    phoneCode: '0',
  },
  {
    flag: 'li.svg',
    country: 'Liechtenstein',
    code: 'li',
    key: 134,
    phoneCode: '+423',
  },
  {
    flag: 'lk.svg',
    country: 'Sri Lanka',
    code: 'lk',
    key: 135,
    phoneCode: '+94',
  },
  {
    flag: 'lr.svg',
    country: 'Liberia',
    code: 'lr',
    key: 136,
    phoneCode: '+231',
  },
  {
    flag: 'ls.svg',
    country: 'Lesotho',
    code: 'ls',
    key: 137,
    phoneCode: '+266',
  },
  {
    flag: 'lt.svg',
    country: 'Lithuania',
    code: 'lt',
    key: 138,
    phoneCode: '+370',
  },
  {
    flag: 'lu.svg',
    country: 'Luxembourg',
    code: 'lu',
    key: 139,
    phoneCode: '+352',
  },
  {
    flag: 'lv.svg',
    country: 'Latvia',
    code: 'lv',
    key: 140,
    phoneCode: '+371',
  },
  {
    flag: 'ly.svg',
    country: 'Libya',
    code: 'ly',
    key: 141,
    phoneCode: '+218',
  },
  {
    flag: 'ma.svg',
    country: 'Morocco',
    code: 'ma',
    key: 142,
    phoneCode: '+212',
  },
  {
    flag: 'mc.svg',
    country: 'Monaco',
    code: 'mc',
    key: 143,
    phoneCode: '+377',
  },
  {
    flag: 'md.svg',
    country: 'Moldova',
    code: 'md',
    key: 144,
    phoneCode: '+373',
  },
  {
    flag: 'me.svg',
    country: 'Montenegro',
    code: 'me',
    key: 145,
    phoneCode: '+382',
  },
  {
    flag: 'mf.svg',
    country: 'St. Martin',
    code: 'mf',
    key: 146,
    phoneCode: '0',
  },
  {
    flag: 'mg.svg',
    country: 'Madagascar',
    code: 'mg',
    key: 147,
    phoneCode: '+261',
  },
  {
    flag: 'mh.svg',
    country: 'Marshall Islands',
    code: 'mh',
    key: 148,
    phoneCode: '+692',
  },
  {
    flag: 'mk.svg',
    country: 'North Macedonia',
    code: 'mk',
    key: 149,
    phoneCode: '+389',
  },
  {
    flag: 'ml.svg',
    country: 'Mali',
    code: 'ml',
    key: 150,
    phoneCode: '+223',
  },
  {
    flag: 'mm.svg',
    country: 'Myanmar (Burma)',
    code: 'mm',
    key: 151,
    phoneCode: '+95',
  },
  {
    flag: 'mn.svg',
    country: 'Mongolia',
    code: 'mn',
    key: 152,
    phoneCode: '+976',
  },
  {
    flag: 'mo.svg',
    country: 'Macao Sar China',
    code: 'mo',
    key: 153,
    phoneCode: '+86',
  },
  {
    flag: 'mp.svg',
    country: 'Northern Mariana Islands',
    code: 'mp',
    key: 154,
    phoneCode: '+1 670',
  },
  {
    flag: 'mq.svg',
    country: 'Martinique',
    code: 'mq',
    key: 155,
    phoneCode: '+596',
  },
  {
    flag: 'mr.svg',
    country: 'Mauritania',
    code: 'mr',
    key: 156,
    phoneCode: '+222',
  },
  {
    flag: 'ms.svg',
    country: 'Montserrat',
    code: 'ms',
    key: 157,
    phoneCode: '+1664',
  },
  {
    flag: 'mt.svg',
    country: 'Malta',
    code: 'mt',
    key: 158,
    phoneCode: '+356',
  },
  {
    flag: 'mu.svg',
    country: 'Mauritius',
    code: 'mu',
    key: 159,
    phoneCode: '+230',
  },
  {
    flag: 'mv.svg',
    country: 'Maldives',
    code: 'mv',
    key: 160,
    phoneCode: '+960',
  },
  {
    flag: 'mw.svg',
    country: 'Malawi',
    code: 'mw',
    key: 161,
    phoneCode: '+265',
  },
  {
    flag: 'mx.svg',
    country: 'Mexico',
    code: 'mx',
    key: 162,
    phoneCode: '+52',
  },
  {
    flag: 'my.svg',
    country: 'Malaysia',
    code: 'my',
    key: 163,
    phoneCode: '+60',
  },
  {
    flag: 'mz.svg',
    country: 'Mozambique',
    code: 'mz',
    key: 164,
    phoneCode: '0',
  },
  {
    flag: 'na.svg',
    country: 'Namibia',
    code: 'na',
    key: 165,
    phoneCode: '+264',
  },
  {
    flag: 'nc.svg',
    country: 'New Caledonia',
    code: 'nc',
    key: 166,
    phoneCode: '+687',
  },
  {
    flag: 'ne.svg',
    country: 'Niger',
    code: 'ne',
    key: 167,
    phoneCode: '+227',
  },
  {
    flag: 'nf.svg',
    country: 'Norfolk Island',
    code: 'nf',
    key: 168,
    phoneCode: '+672',
  },
  {
    flag: 'ng.svg',
    country: 'Nigeria',
    code: 'ng',
    key: 169,
    phoneCode: '+227',
  },
  {
    flag: 'ni.svg',
    country: 'Nicaragua',
    code: 'ni',
    key: 170,
    phoneCode: '+505',
  },
  {
    flag: 'nl.svg',
    country: 'Netherlands',
    code: 'nl',
    key: 171,
    phoneCode: '+31',
  },
  {
    flag: 'no.svg',
    country: 'Norway',
    code: 'no',
    key: 172,
    phoneCode: '+47',
  },
  {
    flag: 'np.svg',
    country: 'Nepal',
    code: 'np',
    key: 173,
    phoneCode: '+977',
  },
  {
    flag: 'nr.svg',
    country: 'Nauru',
    code: 'nr',
    key: 174,
    phoneCode: '+674',
  },
  {
    flag: 'nu.svg',
    country: 'Niue',
    code: 'nu',
    key: 175,
    phoneCode: '+683',
  },
  {
    flag: 'nz.svg',
    country: 'New Zealand',
    code: 'nz',
    key: 176,
    phoneCode: '+64',
  },
  {
    flag: 'om.svg',
    country: 'Oman',
    code: 'om',
    key: 177,
    phoneCode: '+968',
  },
  {
    flag: 'pa.svg',
    country: 'Panama',
    code: 'pa',
    key: 178,
    phoneCode: '+507',
  },
  {
    flag: 'pe.svg',
    country: 'Peru',
    code: 'pe',
    key: 179,
    phoneCode: '+51',
  },
  {
    flag: 'pf.svg',
    country: 'French Polynesia',
    code: 'pf',
    key: 180,
    phoneCode: '+689',
  },
  {
    flag: 'pg.svg',
    country: 'Papua New Guinea',
    code: 'pg',
    key: 181,
    phoneCode: '+224',
  },
  {
    flag: 'ph.svg',
    country: 'Philippines',
    code: 'ph',
    key: 182,
    phoneCode: '+63',
  },
  {
    flag: 'pk.svg',
    country: 'Pakistan',
    code: 'pk',
    key: 183,
    phoneCode: '+92',
  },
  {
    flag: 'pl.svg',
    country: 'Poland',
    code: 'pl',
    key: 184,
    phoneCode: '+48',
  },
  {
    flag: 'pm.svg',
    country: 'St. Pierre & Miquelon',
    code: 'pm',
    key: 185,
    phoneCode: '0',
  },
  {
    flag: 'pn.svg',
    country: 'Pitcairn Islands',
    code: 'pn',
    key: 186,
    phoneCode: '0',
  },
  {
    flag: 'pr.svg',
    country: 'Puerto Rico',
    code: 'pr',
    key: 187,
    phoneCode: '+1 787',
  },
  {
    flag: 'ps.svg',
    country: 'Palestinian Territories',
    code: 'ps',
    key: 188,
    phoneCode: '0',
  },
  {
    flag: 'pt.svg',
    country: 'Portugal',
    code: 'pt',
    key: 189,
    phoneCode: '+351',
  },
  {
    flag: 'pw.svg',
    country: 'Palau',
    code: 'pw',
    key: 190,
    phoneCode: '+680',
  },
  {
    flag: 'py.svg',
    country: 'Paraguay',
    code: 'py',
    key: 191,
    phoneCode: '+595',
  },
  {
    flag: 'qa.svg',
    country: 'Qatar',
    code: 'qa',
    key: 192,
    phoneCode: '+974',
  },
  {
    flag: 're.svg',
    country: 'Réunion',
    code: 're',
    key: 193,
    phoneCode: '0',
  },
  {
    flag: 'ro.svg',
    country: 'Romania',
    code: 'ro',
    key: 194,
    phoneCode: '+968',
  },
  {
    flag: '',
    country: 'Serbia',
    code: 'yu',
    key: 195,
    phoneCode: '+381',
  },
  {
    flag: 'ru.svg',
    country: 'Russia',
    code: 'ru',
    key: 196,
    phoneCode: '+7',
  },
  {
    flag: 'rw.svg',
    country: 'Rwanda',
    code: 'rw',
    key: 197,
    phoneCode: '+250',
  },
  {
    flag: 'sa.svg',
    country: 'Saudi Arabia',
    code: 'sa',
    key: 198,
    phoneCode: '+966',
  },
  {
    flag: 'sb.svg',
    country: 'Solomon Islands',
    code: 'sb',
    key: 199,
    phoneCode: '+677',
  },
  {
    flag: 'sc.svg',
    country: 'Seychelles',
    code: 'sc',
    key: 200,
    phoneCode: '+248',
  },
  {
    flag: 'sd.svg',
    country: 'Sudan',
    code: 'sd',
    key: 201,
    phoneCode: '+249',
  },
  {
    flag: 'se.svg',
    country: 'Sweden',
    code: 'se',
    key: 202,
    phoneCode: '+46',
  },
  {
    flag: 'sg.svg',
    country: 'Singapore',
    code: 'sg',
    key: 203,
    phoneCode: '+65',
  },
  {
    flag: 'sh.svg',
    country: 'St. Helena',
    code: 'sh',
    key: 204,
    phoneCode: '0',
  },
  {
    flag: 'si.svg',
    country: 'Slovenia',
    code: 'si',
    key: 205,
    phoneCode: '+386',
  },
  {
    flag: 'sj.svg',
    country: 'Svalbard & Jan Mayen',
    code: 'sj',
    key: 206,
    phoneCode: '0',
  },
  {
    flag: 'sk.svg',
    country: 'Slovakia',
    code: 'sk',
    key: 207,
    phoneCode: '+421',
  },
  {
    flag: 'sl.svg',
    country: 'Sierra Leone',
    code: 'sl',
    key: 208,
    phoneCode: '+232',
  },
  {
    flag: 'sm.svg',
    country: 'San Marino',
    code: 'sm',
    key: 209,
    phoneCode: '+378',
  },
  {
    flag: 'sn.svg',
    country: 'Senegal',
    code: 'sn',
    key: 210,
    phoneCode: '+221',
  },
  {
    flag: 'so.svg',
    country: 'Somalia',
    code: 'so',
    key: 211,
    phoneCode: '+223',
  },
  {
    flag: 'sr.svg',
    country: 'Suriname',
    code: 'sr',
    key: 212,
    phoneCode: '+597',
  },
  {
    flag: 'ss.svg',
    country: 'South Sudan',
    code: 'ss',
    key: 213,
    phoneCode: '+249',
  },
  {
    flag: 'st.svg',
    country: 'São Tomé & Príncipe',
    code: 'st',
    key: 214,
    phoneCode: '0',
  },
  {
    flag: 'sv.svg',
    country: 'El Salvador',
    code: 'sv',
    key: 215,
    phoneCode: '+503',
  },
  {
    flag: 'sx.svg',
    country: 'Sint Maarten',
    code: 'sx',
    key: 216,
    phoneCode: '0',
  },
  {
    flag: 'sy.svg',
    country: 'Syria',
    code: 'sy',
    key: 217,
    phoneCode: '+963',
  },
  {
    flag: 'sz.svg',
    country: 'Eswatini',
    code: 'sz',
    key: 218,
    phoneCode: '0',
  },
  {
    flag: 'sh.svg',
    country: 'Tristan Da Cunha',
    code: 'sh',
    key: 219,
    phoneCode: '0',
  },
  {
    flag: 'tc.svg',
    country: 'Turks & Caicos Islands',
    code: 'tc',
    key: 220,
    phoneCode: '0',
  },
  {
    flag: 'td.svg',
    country: 'Chad',
    code: 'td',
    key: 221,
    phoneCode: '+235',
  },
  {
    flag: 'tf.svg',
    country: 'French Southern Territories',
    code: 'tf',
    key: 222,
    phoneCode: '0',
  },
  {
    flag: 'tg.svg',
    country: 'Togo',
    code: 'tg',
    key: 223,
    phoneCode: '+228',
  },
  {
    flag: 'th.svg',
    country: 'Thailand',
    code: 'th',
    key: 224,
    phoneCode: '+66',
  },
  {
    flag: 'tj.svg',
    country: 'Tajikistan',
    code: 'tj',
    key: 225,
    phoneCode: '+992',
  },
  {
    flag: 'tk.svg',
    country: 'Tokelau',
    code: 'tk',
    key: 226,
    phoneCode: '+690',
  },
  {
    flag: 'tl.svg',
    country: 'Timor-Leste',
    code: 'tl',
    key: 227,
    phoneCode: '0',
  },
  {
    flag: 'tm.svg',
    country: 'Turkmenistan',
    code: 'tm',
    key: 228,
    phoneCode: '+993',
  },
  {
    flag: 'tn.svg',
    country: 'Tunisia',
    code: 'tn',
    key: 229,
    phoneCode: '+216',
  },
  {
    flag: 'to.svg',
    country: 'Tonga',
    code: 'to',
    key: 230,
    phoneCode: '+676',
  },
  {
    flag: 'tr.svg',
    country: 'Turkey',
    code: 'tr',
    key: 231,
    phoneCode: '+90',
  },
  {
    flag: 'tt.svg',
    country: 'Trinidad & Tobago',
    code: 'tt',
    key: 232,
    phoneCode: '0',
  },
  {
    flag: 'tv.svg',
    country: 'Tuvalu',
    code: 'tv',
    key: 233,
    phoneCode: '+688',
  },
  {
    flag: 'tw.svg',
    country: 'Taiwan',
    code: 'tw',
    key: 234,
    phoneCode: '+886',
  },
  {
    flag: 'tz.svg',
    country: 'Tanzania',
    code: 'tz',
    key: 235,
    phoneCode: '+255',
  },
  {
    flag: 'ua.svg',
    country: 'Ukraine',
    code: 'ua',
    key: 236,
    phoneCode: '+380',
  },
  {
    flag: 'ug.svg',
    country: 'Uganda',
    code: 'ug',
    key: 237,
    phoneCode: '+256',
  },
  {
    flag: 'um.svg',
    country: 'U.S. Outlying Islands',
    code: 'um',
    key: 238,
    phoneCode: '0',
  },
  {
    flag: 'un.svg',
    country: 'United Nations',
    code: 'un',
    key: 239,
    phoneCode: '0',
  },
  {
    flag: 'us.svg',
    country: 'United States',
    code: 'us',
    key: 240,
    phoneCode: '+1',
  },
  {
    flag: 'uy.svg',
    country: 'Uruguay',
    code: 'uy',
    key: 241,
    phoneCode: '+598',
  },
  {
    flag: 'uz.svg',
    country: 'Uzbekistan',
    code: 'uz',
    key: 242,
    phoneCode: '+998',
  },
  {
    flag: 'va.svg',
    country: 'Vatican City',
    code: 'va',
    key: 243,
    phoneCode: '0',
  },
  {
    flag: 'vc.svg',
    country: 'St. Vincent & Grenadines',
    code: 'vc',
    key: 244,
    phoneCode: '0',
  },
  {
    flag: 've.svg',
    country: 'Venezuela',
    code: 've',
    key: 245,
    phoneCode: '+58',
  },
  {
    flag: 'vg.svg',
    country: 'British Virgin Islands',
    code: 'vg',
    key: 246,
    phoneCode: '+1 284',
  },
  {
    flag: 'vi.svg',
    country: 'U.S. Virgin Islands',
    code: 'vi',
    key: 247,
    phoneCode: '+1 340',
  },
  {
    flag: 'vn.svg',
    country: 'Vietnam',
    code: 'vn',
    key: 248,
    phoneCode: '+84',
  },
  {
    flag: 'vu.svg',
    country: 'Vanuatu',
    code: 'vu',
    key: 249,
    phoneCode: '+678',
  },
  {
    flag: 'wf.svg',
    country: 'Wallis & Futuna',
    code: 'wf',
    key: 250,
    phoneCode: '0',
  },
  {
    flag: 'ws.svg',
    country: 'Samoa',
    code: 'ws',
    key: 251,
    phoneCode: '+685',
  },
  {
    flag: 'xk.svg',
    country: 'Kosovo',
    code: 'xk',
    key: 252,
    phoneCode: '0',
  },
  {
    flag: 'ye.svg',
    country: 'Yemen',
    code: 'ye',
    key: 253,
    phoneCode: '+967',
  },
  {
    flag: 'yt.svg',
    country: 'Mayotte',
    code: 'yt',
    key: 254,
    phoneCode: '+262',
  },
  {
    flag: 'za.svg',
    country: 'South Africa',
    code: 'za',
    key: 255,
    phoneCode: '+27',
  },
  {
    flag: 'zm.svg',
    country: 'Zambia',
    code: 'zm',
    key: 256,
    phoneCode: '+260',
  },
  {
    flag: 'zw.svg',
    country: 'Zimbabwe',
    code: 'zw',
    key: 257,
    phoneCode: '+263',
  },
  {
    flag: '',
    country: 'England',
    code: 'uk',
    key: 258,
    phoneCode: '0',
  },
  {
    flag: '',
    country: 'Scotland',
    code: 'uk',
    key: 259,
    phoneCode: '0',
  },
  {
    flag: '',
    country: 'Wales',
    code: 'uk',
    key: 260,
    phoneCode: '0',
  },
];
export default CountriesFlagsAndCodes;
