import React from 'react';
import FormWizzardContent from './FormWizzardContent';
import FormWizzardFooter from './FormWizzardFooter';
import FormWizzardHeader from './FormWizzardHeader';
import CardContainer from '../../../CardContainer';
import ContainerDashboard from '../../ContainerDashboard';
import { serviceEtablissementModels } from '../../../../models/services/module.etablissement';
import { useSelector } from 'react-redux';

export default function FormWizzard() {
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  return (
    <div className="FormWizzard fl_col gp10 h-full jc_s">
      <div className="fl_col gp10">
        <CardContainer type="form">
          <FormWizzardHeader />
        </CardContainer>
        <ContainerDashboard>
          <FormWizzardContent />
        </ContainerDashboard>
      </div>

      {addEtablissementStep === 3 ? (
        <></>
      ) : (
        <CardContainer type="form" ClassName="CardFooterForm">
          <FormWizzardFooter />
        </CardContainer>
      )}
    </div>
  );
}
