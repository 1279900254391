export const handleKeyDownNumber = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  const allowedKeysPattern =
    /[0-9]|Backspace|Tab|ArrowLeft|ArrowRight|Delete|Enter/;

  if (!allowedKeysPattern.test(event.key)) {
    event.preventDefault();
  }
};
export const onInputNumber = (event: React.FormEvent<HTMLInputElement>) => {
  const target = event.target as HTMLInputElement;
  target.value = target.value.replace(/[^0-9]*/g, '');
  onInputNumberStep2(event);
};
export const onInputNumberStep2 = (
  event: React.FormEvent<HTMLInputElement>,
) => {
  const target = event.target as HTMLInputElement;

  if (target.value.length > 10) {
    target.value = target.value.slice(0, 10);
  }
};
export const onKeyDownWithoutE = (evt: React.KeyboardEvent<HTMLInputElement>) =>
  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
export const onKeyDownWithoutZero = (evt: React.KeyboardEvent<HTMLInputElement>) => {
  const { value, selectionStart } = evt.currentTarget;
  if (['e', 'E', '+', '-'].includes(evt.key)) {
    evt.preventDefault();
    return;
  }
  if (evt.key === '0') {
    if (value === '' || (value.startsWith('0') && selectionStart === 1)) {
      evt.preventDefault();
    }
  }
};
