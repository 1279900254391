import React from 'react';
import ButtonElement from '../../../../../components/button/button';
import { languageData } from '../../../../../i18n';
import { getErrorMessageRedevance } from '../../../../../utils/GetErrorMessages';
import { getErrorRedevance } from '../../../../../utils/GetErrors';
import CheckBoxCounter from '../CheckBoxCounter';
import FormCard from '../FormCard';
import StepWizzard from '../StepWizzard';
import { DeleteRedevance } from '../../../../../utils/DeleteRedevance';
import { UpdateRedevance } from '../../../../../utils/UpdateRedevance';
import { serviceEtablissementModels } from '../../../../../models/services/module.etablissement';
import { useDispatch, useSelector } from 'react-redux';
import { useUnsavedChanges } from '../../useUnsavedChanges';
import { addRedevance } from '../../../../../utils/addRedevance';
export default function Step1_Redevance() {
  const dispatch = useDispatch();
  const { newFormData } = useUnsavedChanges();
  const validationRequested = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.validationRequestedEtablissement,
  );
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const onChangeRedevanceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, name } = e.target;

    if (value === 'delete') {
      DeleteRedevance({
        newFormData,
        dispatch,
        id,
        key: 1,
      });
    } else {
      UpdateRedevance({
        newFormData,
        dispatch,
        id,
        checked,
        value,
        name,
        key: 1,
      });
    }
  };
  return (
    <div className="fl_col gp10">
      <StepWizzard
        className="FormWizzardContentCard fl_col gp20 ai_c"
        stepNumber={1}
        isActive={addEtablissementStep === 1}
      >
        <FormCard labelCard="Redevance" grid={6} gap="gp24" align="flex-end">
          {newFormData?.payload?.financialInformation?.royaltiesInformation?.map(
            (item: serviceEtablissementModels.royaltiesInformation) => (
              <CheckBoxCounter
                key={item.key}
                label={item.name}
                name={item.name}
                id={item.key}
                isPrincipal={item.isPrinciple}
                ischecked={item.isActive}
                initialCounter={item.pct}
                prefix="%"
                onChangeCounter={onChangeRedevanceName}
                editItem={item.editItem}
                deleteItem={item.deleteItem}
                haserror={validationRequested ? getErrorRedevance(item) : false}
                errorMsg={
                  validationRequested ? getErrorMessageRedevance(item) : ''
                }
              />
            ),
          )}

          <ButtonElement
            className="btn-repeater"
            type="IconButton"
            text={languageData.global.add}
            buttonSize="none"
            buttonStyle="fl_rev btn-grey-light"
            radius="rd8"
            textColor="GraniteGray-txt"
            onClick={() =>
              addRedevance({
                newFormData,
                dispatch,
                key: 1,
              })
            }
          />
        </FormCard>
      </StepWizzard>
    </div>
  );
}
