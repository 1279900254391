import React from 'react';
import InputField from '../../Input/Input';
import SelectInput from '../../Input/SelectInput';
import ButtonElement from '../../button/button';
import InfoSuccessValidationIcon from '../../../assets/icons/InfoSuccessValidationIcon.svg';

/**
 * ConfirmEnableStatusModal component description.
 *
 * @ConfirmEnableStatusModal
 *
 */

interface ConfirmEnableStatusModalProps {
  onClickClose : () => void;
  onClickValidate : () => void;
}

const ConfirmEnableStatusModal: React.FC<ConfirmEnableStatusModalProps> = ({ onClickClose,
  onClickValidate }) => {
  return (
    <div className="ConfirmEnableStatusModal fl_col gp40">
      <div className="content-wrapper fl_row ai_s gp20">
        <img width={48} src={InfoSuccessValidationIcon} alt="" />
        <div className="fl_col gp4">
          <p className="p18 w-700">Activer Abonnements?</p>
          <p className="p14 Independence-txt">
            L’activation de votre abonnement entraînera automatiquement le
            déclenchement de la procédure de paiement.
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="footer-wrapper fl_row jc_fe gp20">
          <div className="fl-1">
            <ButtonElement
              type={'TextButton'}
              text="Annuler"
              buttonSize={'medium'}
              buttonStyle="btn-primary-light"
              onClick={onClickClose}
            />
          </div>
          <div className="fl-2">
            <ButtonElement
              type={'TextButton'}
              text="Valider"
              buttonSize={'medium'}
              buttonStyle="btn-primary"
              onClick={onClickValidate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEnableStatusModal;
  