import React from 'react';
import CloseBtnPrimary from '../../assets/icons/CloseBtnPrimary.svg';
import ModalController from './ModalController';
import { ModalProps } from '../../models/services/module.modals';
const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const modalContentBody = ModalController(props).modalContentBody;
  const modalContentFooter = ModalController(props).modalContentFooter;
  if (props.visibility) {
    document.querySelector('body')?.classList.add('ModalEnabled');
  } else {
    document.querySelector('body')?.classList.remove('ModalEnabled');
  }
  if (!props.visibility) return null;
  return (
    <div
      className={`Modal ${props.className ? props.className : ''} ${props.version + '_modal'} ${props.type} ${props.visibility ? '' : 'hidden'}`}
    >
      <div className="OverlayModal" onClick={props.onClickClose} />
      <div
        style={{ maxWidth: props.maxWidth, minHeight: props.height }}
        className="ModalContent rd12"
      >
        <div className="modal-wrapper pd24 fl_col gp20 jc_s">
          <div className="ModalHeader fl_row jc_s">
            {props.customDescription ? (
              <div className="fl_col gp4">
                <p className="p18 w-700">{props.title}</p>
                <p className="p14 w-400 Independence-txt">
                  {props.customDescription}
                </p>
              </div>
            ) : (
              <p className="p18 w-700">{props.title}</p>
            )}

            {props.close && (
              <img
                className="closebtn"
                onClick={props.onClickClose}
                src={CloseBtnPrimary}
                alt=""
              />
            )}
          </div>
          <div className="ModalBody fl_row jc_s">{modalContentBody}</div>
          <div className="ModalFooter">{modalContentFooter}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
