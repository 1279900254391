import { useSelector } from 'react-redux';
import { ToastProps } from '../../../components/Toast/Toast';
import React, { useEffect } from 'react';
import FormWizzard from './FormWizzard/FormWizzard';
import ToastHandler from '../Toast/ToastHandler';
import useInitializeStepsData from './useInitializeStepsData';
import useFormValidation from './useFormValidation';
import useHandleFormChanges from './useHandleFormChanges';
import useToast from './useToast';
import { useDispatch } from 'react-redux';
import tabs_list from '../../../data/tabsList';
import { initialState } from '../../../utils/addEtablissementFormInitialState';
import { unsavedChangesRegister } from '../../../store/actions/actions';
interface EtablissementState {
  etablissementState: boolean;
}
export default function SelectedCompanyCreateEtablissement() {
  const dispatch = useDispatch();
  const etablissementState = useSelector(
    (state: { etablissementStateChangedReducer: EtablissementState }) =>
      state.etablissementStateChangedReducer.etablissementState,
  );
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);
  useInitializeStepsData();
  useFormValidation();
  useHandleFormChanges();
  useToast(toastRef);
  useEffect(() => {
    const array = [
      {
        key: tabs_list[1].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...initialState
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  },[])
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (etablissementState) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [etablissementState]);
  return (
     <div className='h-full'>
      <FormWizzard />
      <ToastHandler ref={toastRef} />
    </div>
  );
}
