import compareObjects from '../../../utils/CompareObjects';
import { IsChangedTab } from '../../../services/tabs/IsChangedTab';
import { updateTabs } from '../../../store/actions/actions';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import tabs_list from '../../../data/tabsList';
import { IsTabFormDirty } from '../../../services/tabs/IsTabFormDirty';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import { initialState } from '../../../utils/addEtablissementFormInitialState';

const useHandleFormChanges = () => {
  const dispatch = useDispatch();
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const tabFormDirtyIndex = IsTabFormDirty({
    array: unsavedChangesData,
    key: tabs_list[1].name,
  });

  const isDirty = tabFormDirtyIndex !== -1;
  const newFormData = isDirty
    ? unsavedChangesData[tabFormDirtyIndex].value.payload
    : initialState;
  const tabIndex = open_tabs.findIndex(
    (item) => item.name === tabs_list[1].name,
  );
  const isChangedTab = IsChangedTab({
    label: tabs_list[1].name,
    tab: open_tabs[tabIndex],
  });
  useEffect(() => {
    if (!compareObjects(initialState, newFormData) && !isChangedTab) {
      const updatedTabs = [...open_tabs];
      updatedTabs[tabIndex] = {
        ...updatedTabs[tabIndex],
        isChanged: true,
      };
      dispatch(updateTabs(updatedTabs));
    }
    // dispatch(addEtablissementCurrentStep(addEtablissementStep));
  }, [newFormData, isChangedTab]);
};
export default useHandleFormChanges;
