import axios from 'axios';

export async function downloadImage(
  url: string,
  fileName = new Date().getTime() + 'image.jpg',
) {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data]);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Programmatically trigger the download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(link.href);
    link.remove();

    console.log('Image downloaded successfully.');
  } catch (error) {
    console.error('Error downloading image:', error);
  }
}
